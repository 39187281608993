<template>
    <transition name="expand" mode="out-in">
        <div class="sidebar" :class="{ 'sidebar-collapsed': $store.state.sidebarCollapsed }">
            <div class="sidebar-head position-relative">
                <img @click="$route.name !== 'Home' && $router.push('/')"
                     style="cursor: pointer"
                     :src="require($store.state.sidebarCollapsed ? '@/assets/logo-small.png' : '@/assets/logo.png')">

                <button @click="collapseSidebar"
                        v-if="!$store.state.sidebarCollapsed"
                        class="btn btn-sm btn-light position-absolute d-md-none"
                        style="right: 10px;">
                    <i class="fas fa-fw fa-chevron-left"></i>
                </button>

            </div>
            <div class="sidebar-body p-2">
                <div>
                    <a class="sidebar-link"
                       @click.prevent="$route.name !== 'Devices' && $router.push({name: 'Devices'})"
                       :class="{ 'active' : $route.name === 'Devices'}"
                       href="#">
                        <Icon
                            class="sidebar-icon-fix"
                            icon-name="devices"
                            :fill="linkIconColor"
                            width="22"
                            height="22"
                        />
                        <span>{{ $t('Устройства') }}</span>
                    </a>
                </div>
                <div>
                    <a class="sidebar-link"
                       @click.prevent="$route.name !== 'Alarms' && $router.push({name: 'Alarms'})"
                       :class="{ 'active' : $route.name === 'Alarms'}"
                       href="#">
                        <Icon
                            class="sidebar-icon-fix"
                            icon-name="about"
                            :fill="linkIconColor"
                            width="22"
                            height="22"
                        />
                        <span>{{ $t('Аварии') }}</span>
                    </a>
                </div>
                <div>
                    <a class="sidebar-link"
                       @click.prevent="$route.name !== 'Accidents' && $router.push({name: 'Accidents'})"
                       :class="{ 'active' : $route.name === 'Accidents'}"
                       href="#">
                        <Icon
                            class="sidebar-icon-fix"
                            icon-name="accident"
                            :fill="linkIconColor"
                            width="22"
                            height="22"
                        />
                        <span>{{ $t('Инциденты') }}</span>
                    </a>
                </div>
                <div>
                    <a class="sidebar-link"
                       @click.prevent="$route.name !== 'Accesses' && $router.push({name: 'Accesses'})"
                       :class="{ 'active' : $route.name === 'Accesses'}"
                       href="#">
                        <Icon
                            class="sidebar-icon-fix"
                            icon-name="access"
                            :fill="linkIconColor"
                            width="22"
                            height="22"
                        />
                        <span>{{ $t('Доступы') }}</span>
                    </a>
                </div>
                <div>
                    <a class="sidebar-link"
                       @click.prevent="$route.name !== 'Support' && $router.push({name: 'Support'})"
                       :class="{ 'active' : $route.name === 'Support'}"
                       href="#">
                        <Icon
                            class="sidebar-icon-fix"
                            icon-name="support"
                            :fill="linkIconColor"
                            width="22"
                            height="22"
                        />
                        <span>{{ $t('Поддержка') }}</span>
                    </a>
                </div>
<!--                <div>-->
<!--                    <a class="sidebar-link"-->
<!--                       @click.prevent="$route.name !== 'Settings' && $router.push({name: 'Settings'})"-->
<!--                       :class="{ 'active' : $route.name === 'Settings'}"-->
<!--                       href="#">-->
<!--                        <Icon-->
<!--                            class="sidebar-icon-fix"-->
<!--                            icon-name="settings"-->
<!--                            :fill="linkIconColor"-->
<!--                            width="22"-->
<!--                            height="22"-->
<!--                        />-->
<!--                        <span>{{ $t('Настройки') }}</span>-->
<!--                    </a>-->
<!--                </div>-->
            </div>
        </div>
    </transition>
</template>

<script>

import types from "../store/types";
import Icon from "./Icon";

export default {
    name: "Sidebar",
    components: {
        Icon
    },
    data() {
        return {
            linkIconColor: '#c9c9c9',
            visibleLocalities: true,
            localities: [],
            chevron_visible: true,
        }
    },
    methods: {
        collapseSidebar() {
            this.$store.commit(types.SIDEBAR_COLLAPSED, true);
        },
        navigate(name) {
            if (this.$route.name !== name) {
                this.$router.push({name});
                this.mobileHideSidebar();
            }
        },
        navigateLocality(locality) {
            if (this.$route.params.id !== locality.id) {
                this.$router.push({name: 'Locality', params: {id: locality.id}});
                this.mobileHideSidebar();
            }
        },
        mobileHideSidebar() {
            if (window.innerWidth < 768) {
                setTimeout(() => {
                    this.$store.commit(types.SIDEBAR_COLLAPSED, 1);
                }, 200);
            }
        },
    },
    watch: {
        "$store.state.sidebarCollapsed"(val) {
            setTimeout(() => {
                this.chevron_visible = !val;
            }, 250);
        },
    },
    async mounted() {
        // this.$store.commit(types.PRELOADER, true);
        // const locality = new LocalityService();
        // this.$store.commit(types.PRELOADER, false);
        // this.localities = await locality.fetch();
    }
}
</script>

<style lang="less" scoped>

@import "../less/_variables";

.sidebar {
    width: @sidebar-width;
    height: 100vh;
    background: @sidebar-bg-new;
    z-index: 1010;
    padding: 0;
    transition: 0.25s ease-out;
    transition-property: width;

    @media (max-width: 768px) {
        width: @sidebar-mobile-width;
        position: absolute;
        top: 0;
        left: 0;
    }

    &-head {
        width: 100%;
        height: @navbar-height;
        border-bottom: 1px solid tint(@sidebar-bg, 30);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-height: 40px;
            width: auto;
        }
    }

    &-link {
        display: block;
        font-weight: 500;
        color: #fff;
        text-decoration: none;
        padding: .5rem 1rem;
        border-radius: .25rem;
        margin-bottom: .5rem;
        transition: background-color .5s;
        white-space: nowrap;
        overflow: hidden;

        &.active, &:hover {
            background-color: tint(@sidebar-bg, 30);
        }


        span {
            margin-left: 10px;
        }
    }

    &.sidebar-collapsed {
        width: @sidebar-width-collapsed;
        transition: 0.25s ease-out;
        transition-property: width;

        @media (max-width: 768px) {
            width: 0;
            overflow: hidden;
        }

        .sidebar-link {
            padding-left: 0;
            padding-right: 0;
            text-align: center;

            span {
                display: none;
            }
        }

    }
}

.sidebar-icon-fix {
    position: relative;
    top: -2px;
}

.theme-dark {

    .sidebar {
        background: @dark-purple;

        &-link {
            &.active, &:hover {
                background-color: tint(@dark-purple, 30);
            }
        }



    }

}


</style>
