<script>

import Default from "./Default";
import AirQualityHeatingTable from "./AirQualityHeatingTable";

export default {
    name: "AirQualityHeating",
    extends: Default,
    components: {
        DefaultLineChart: AirQualityHeatingTable,
        DefaultTable: AirQualityHeatingTable
    },
}
</script>
