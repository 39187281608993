<template>
    <div class="row mb-2" style="min-height: 140px">
        <div class="col-12 position-relative">
            <div class="list-group">
                <div class="list-group-item px-2 list-group-item-action font-weight-bold justify-content-between align-items-center"
                     v-for="(metric, index) in paginatedData"
                     :key="index"
                     :class="{ active : isSelected(metric) }"
                     @click="$emit('change', metric)">
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="pr-2">
                            <div class="position-relative pr-1">
                                <span v-if="metric.getPortIndex() !== null" class="metric-icon-index">
                                    {{ metric.getPortIndex() + 1 }}
                                </span>

                                <Icon :icon-name="metric.getIcon()"
                                      width="23"
                                      height="23"
                                      :fill="metric.getColor()"/>
                            </div>

                            <Icon v-if="isSelected(metric)" class="d-block mx-auto mt-1"
                                  icon-name="dot"
                                  :fill="isSelected(metric) ? metric.getColor() : ''"
                                  width="4"
                                  height="4"/>

                            <div v-else class="mt-1" style="height: 4px"></div>
                        </div>

                        <div v-if="metric.getName().length > 20"
                             class="list-group-item-title"
                             data-toggle="tooltip"
                             :title="metric.getName()">
                            {{ stringLimit(metric.getName(), 20) }}
                        </div>
                        <div v-else class="list-group-item-title">
                            {{ metric.getName() }}
                        </div>
                    </div>
                    <div class="list-group-item-value">
                        {{ getMetricLabel(metric) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Icon from "../../Icon";
import Helper from "../../../classess/Helper";
import DeviceObject from "../../../objects/DeviceObject";

export default {
    name: "DefaultCard_3ValuesList",
    components: {
        Icon,
    },
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        timeseries: {
            type: Array,
            required: false
        },
        currentMetric: {
            required: true
        },
        paginatedData: {
            type: Array,
            required: true
        },
    },
    methods: {
        isSelected(metric) {
            return this.currentMetric?.getSlug() === metric.getSlug()
                && this.currentMetric?.getPortIndex() === metric.getPortIndex()
        },
        stringLimit: Helper.stringLimit,
        getMetricLabel(metric, fixed = 2) {
            const values = this.timeseries?.filter(item => item.getSlug() === metric?.getSlug());

            const value = metric?.getPortIndex()
                ? values[metric?.getPortIndex()]
                : values[0];

            const result = value?.getLatestData()?.getValue();

            return (result || result === 0) ? result?.toFixed(fixed) + " " + metric.getUnits() : "N/A";
        },
    }
}
</script>

<style lang="less" scoped>

@import "../../../less/_variables";

.list-group {
    border-radius: 0;

    &-item {
        display: flex;
        border-color: transparent;
        padding: 0.45rem 1.25rem;
        font-size: 0.9rem;
        cursor: pointer;
        border-radius: .5rem;

        &:hover {
            background: white;
        }

        &-title {
            font-weight: 500;
        }

        &-value {
            font-weight: 600;
            color: #706C6C;
        }

        &.active {
            color: #495057;
            background: #f1f1f1;
        }

        .status-icon {
            background: #FFFFFF;
            border-radius: 3px;
            width: 30px;
            height: 30px;
        }
    }
}

.metric-icon-index {
    user-select: none;
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 1px 7px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
}


</style>
