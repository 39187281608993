<template>
    <div v-show="isDemoCaseAccount" class="btn-group btn-block mb-4">
        <button v-for="(item, index) in cases"
                :key="index"
                :class="{ active : item.name === currentCaseName }"
                @click="currentCaseName = item.name"
                class="btn btn-outline-purple"
                data-toggle="tooltip"
                :title="item.name">
            <Icon :icon-name="item.icon"
                  :fill="item.name === currentCaseName ? '#ffffff' : '#732AAD'"
                  class="mr-2"/>
            <span class="d-none d-xl-inline">{{ stringLimit(item.name, 7) }}</span>
        </button>
        <button class="btn btn-outline-purple"
                :class="{ active : 'all' === currentCaseName }"
                @click="currentCaseName = 'all'">
            <Icon icon-name="dc-all-devices"
                  class="mr-2"
                  :fill="'all' === currentCaseName ? '#ffffff' : '#732AAD'"/>
            <span class="d-none d-xl-inline"> {{ $t('Все устройства') }}</span>
        </button>
    </div>
</template>

<script>

import $ from "jquery";
import {mapState} from "vuex";
import config from "../config";
import Icon from "./Icon";
import Helper from "../classess/Helper";

export default {
    name: "DemoCaseTabs",
    components: {
        Icon
    },
    data() {
        return {
            currentCaseName: "all",
        }
    },
    methods: {
        stringLimit: Helper.stringLimit
    },
    computed: {
        ...mapState({
            currentObject: state => state.currentObject,
        }),
        isDemoCaseAccount() {
            return typeof config.demoCaseAccounts?.[this.currentObject.account_id] !== "undefined";
        },
        cases() {
            return config.demoCaseAccounts[this.currentObject?.account_id];
        }
    },
    watch: {
        currentCaseName(val) {
            $('[data-toggle="tooltip"]').tooltip("hide");
            this.$emit("change", this.cases.find(item => item.name === val));
        }
    }

}
</script>

<style scoped>

</style>
