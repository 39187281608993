import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './translations';
import StorageAdapter from "./classess/StorageAdapter";

Vue.use(VueI18n);

const storage = new StorageAdapter()

export default new VueI18n({
    locale: storage.get("appLocale", "uk"),
    fallbackLocale: 'ua',
    messages,
    silentTranslationWarn: true
})