<template>
    <div class="col-12">
        <div class="d-flex flex-column flex-lg-row">
            <div class="d-none d-xl-flex btn-group flex-grow-1 mr-lg-4 mb-4">
                <button v-for="item in breakpoints" :key="item.name"
                        @click="breakpoint = item.slug"
                        :class="{ active : item.slug === breakpoint && breakpoint !== 'custom' }"
                        class="btn btn-outline-purple">
                    {{ item.name }}
                </button>
            </div>
            <div class="dropdown d-xl-none mr-lg-4 mb-4">
                <button class="btn btn-block btn-purple dropdown-toggle" type="button" data-toggle="dropdown">
                    {{ $t('Выбрать диапазон') }}
                </button>
                <div class="dropdown-menu">
                    <a v-for="item in breakpoints" :key="item.name"
                       @click.prevent="breakpoint = item.slug"
                       :class="{ active : item.slug === breakpoint && breakpoint !== 'custom' }"
                       class="dropdown-item" href="#">
                        {{ item.name }}
                    </a>
                </div>
            </div>
            <div class="mr-lg-4 flex-grow-1 mb-4">
                <date-picker v-model="customPeriod"
                             class="w-100"
                             type="date"
                             :disabled-date="isDisabledDate"
                             :class="{ 'active-custom-breakpoint' : breakpoint === 'custom' } "
                             range
                             :lang="$store.state.appLocale"
                             input-class="form-control py-2"
                             :placeholder="$t('Выбрать диапазон дат')">
                </date-picker>
            </div>
            <div class="flex-grow-0 d-block mb-4">
                <div v-if="hasAggregatedMetrics" class="btn-group w-100">
                    <button @click="aggregate = false"
                            :class="{ active : !aggregate }"
                            class="btn btn-outline-purple">
                        <i class="fas fa-stream fa-fw"></i>
                        <span class="d-none d-xxl-inline pl-1">{{ $t("Без агрегации") }}</span>
                    </button>
                    <button @click="aggregate = true"
                            :class="{ active : aggregate }"
                            class="btn btn-outline-purple">
                        <i class="fas fa-layer-group fa-fw"></i>
                        <span class="d-none d-xxl-inline pl-1">{{ $t("Агрегация") }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import moment from "moment-mini";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/uk';
import 'vue2-datepicker/locale/ru';
import DeviceObject from "../objects/DeviceObject";
import metricsConfig from "../metricsConfig";

export default {
    name: "DevicePeriodPicker",
    components: {
        DatePicker
    },
    props: {
        deviceVisibleMetrics: {
            type: Array,
            required: true,
        },
        deviceObject: {
            type: DeviceObject,
            required: true
        }
    },
    data() {
        return {
            aggregate: false,
            breakpoint: "today",
            breakpoints: [
                {
                    slug: "today",
                    name: this.$t('Сегодня'),
                    period: [
                        moment().endOf("day").format(),
                        moment().startOf("day").format()
                    ],
                    groupBy: "hour"
                },
                {
                    slug: "yesterday",
                    name: this.$t('Вчера'),
                    period: [
                        moment().subtract(1, "days").endOf("day").format(),
                        moment().subtract(1, "days").startOf("day").format(),
                    ],
                    groupBy: "hour"
                },
                {
                    slug: "before_yesterday",
                    name: this.$t('Позавчера'),
                    period: [
                        moment().subtract(2, "days").endOf("day").format(),
                        moment().subtract(2, "days").startOf("day").format()
                    ],
                    groupBy: "hour"
                },
                {
                    slug: "week",
                    name: this.$t('Неделя'),
                    period: [
                        moment().endOf("day").format(),
                        moment().subtract(1, "weeks").startOf("day").format()
                    ],
                    groupBy: "day"
                },
                {
                    slug: "month",
                    name: this.$t('Месяц'),
                    period: [
                        moment().endOf("day").format(),
                        moment().subtract(1, "months").startOf("day").format()
                    ],
                    groupBy: "day"
                },
            ],
            customPeriod: []
        }
    },
    methods: {
        isDisabledDate(date, currentValue) {
            if (currentValue.length) {
                return moment(currentValue[0]).add(-2, 'month') > date || date > moment(currentValue[0]).add(2, 'month') || moment(date).isAfter()
            }
            return moment(date).isAfter();
        },
    },
    computed: {
        selectedPeriod() {
            if (this.customPeriod.length) {
                let start = moment(this.customPeriod[1] || this.customPeriod[0]).endOf("day");
                let end = moment(this.customPeriod[0]).startOf("day");
                this.breakpoint = "custom"

                return {
                    slug: "custom",
                    name: this.$t('Произвольный диапазон'),
                    period: [start.format(), end.format()],
                    groupBy: this.aggregate ? start.diff(end, 'days') >= 1 ? "day" : "hour" : null
                };
            }

            const selected = JSON.parse(JSON.stringify(this.breakpoints.find(item => item.slug === this.breakpoint)));

            if (this.aggregate === false) {
                selected.groupBy = null;
            }

            return selected;
        },
        hasAggregatedMetrics() {
            const deviceTypeSlug = this.deviceObject?.getType()?.getSlug();

            const countNotAggregated = this.deviceVisibleMetrics?.filter(item => {
                return metricsConfig[deviceTypeSlug]?.notAggregatedMetrics?.indexOf(item.getSlug()) > -1;
            })?.length;

            return this.deviceVisibleMetrics?.length - countNotAggregated > 0;
        },
    },
    watch: {
        breakpoint(val) {
            if (val !== 'custom') {
                this.customPeriod = [];
            }
        },
        selectedPeriod(val) {
            this.$emit("change", val);
        },
    },
    mounted() {
        this.$emit("change", this.selectedPeriod);
    }
}
</script>

<style scoped>
</style>
