<template>
    <div ref="latestValue" class="latest-value animate__animated">
        <div class="latest-value__bg" :style="metricImage">
            <div v-if="value !== null">
                <div class="latest-value__value">{{ value }}</div>
                <div class="latest-value__units">{{ units }}</div>
            </div>
            <div v-else>
                <div class="latest-value__value">N/A</div>
                <div class="latest-value__units">{{ units }}</div>
            </div>
        </div>
    </div>
</template>

<script>

import DeviceObject from "../../../objects/DeviceObject";

export default {
    name: "DefaultCard_1LatestValue",
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        timeseries: {
            type: Array,
            required: false
        },
        currentMetric: {
            required: true,
        },
        metricImage: {
            required: true
        }
    },
    computed: {
        intValue() {
            return parseInt(this.value);
        },
        value() {
            const val = this.metricTimeseries?.getLatestData()?.getValue();

            if (val || val === 0) {
                return val.toFixed(2);
            }

            return null;
        },
        units() {
            return this.deviceObject
                ?.getType()
                ?.getMetricBySlug(this.currentMetric?.getSlug())
                ?.getUnits() || "-"
        },
        metricTimeseries() {
            const values = this.timeseries?.filter(item => item.getSlug() === this.currentMetric?.getSlug());
            return this.currentMetric?.getPortIndex()
                ? values[this.currentMetric?.getPortIndex()]
                : values[0];
        }
    },
    watch: {
        currentMetric() {
            this.$refs.latestValue?.classList.add("animate__bounceIn");

            setTimeout(() => {
                this.$refs.latestValue?.classList.remove("animate__bounceIn");
            }, 500);
        }
    }
}
</script>

<style lang="less" scoped>
@import "~animate.css";

.latest-value {
    width: 100%;
    padding: 0;

    &__bg {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 170px;
        height: 170px;
        background-color: #ccc;
        border-radius: 100%;
        color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__value {
        text-align: center;
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 1;
        color: #fff;
    }

    &__units {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
        color: #fff;
    }

    .status-icon {
        background-color: white;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
    }
}
</style>
