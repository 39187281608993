import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'
import store from "../store"
import NotFound from "../views/NotFound";
import Auth from "../views/auth/Auth";
import types from "../store/types";
import Devices from "../views/Devices";
import Device from "../views/Device";
import DeviceAccess from "../views/DeviceAccess";
import Accesses from "../views/Accesses";
import DeviceAlarm from "../views/DeviceAlarm";
import Settings from "../views/Settings";
import Support from "../views/Support";
import Accidents from "../views/Accidents";
import Alarms from "../views/Alarms";
import i18n from "../i18n";
import $ from "jquery";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: i18n.t('Главная')
        }
    },
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        meta: {
            title: i18n.t('Авторизация')
        }
    },
    {
        path: '/devices',
        name: 'Devices',
        props: true,
        component: Devices,
        meta: {
            title: i18n.t('Устройства')
        }
    },
    {
        path: '/device/:id',
        name: 'Device',
        props: true,
        component: Device,
        meta: {
            title: i18n.t('Графики')
        }
    },
    {
        path: '/device/:id/alarm',
        name: 'DeviceAlarm',
        props: true,
        component: DeviceAlarm,
        meta: {
            title: i18n.t('Настройки аварий')
        }
    },
    {
        path: '/device/:deviceId/access',
        name: 'DeviceAccess',
        props: true,
        component: DeviceAccess,
        meta: {
            title: i18n.t('Настройки доступа')
        }
    },
    {
        path: '/accesses',
        name: 'Accesses',
        props: true,
        component: Accesses,
        meta: {
            title: i18n.t('Настройки доступа')
        }
    },
    {
        path: '/alarms',
        name: 'Alarms',
        props: true,
        component: Alarms,
        meta: {
            title: i18n.t('Аварии')
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        props: true,
        component: Settings,
        meta: {
            title: i18n.t('Настройки')
        }
    },
    {
        path: '/support',
        name: 'Support',
        props: true,
        component: Support,
        meta: {
            title: i18n.t('Поддержка')
        }
    },
    {
        path: '/accidents/:deviceId?/:alarmId?',
        name: 'Accidents',
        props: true,
        component: Accidents,
        meta: {
            title: i18n.t('Инциденты')
        }
    },
    {
        path: '/profile/',
        name: 'Profile',
        props: true,
        component: Profile,
        meta: {
            title: i18n.t('Профиль')
        }
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: i18n.t('Не найдено')
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(function (to, from, next) {
    if (!(store.state.token?.length && store.state.refresh?.length) && to.name !== "Auth") {
        window.location.replace("/auth");
        return;
    }

    store.commit(types.PRELOADER, true);

    // скрываем тултипы
    $('[data-toggle="tooltip"]').tooltip('hide')

    // на мобильных при смене роута сворачиваем сайдбар
    if (window.outerWidth < 768) {
        store.commit(types.SIDEBAR_COLLAPSED, true);
    }

    if (to?.meta?.title) {
        document.title = to.meta.title;
    }

    if (to.name === "Auth") {
        next();
    } else {
        setTimeout(() => {
            next();
        }, 500)
    }
});

export default router
