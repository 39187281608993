<template>
    <small>
        <ul class="list-group">
            <li v-for="item in getAccessMetrics"
                :key="item.getId()"
                class="list-group-item py-1 px-2">
                <div class="row">
                    <div class="col-2">
                        <Icon :iconName="item.getIcon()" :fill="item.getColor()"></Icon>
                    </div>
                    <div class="col-10 font-weight-bold">
                        {{ item.getName() }}
                    </div>
                </div>
            </li>
        </ul>
    </small>
</template>

<script>

import _ from "underscore";
import DeviceObject from "../objects/DeviceObject";
import Icon from "./Icon";

export default {
    name: "DeviceAccessTableItemMetrics",
    components:{
        Icon
    },
    props: {
        access: {
            type: Object,
            required: true
        },
        deviceObject: {
            type: DeviceObject,
            required: true
        }
    },
    computed: {
        getAccessMetrics() {
            return _.chain(this.access?.metrics)
                .map(item => {
                    return this.deviceObject.getType().getMetricBySlug(item.name);
                })
                .filter(item => item)
                .value()
        },
    }
}
</script>

<style scoped>

</style>
