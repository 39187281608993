import types from "../types";
import StorageAdapter from "../../classess/StorageAdapter";
import i18n from "../../i18n";

const storage = new StorageAdapter();

export const DevicesSettingsDropdown = {
    state() {
        return {
            deviceCardCompactMode: storage.get("deviceCardCompactMode", false),
            deviceNameOnCard: storage.get("deviceNameOnCard", true),
            deviceCardAccidentsHighlight: storage.get("deviceCardAccidentsHighlight", false),
            currentCardDesign: storage.get("currentCardDesign", 1),
            availableDesigns: [
                {value: 1},
                {value: 2},
                {value: 3},
            ],
        }
    },
    mutations: {
        [types.DEVICE_CARD_COMPACT_MODE](state, payload) {
            state.deviceCardCompactMode = payload;
            storage.set("deviceCardCompactMode", payload);
        },
        [types.DEVICE_NAME_ON_CARD](state, payload) {
            state.deviceNameOnCard = payload;
            storage.set("deviceNameOnCard", payload);
        },
        [types.DEVICE_CARD_ACCIDENTS_HIGHLIGHT](state, payload) {
            state.deviceCardAccidentsHighlight = payload;
            storage.set("deviceCardAccidentsHighlight", payload);
        },
        [types.SET_CARD_DESIGN](state, payload) {
            state.currentCardDesign = payload;
            storage.set("currentCardDesign", payload);
        },
    }
}
