<template>
    <div class="d-flex justify-content-between mb-3">

        <Icon data-toggle="tooltip"
              data-placement="top"
              data-html="true"
              :data-original-title="`${deviceObject.getName()} <br /> ${deviceObject.getDescription()}`"
              icon-name="info"
              width="30"
              height="30"
              :fill="iconColor"/>

        <DefaultCard_1AccidentsIndicator
            :deviceObject="deviceObject"
            @onHasAccidents="val => this.hasAccidents = val"/>

        <Icon icon-name="clock"
              data-toggle="tooltip"
              data-placement="top"
              :title="lastUpstreamDateAgo"
              :fill="iconColor"
              width="28"
              height="28"/>

        <div class="p-0 m-0 cursor-pointer"
             data-toggle="tooltip"
             data-placement="top"
             :title="$t('Настройки')">

            <Icon icon-name="setting"
                  data-toggle="dropdown"
                  :fill="iconColor"
                  width="28"
                  height="28"/>

            <div class="dropdown-menu app-dropdown">
                <a v-if="isCurrentUserDevice" class="dropdown-item" href="#" @click.prevent="$emit('deviceDoUpdate', deviceObject)">
                    <i class="fas fa-pen pr-4 text-purple fa-fw"></i>
                    {{ $t('Редактировать') }}
                </a>
                <a class="dropdown-item" href="#" @click.prevent="$emit('deviceDoShowGeoPosition', deviceObject)">
                    <i class="fas fa-map-marker-alt pr-4 text-primary fa-fw"></i>
                    {{ $t('Местоположение') }}
                </a>
                <a v-if="isCurrentUserDevice" class="dropdown-item" href="#"
                   @click.prevent="$router.push({name: 'DeviceAlarm', params: { id: deviceObject.getId() }})">
                    <i class="fas fa-bell pr-4 text-warning fa-fw"></i>
                    {{ $t('Настройка аварий') }}
                </a>
                <a v-if="isCurrentUserDevice && hasAggregatedMetrics" class="dropdown-item" href="#" @click.prevent="$eventBus.emit('DEVICE_COEFFICIENT_MODAL_OPEN', {deviceObject: deviceObject})">
                    <i class="fas fa-percent pr-4 text-primary fa-fw"></i>
                    {{ $t('Поправочный коэффициент') }}
                </a>
                <a v-if="isCurrentUserDevice" class="dropdown-item" href="#"
                   @click.prevent="$router.push({name: 'Accidents', params: {deviceId: deviceObject.getId() }})">
                    <i class="fas fa-exclamation-circle pr-4 text-danger fa-fw"></i>
                    {{ $t('Обзор инцидентов') }}
                </a>
                <a v-if="isCurrentUserDevice" class="dropdown-item" href="#"
                   @click.prevent="$router.push({name: 'DeviceAccess', params: { deviceId: deviceObject.getId() }})">
                    <i class="fas fa-share-alt pr-4 text-info fa-fw"></i>
                    {{ $t('Управление доступом') }}
                </a>
                <a class="dropdown-item" href="#" @click.prevent="switchAutoUpdate">
                    <i class="fas fa-sync-alt pr-4 fa-fw"
                       :class="{ 'text-success' : !isEnabledAutoUpdate, 'text-muted' : isEnabledAutoUpdate }"></i>
                    {{ isEnabledAutoUpdate ? $t('Отключить авто-обновление') : $t('Включить авто-обновление') }}
                </a>
                <div v-if="isCurrentUserDevice" class="dropdown-divider"></div>
                <a v-if="isCurrentUserDevice" class="dropdown-item" href="#"
                   @click.prevent="$emit('deviceDoRemove', deviceObject)">
                    <i class="fas fa-trash-alt pr-4 text-danger fa-fw"></i>
                    {{ $t('Удалить устройство') }}
                </a>
            </div>
        </div>

        <DefaultCard_1HeaderBattery v-if="deviceBatVolt"
                                    :deviceObject="deviceObject"
                                    :timeseries="timeseries"/>
    </div>
</template>

<script>

import _ from "underscore";
import TimeAgo from 'javascript-time-ago';
import ru from 'javascript-time-ago/locale/ru.json';
import uk from 'javascript-time-ago/locale/uk.json';
import {mapState} from "vuex";
import DeviceObject from "../../../objects/DeviceObject";
import types from "../../../store/types";
import Icon from "../../Icon";
import DefaultCard_1HeaderBattery from "./DefaultCard_1HeaderBattery";
import DefaultCard_1AccidentsIndicator from "./DefaultCard_1AccidentsIndicator";
import metricsConfig from "../../../metricsConfig"

export default {
    name: "DefaultCard_1Header",
    components: {
        DefaultCard_1AccidentsIndicator,
        DefaultCard_1HeaderBattery,
        Icon
    },
    data: () => ({
        hasAccidents: false
    }),
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        timeseries: {
            type: Array,
            required: false
        },
    },
    methods: {
        ago(date) {
            if (!date) return this.$t('Нет данных');
            const timeAgo = new TimeAgo(this.$i18n.locale);
            return this.$t("Обновлено") + " " + timeAgo.format(new Date(date));
        },
        isCurrentUserDevice() {
            return this.deviceObject.getAccountId() === this.currentObject?.account_id;
        },
        switchAutoUpdate() {
            let devices = [...this.autoUpdateDevices];

            if (this.isEnabledAutoUpdate) {
                devices = _.without(devices, this.deviceObject.getId());
            } else {
                devices.push(this.deviceObject.getId());
            }

            this.$store.commit(types.SET_AUTO_UPDATE_DEVICES, devices);
        }
    },
    computed: {
        ...mapState({
            currentCardDesign: state => state.DevicesSettingsDropdown.currentCardDesign,
            currentObject: state => state.currentObject,
            lastUpstreamDateAgo() {
                return this.ago(this.deviceObject.getLastUpstreamDate())
            },
            autoUpdateDevices: state => state.autoUpdateDevices
        }),
        hasAggregatedMetrics() {
            const metrics = this.deviceObject.getType().excludeInternalMetrics().getMetricsWithPorts();
            const deviceTypeSlug = this.deviceObject?.getType()?.getSlug();

            const countNotAggregated = metrics?.filter(item => {
                return metricsConfig[deviceTypeSlug]?.notAggregatedMetrics?.indexOf(item.getSlug()) > -1;
            })?.length;

            return metrics?.length - countNotAggregated > 0;
        },
        isEnabledAutoUpdate() {
            return this.autoUpdateDevices.indexOf(this.deviceObject.getId()) !== -1;
        },
        deviceBatVolt() {
            return this.deviceObject?.getType()?.getMetrics()?.map(item => item.getSlug()).includes('bat_volt')
        },
        iconColor() {
            return this.currentCardDesign === 2 ? 'white' : '#B9B9BA';
        }
    },
    created() {
        TimeAgo.addLocale(ru);
        TimeAgo.addLocale(uk);
    },
}
</script>

<style lang="less">

.tooltip {
    font-family: Montserrat, sans-serif;
    font-size: .8rem;

    &-inner {
        border-radius: .6rem;
        padding: .5rem 1rem;
    }
}
</style>
