import AbstractService from "./AbstractService";

export default class DeviceTimeseriesService extends AbstractService {

    constructor() {
        super();
    }

    async get(id, start = null, end = null, groupBy = null, limit = null) {

        const data = {};

        if (start) data["datetime[before]"] = start;
        if (end) data["datetime[after]"] = end;
        if (groupBy) data.period = groupBy;
        if (limit) data.limit = limit;

        const {code, body} = await this.request.get("/devices/" + id + "/timeseries", data);

        return code === 200 ? body["hydra:member"] : [];
    }
}
