export default class Color {

    static primary() {
        return "#0083FF";
    }

    static danger() {
        return "#FF2A64";
    }

    static success() {
        return "#27AE60";
    }

    static info() {
        return "#FE9900";
    }

    static adjust(color, amount) {
        return '#' + color.replace(/^#/, '')
            .replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
    }

    static rgbToYIQ({r, g, b}) {
        return ((r * 299) + (g * 587) + (b * 114)) / 1000;
    }

    static hexToRgb(hex) {
        if (!hex || hex === undefined || hex === '') {
            return undefined;
        }

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : undefined;
    }

    static hexToRGBA(hex, opacity) {
        const tempHex = hex.replace('#', '');
        const r = parseInt(tempHex.substring(0, 2), 16);
        const g = parseInt(tempHex.substring(2, 4), 16);
        const b = parseInt(tempHex.substring(4, 6), 16);

        return `rgba(${r},${g},${b},${opacity / 100})`;
    }

    contrast(colorHex, threshold = 128) {
        if (colorHex === undefined) {
            return '#000';
        }

        const rgb = Color.hexToRgb(colorHex);

        if (rgb === undefined) {
            return '#000';
        }

        return Color.rgbToYIQ(rgb) >= threshold ? '#000' : '#fff';
    }

}