<template>
    <Layout>
        <div class="row">
            <div class="col-12 mb-4">
                <h4 class="title">{{ $t('Обзор инцидентов') }}</h4>
            </div>
        </div>
        <AccidentsFilterButton v-if="(deviceId || (deviceId && alarmId)) && accidents.length"
                               :accidents="accidents"
                               :deviceId="deviceId"
                               :alarmId="alarmId"/>
        <div v-if="accidents.length" class="row">
            <div class="col-12 mb-4">
                <AccidentsTable :accidents="accidents"
                                :totalRows="totalRows"
                                @onSortChange="onSortChangeHandler"
                                @onPageChange="onPageChangeHandler"
                                @onPerPageChange="onPerPageChangeHandler"
                                @select="accident => this.currentAccident = accident"/>
            </div>
        </div>
        <CenterBlock v-else>
            <div class="text-center">
                <i class="far fa-check-circle text-muted fa-5x mb-4"></i>
                <h4 class="text-muted text-center">{{ $t('Все хорошо, инцидентов нет!') }}</h4>
            </div>
        </CenterBlock>
        <AccidentsLogModal :opened="!!currentAccident"
                           :currentAccident="currentAccident"
                           @closed="currentAccident = null"/>
    </Layout>
</template>

<script>

import types from "../store/types";
import AccidentService from "../services/AccidentService";
import AlarmTypeService from "../services/AlarmTypeService";
import Layout from "../components/Layout";
import AccidentsTable from "../components/AccidentsTable";
import AccidentsFilterButton from "../components/AccidentsFilterButton";
import CenterBlock from "../components/CenterBlock";
import AccidentsLogModal from "@/components/modals/AccidentsLogModal";

const Accident = new AccidentService();
const AlarmType = new AlarmTypeService()

export default {
    name: 'Accidents',
    components: {
        AccidentsTable,
        AccidentsFilterButton,
        Layout,
        CenterBlock,
        AccidentsLogModal
    },
    props: {
        deviceId: {
            type: String,
            required: false,
            default: null
        },
        alarmId: {
            required: false,
            default: null
        }
    },
    data() {
        return {
            accidents: [],
            currentAccident: null,
            orderBy: 'created_at',
            orderType: 'desc',
            page: 1,
            perPage: 10,
            totalRows: 0
        }
    },
    methods: {
        async loadAccidents() {
            let accidents = [];
            const body = this.deviceId
                ? await Accident.fetchByDevice(this.deviceId, this.orderBy, this.orderType, this.page, this.perPage)
                : await Accident.fetch(this.orderBy, this.orderType, this.page, this.perPage);

            accidents = body["hydra:member"];
            this.totalRows = body["hydra:totalItems"];

            if (this.alarmId) {
                accidents = accidents.filter(item => {
                    let alarmId = item.alarm_type.split("/").reverse()[0] * 1;
                    return alarmId === this.alarmId * 1;
                });
            }

            for (let index in accidents) {
                accidents[index].alarm = await AlarmType.getWitchCache(accidents[index].alarm_type.split("/").reverse()[0]);
            }

            this.accidents = accidents;
            this.$store.commit(types.PRELOADER, false);
        },
        async loadAccidentsShowSimplePreloader() {
            this.$store.commit(types.SIMPLE_PRELOADER, true);
            await this.loadAccidents();
            this.$store.commit(types.SIMPLE_PRELOADER, false);
        },
        async onSortChangeHandler(params) {
            if (params[0].field === 'level') return;

            this.orderBy = params[0].field;
            this.orderType = params[0].type;
            this.page = 1;

            if (params[0].type === 'none') {
                this.orderBy = 'created_at';
                this.orderType = 'desc';
            }

            await this.loadAccidentsShowSimplePreloader();
        },
        async onPageChangeHandler(params) {
            this.page = params.currentPage;
            this.currentAccident = null;
            await this.loadAccidentsShowSimplePreloader();
        },
        async onPerPageChangeHandler(params) {
            this.perPage = params.currentPerPage;
            this.page = 1;
            this.currentAccident = null;
            await this.loadAccidentsShowSimplePreloader();
        }
    },
    async beforeRouteUpdate(to, from, next) {
        next();
        this.$nextTick(async () => {
            await this.loadAccidents();
        });
    },
    async mounted() {
        await this.loadAccidents();
    }
}
</script>

<style lang="less">

</style>
