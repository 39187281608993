export default class StorageAdapter {

    constructor(name = "main") {
        this.diskName = name;
    }

    disk(name) {
        this.diskName = name;
        return this;
    }

    removeDisk(name) {
        localStorage.removeItem(name);
    }

    set(key, value) {
        this.currentDiskData = Object.assign(this.currentDiskData, {
            [key]: value
        });
    }

    get(key, defaultValue = null) {
        return this.currentDiskData.hasOwnProperty(key)
            ? this.currentDiskData[key]
            : defaultValue;
    }

    remove(key) {
        const prevData = this.currentDiskData;
        delete prevData[key];
        this.currentDiskData = prevData;
    }

    exists(key) {
        return this.currentDiskData.hasOwnProperty(key);
    }

    get currentDiskData() {
        try {
            const data = JSON.parse(localStorage.getItem(this.diskName));
            return typeof data === 'object' && data !== null ? data : {};
        } catch (e) {
            return {};
        }
    }

    set currentDiskData(val) {
        localStorage.setItem(this.diskName, JSON.stringify(val));
    }

}