import store from "../store"

export default class Request {

    async _request(url, method, data, headers) {

        const options = {
            method: method.toUpperCase(),
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': store.state.appLocale,
                ...headers
            }
        }

        if (method !== "get") {
            options.body = JSON.stringify(data)
        }

        if (method === "get" && Object.keys(data).length > 0) {
            url += "?" + new URLSearchParams(data);
        }

        const response = await fetch(url, options);
        let body;

        try {
            body = await response.json();
        } catch (e) {
            body = null;
        }

        return {
            code: response.status,
            body: body
        }
    }

    get(url, data = {}, headers = {}) {
        return this._request(url, "get", data, headers);
    }

    post(url, data = {}, headers = {}) {
        return this._request(url, "post", data, headers);
    }

    put(url, data = {}, headers = {}) {
        return this._request(url, "put", data, headers);
    }

    path(url, data = {}, headers = {}) {
        return this._request(url, "path", data, headers);
    }

    delete(url, data = {}, headers = {}) {
        return this._request(url, "delete", data, headers);
    }
}
