<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <h4 class="title mb_40">
                    {{ $t('Доступы') }}
                    <button @click="addAccessModal = true" class="btn btn-purple ml-3">
                        <i class="fas fa-plus fa-fw"></i>
                        <span class="d-none d-md-inline pl-2">{{ $t("Открыть доступ") }}</span>
                    </button>
                </h4>
            </div>
            <div v-if="accesses.length" class="col-12 mb-4">
                <vue-good-table
                    :columns="columns"
                    :rows="accesses"
                    :pagination-options="vgtPaginationOptions()">
                    <template slot="table-row" slot-scope="props">
                        <small v-if="props.column.field === 'device'">
                            <a href="#"
                               class="font-weight-bold"
                               @click.prevent="$router.push({name: 'Devices', query: { highlight_device_id : getDeviceObjectByType(props.row.device).getId() }})">
                                {{ getDeviceObjectByType(props.row.device).getName() | stringLimit }}
                            </a>
                        </small>
                        <small v-else-if="['start', 'stop'].indexOf(props.column.field) > -1">
                            {{ dateFormat(props.formattedRow[props.column.field]) }}
                        </small>
                        <DeviceAccessTableItemMetrics v-else-if="props.column.field === 'metrics'"
                                                      :access="props.row"
                                                      :deviceObject="getDeviceObjectByType(props.row.device)"/>

                        <small v-else-if="props.column.field === 'status'">
                                <span v-if="isActiveDate(props.row)" class="text-success">
                                     <i class="fas fa-unlock fa-lg pr-1"></i>
                                    {{ $t('Доступ открыт') }}
                                </span>
                            <span v-else class="text-danger">
                                     <i class="fas fa-lock fa-lg pr-1"></i>
                                    {{ $t('Доступ закрыт') }}
                                </span>
                        </small>
                        <small v-else-if="props.column.field === 'action'">
                                <span class="btn-group btn-block">
                                    <button @click="updateAccessModal = true; currentAccessIndex = props.index" class="btn btn-sm btn-purple">
                                        {{ $t('Править') }}
                                    </button>
                                    <button @click="removeAccessModal = true; currentAccessIndex = props.index" class="btn btn-sm btn-danger">
                                        {{ $t('Удалить') }}
                                    </button>
                                </span>
                        </small>
                        <div v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </div>
                    </template>
                </vue-good-table>
            </div>
            <CenterBlock v-else>
                <div class="text-center">
                    <Icon icon-name="access"
                          class="mb-4"
                          fill="#6C757D"
                          width="70"
                          height="70"/>
                    <h4 class="text-muted text-center mb-4">{{ $t('Вы еще не открывали доступ к устройствам') }}</h4>
                    <button @click="addAccessModal = true" class="btn btn-outline-purple">{{ $t("Открыть доступ") }}</button>
                </div>
            </CenterBlock>
        </div>
        <DeviceAccessAddModal :showDevicesList="true"
                              :opened="addAccessModal"
                              @created="access => this.accesses.unshift(access)"
                              @closed="() => this.addAccessModal = false"/>

        <DeviceAccessUpdateModal :showDevicesList="true"
                                 :access="accesses[currentAccessIndex]"
                                 :opened="updateAccessModal"
                                 @updated="updateAccess"
                                 @closed="() => {this.updateAccessModal = false; this.currentAccessIndex = null}"/>

        <RemoveModal :opened="removeAccessModal"
                     :processing="removeProcessing"
                     :title="$t('Удалить доступ?')"
                     :text="$t('Вы уверены что хотите удалить доступ? Это действие нельзя будет отменить!')"
                     :buttonText="$t('Удалить')"
                     @removed="removeAccess"
                     @closed="() => {this.removeAccessModal = false; this.currentAccessIndex = null}"/>

    </Layout>
</template>

<script>

import Layout from "../components/Layout";
import _ from "underscore";
import types from "../store/types";
import DeviceAccessService from "../services/DeviceAccessService";
import DeviceAccessAddModal from "../components/modals/DeviceAccessAddModal";
import DeviceAccessUpdateModal from "../components/modals/DeviceAccessUpdateModal";
import {mapState} from "vuex";
import Helper from "../classess/Helper";
import moment from "moment-mini";
import Icon from "../components/Icon";
import RemoveModal from "../components/modals/RemoveModal";
import config from "../config";
import DeviceAccessTableItemMetrics from "../components/DeviceAccessTableItemMetrics";
import DeviceObject from "../objects/DeviceObject";
import CenterBlock from "../components/CenterBlock";

const DeviceAccess = new DeviceAccessService();

export default {
    name: 'Accesses',
    components: {
        Layout,
        DeviceAccessAddModal,
        DeviceAccessUpdateModal,
        RemoveModal,
        DeviceAccessTableItemMetrics,
        Icon,
        CenterBlock,
    },
    filters: {
        stringLimit: str => Helper.stringLimit(str, 30)
    },
    data() {
        return {
            addAccessModal: false,
            removeAccessModal: false,
            removeProcessing: false,
            updateAccessModal: false,
            accesses: [],
            columns: [
                {
                    label: this.$t('Устройство'),
                    field: 'device',
                    sortable: false,
                },
                {
                    label: this.$t('Открытые метрики'),
                    field: 'metrics',
                    sortable: false,
                    width: "300px"
                },
                {
                    label: this.$t('Аккаунт ID'),
                    field: 'account_id',
                },
                {
                    label: this.$t('Доступ открыт с'),
                    field: 'start',
                    sortable: false
                },
                {
                    label: this.$t('Доступ открыт до'),
                    field: 'stop',
                    sortable: false,
                },
                {
                    label: this.$t('Статус'),
                    field: 'status',
                    sortable: false,
                },
                {
                    label: this.$t('Действия'),
                    field: 'action',
                    sortable: false,
                },
            ],
            currentAccessIndex: null
        }
    },
    methods: {
        vgtPaginationOptions: config.vgtPaginationOptions,
        getDeviceObjectByType(type) {
            const id = type.split("/").reverse()[0]
            const device = _.find(this.devices, device => {
                return device.id === id;
            }) || {};

            return new DeviceObject(device);
        },
        dateFormat(date) {
            return date ? moment(date).format(config.dateTimeFormats.DDMMYYYYHHmmss) : "-"
        },
        isActiveDate({start, stop}) {
            return moment(start).isSameOrBefore() && moment(stop).isSameOrAfter();
        },
        async removeAccess() {
            this.removeProcessing = true;
            const removingAccess = this.accesses[this.currentAccessIndex];
            const removingAccessId = removingAccess['@id'].split('/').reverse()[0];
            const result = await DeviceAccess.remove(removingAccessId);
            this.removeProcessing = false;
            this.removeAccessModal = false;
            this.accesses = _.reject(this.accesses, item => item['@id'] === removingAccess['@id']);

            if (result) {
                this.$toast.success(this.$t('Доступ удален'));
            } else {
                this.$toast.error(this.$t('Ошибка!'));
            }
        },
        updateAccess(access) {
            this.accesses = _.map(this.accesses, item => {
                return item['@id'] === access['@id'] ? access : item;
            });
        }
    },
    computed: {
        ...mapState({
            devices: state => state.devices
        }),
    },
    async mounted() {
        this.accesses = (await DeviceAccess.fetch()).reverse();
        this.$store.commit(types.PRELOADER, false);
    }
}
</script>
