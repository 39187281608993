<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content app-modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Лог инцидентов') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <AccidentLogTable :currentAccident="currentAccident"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import $ from "jquery";
import AccidentLogTable from "@/components/AccidentLogTable";

export default {
    name: "AccidentsLogModal",
    components: {
        AccidentLogTable
    },
    props: {
        opened: Boolean,
        currentAccident: {
            required: true
        }
    },
    watch: {
        opened(val) {
            if (val) {
                $(this.$refs.modal).modal("show");
            } else {
                $(this.$refs.modal).modal("hide");
                this.$emit("closed");
            }
        },
    },
    mounted() {
        $(this.$refs.modal).on('hide.bs.modal', () => this.$emit("closed"));
    },
}
</script>

<style lang="less" scoped>

</style>
