<template>
    <div>
        <div class="row">
            <h5 class="col-12 font-weight-bold pb-2">
                <Icon :iconName="metricObject.getIcon()"
                      :fill="metricObject.getColor()"
                      width="32"
                      height="32"
                      class="mr-3"/>
                <span>{{ metricObject.getName() }}</span>
                <span v-if="metricObject.getPortIndex() !== null"> #{{ metricObject.getPortIndex() + 1 }}</span>
                <span v-if="metricPort !== null"> ( port: {{ metricPort }} )</span>
            </h5>
            <div class="col-12 values-list">
                <small class="d-inline-block mr-4 mb-3">
                    {{ $t('Количество открытий') }}
                    <small class="text-muted font-weight-bold">- {{ countOpened }}</small>
                </small>
                <small class="d-inline-block mr-4 mb-3">
                    {{ $t('Количество закрытий') }}
                    <small class="text-muted font-weight-bold"> - {{ countClosed }}</small>
                </small>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-wrapper">
                    <table class="table table-bordered">
                        <thead class="thead-light">
                        <tr>
                            <th scope="col" class="text-center w-25">
                                <i class="far fa-clock mr-2"></i>
                                {{ $t('Дата') }}
                            </th>
                            <th scope="col" class="text-center w-25">
                                <i class="fas fa-calendar-alt mr-2"></i>
                                {{ $t('Время') }}
                            </th>
                            <th scope="col" class="text-danger text-center w-25">
                                <i class="fas fa-unlock mr-2"></i>
                                {{ $t('Открылось') }}
                            </th>
                            <th scope="col" class="text-success text-center w-25">
                                <i class="fas fa-lock mr-2"></i>
                                {{ $t('Закрылось') }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(date, index) in Object.keys(stat)" :key="index">
                            <th scope="row" class="text-center" style="vertical-align: middle;">
                                {{ date }}
                            </th>
                            <td colspan="3" class="p-0 border-0">
                                <table class="table m-0 w-100 border-0">
                                    <tr v-for="event in stat[date]" class="border-0">
                                        <td class="text-center w-33">
                                            {{ getTime(event.getDate()) }}
                                        </td>
                                        <td class="text-center w-33">
                                            <i v-if="event.getValue() === 1" class="fas fa-unlock text-danger"></i>
                                        </td>
                                        <td class="text-center w-33">
                                            <i v-if="event.getValue() === 0" class="fas fa-lock text-success"></i>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import config from "../../config";
import Icon from "../Icon";
import moment from "moment-mini";
import MetricObject from "../../objects/MetricObject";
import DeviceObject from "../../objects/DeviceObject";

export default {
    name: "GerkonStatus",
    components: {
        Icon
    },
    props: {
        metricPort: {
            type: Number,
            required: false,
            default: null
        },
        metricObject: {
            type: MetricObject,
            required: true
        },
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        metricTimeseries: {
            required: true
        },
        selectedPeriod: {
            type: Object,
            required: true
        },
    },
    methods: {
        getTime(date) {
            return moment(date).format(config.dateTimeFormats.HHmmss);
        },
    },
    computed: {
        stat() {
            let stat = {};
            this.metricTimeseries?.getData()?.map((item, index) => {
                if (index === 0) {
                    stat[moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY)] = []
                    stat[moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY)].push(item)
                } else if (stat.hasOwnProperty(moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY))) {
                    stat[moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY)].push(item)
                } else {
                    stat[moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY)] = []
                    stat[moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY)].push(item)
                }
            });

            return stat;
        },
        countOpened() {
            return this.metricTimeseries?.getData()?.reduce((memo, item) => {
                if (item.getValue() === 1) {
                    memo++;
                }
                return memo;
            }, 0);
        },
        countClosed() {
            return this.metricTimeseries?.getData()?.reduce((memo, item) => {
                if (item.getValue() === 0) {
                    memo++;
                }
                return memo;
            }, 0);
        },
    }
}
</script>

<style scoped lang="less">

.table-wrapper {
    max-height: 400px;
    overflow-y: auto;
}

.values-list > small {
    background: #eaeaea;
    padding: .3rem 1rem;
    border-radius: 1rem;
}

</style>
