<template>
    <LineChartGenerator :chart-data="chartData" :chart-options="chartOptions" :width="330" :height="190"/>
</template>


<script>
import {Line as LineChartGenerator} from 'vue-chartjs/legacy'
import moment from "moment-mini";
import config from "../../config";
import 'chart.js/auto'

export default {
    components: {LineChartGenerator},
    props: {
        metricTimeseries: {
            type: Array,
            required: true
        },
        animationDuration: {
            type: Number,
            required: false,
            default: 1000
        }
    },
    computed: {
        slicedTimeseries() {
            return this.metricTimeseries.slice(0, 10).reverse();
        },
        hasNotTodayDate() {
            for (let item of this.slicedTimeseries) {
                if (!moment(item?.getDate()).isSame(new Date(), "day")) {
                    return true;
                }
            }
            return false;
        },
        chartData() {
            const parseLabel = (item) => {
                const format = this.hasNotTodayDate ? config.dateTimeFormats.DDMMHHmm : config.dateTimeFormats.HHmm;
                return moment(item?.getDate()).format(format);
            }

            return {
                labels: this.slicedTimeseries?.map(item => parseLabel(item)) || [],
                datasets: [
                    {
                        data: this.slicedTimeseries?.map(item => item?.getValue()) || [],
                        fill: false,
                        borderColor: '#ffffff',
                    }
                ]
            }
        },
        minMaxValues() {
            const percent = 20;
            const values = this.metricTimeseries?.map(item => item.getValue());

            if (!values.length) {
                return {
                    max: undefined,
                    min: undefined,
                }
            }

            const min = Math.min(...values);
            const max = Math.max(...values);
            const delta = max - min;

            // return {
            //     max: Math.ceil((max * percent / 100) + max),
            //     min: Math.floor(min - (min * percent / 100)),
            // }

            return {
                max: Math.ceil(max + delta),
                min: Math.floor(min - delta)
            }
        },
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true
                },
                scales: {
                    x: {
                        grid: {
                            display: true,
                            color: "#FFFFFF",
                            lineWidth: 1,
                            zeroLineColor: "#FFFFFF",
                        },
                        ticks: {
                            backdropPadding: 0,
                            font: {
                                family: "'Open Sans', sans-serif",
                                size: 12,
                                weight: "bold",
                            },
                            color: "rgba(255,255,255, 1)",
                        }
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            display: true,
                            color: "#FFFFFF",
                            lineWidth: 1,
                            zeroLineColor: "#FFFFFF",
                        },
                        suggestedMax: this.minMaxValues.max,
                        suggestedMin: this.minMaxValues.min,
                        ticks: {
                            //step: 5,
                            font: {
                                family: "'Open Sans', sans-serif",
                                size: 12,
                                weight: "bold",
                            },
                            color: "#FFFFFF",
                        }
                    }
                },
                animation: {
                    duration: this.animationDuration
                },
                plugins: {
                    legend: {
                        display: false
                    },
                }
            }
        }
    },
    watch: {
        // metricTimeseries() {
        //     this.$nextTick(() => {
        //         this.renderChart(this.chartData, this.chartOptions);
        //     });
        // },
        // chartOptions() {
        //     this.$nextTick(() => {
        //         this.renderChart(this.chartData, this.chartOptions);
        //     });
        // },
    },
    mounted() {
        // this.renderChart(this.chartData, this.chartOptions);
    }
}
</script>

<style scoped>

</style>
