<template>
    <Layout>
        <div class="row no-gutters" style="height: 2000px">
            404
        </div>
    </Layout>
</template>

<script>

import types from "../store/types";
import Layout from "../components/Layout";

export default {
    name: 'NotFound',
    components: {
        Layout
    },
    mounted() {
        this.$store.commit(types.PRELOADER, false);
    }
}
</script>
