export default class SimpleCron {

    constructor(expression = "* * * * *") {
        this.expressionArray = [];
        this.setCronString(expression);
    }

    setCronString(expression) {
        if (typeof expression === "string" && expression.split(" ").length >= 4) {
            this.expressionArray = expression.split(" ");
        } else {
            this.expressionArray = "* * * * *".split(" ");
        }
    }

    getExpressionString() {
        return this.expressionArray.slice(0, 5).join(" ");
    }

    getExpressionArray() {
        return this.expressionArray;
    }

    getHoursArray() {
        return this.expressionArray[1] === "*"
            ? Array.from(Array(24).keys())
            : this.expressionArray[1].split(",").map(a => Number(a))
    }

    setHoursArray(hours) {
        if (hours.length === 24 || hours.length === 0) {
            this.expressionArray[1] = "*"
        } else {
            this.expressionArray[1] = hours.sort((a, b) => a - b).join(",");
        }

        return this;
    }

    getWeekDaysArray() {
        return this.expressionArray[4] === "*"
            ? Array.from(Array(7).keys())
            : this.expressionArray[4].split(",").map(a => Number(a))
    }

    setWeekDaysArray(weekDays) {
        if (weekDays.length === 7 || weekDays.length === 0) {
            this.expressionArray[4] = "*"
        } else {
            this.expressionArray[4] = weekDays.sort((a, b) => a - b).join(",");
        }

        return this;
    }
}
