<template>
    <div class="line-chart" :style="{ background : chartColor || '#e3e3e3' }">
        <CardLineChart :metricTimeseries="metricTimeseries"
                       :animationDuration="0"
                       :styles="{
                         height: '190px',
                         padding: '1rem',
                         position: 'relative'
                   }"/>
    </div>
</template>

<script>

import CardLineChart from "../CardLineChart";
import DeviceObject from "../../../objects/DeviceObject";

export default {
    name: "DefaultCard_1Chart",
    components: {
        CardLineChart
    },
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        metricObject: {
            required: true
        },
        metricTimeseries: {
            required: true
        }
    },
    computed: {
        chartColor() {
            return this.metricObject?.getColor();
        }
    }
}
</script>

<style lang="less" scoped>
.line-chart {
    width: 100%;
    border-radius: 10px;
}
</style>
