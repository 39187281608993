<template>
    <div class="col-12 mb-4">
        <div class="app-card position-relative" style="min-height: 300px;">
            <EmptyChart v-if="isEmptyMetric"
                        :metricPort="metricPort"
                        :metricObject="metricObject"/>

            <component v-else
                       v-bind:is="currentComponent"
                       :metricPort="metricPort"
                       :metricObject="metricObject"
                       :deviceObject="deviceObject"
                       :timeseries="timeseries"
                       :typeMetricValues="typeMetricValues"
                       :metricTimeseries="metricComputedTimeseries"
                       :selectedPeriod="selectedPeriod">
            </component>
        </div>
    </div>
</template>

<script>

import DeviceObject from "../../objects/DeviceObject";
import MetricObject from "../../objects/MetricObject";
import DefaultChart from "./Default";
import EmptyChart from "./Empty";
import metricsConfig from "../../metricsConfig";

export default {
    name: "Abstract",
    components: {
        EmptyChart,
        DefaultChart
    },
    props: {
        metricObject: {
            type: MetricObject,
            required: true
        },
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        aggregatedTimeseries: {
            type: Array,
            required: true
        },
        typeMetricValues: {
            type: Array,
            required: false
        },
        timeseries: {
            type: Array,
            required: true
        },
        selectedPeriod: {
            type: Object,
            required: true
        },
    },
    computed: {
        currentComponent() {
            const deviceTypeSlug = this.deviceObject.getType().getSlug();

            if (Object.keys(metricsConfig).indexOf(deviceTypeSlug) > -1) {
                const metrics = metricsConfig[deviceTypeSlug]?.chartsComponent || [];
                if (metrics.indexOf(this.metricObject?.getSlug()) > -1) {
                    return metrics[metrics.length - 1];
                }
            }

            return DefaultChart;
        },
        isAggregatedMetric() {
            let deviceTypeSlug = this.deviceObject?.getType()?.getSlug()
            let metricSlug = this.metricObject?.getSlug();

            if (!metricsConfig.hasOwnProperty(deviceTypeSlug)) {
                return true;
            }

            return metricsConfig[deviceTypeSlug]?.notAggregatedMetrics?.indexOf(metricSlug) === -1;
        },
        metricAggregatedTimeseries() {
            const metrics = this.aggregatedTimeseries?.filter(item => item.getSlug() === this.metricObject?.getSlug()) || [];

            if (this.metricObject.getCountPorts() > 0) {
                return metrics[this.metricObject.getPortIndex()];
            }

            return metrics[0];
        },
        metricTimeseries() {
            const metrics = this.timeseries?.filter(item => item.getSlug() === this.metricObject?.getSlug()) || [];

            if (this.metricObject.getCountPorts() > 0) {
                return metrics[this.metricObject.getPortIndex()] || [];
            }

            return metrics[0];
        },
        metricComputedTimeseries() {
            return this.isAggregatedMetric
                ? this.metricAggregatedTimeseries
                : this.metricTimeseries;
        },
        metricPort() {
            return this.metricComputedTimeseries?.getPort() || null;
        },
        isEmptyMetric() {
            return !this.metricComputedTimeseries?.getData()?.length;
        }
    },
}
</script>

<style scoped>

</style>
