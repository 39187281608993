import AbstractService from "./AbstractService";
import types from "../store/types";

export default class ContactService extends AbstractService {

    constructor() {
        super();
    }

    async getAndStore() {
        const {code, body} = await this.request.get("/contacts");
        if (code === 200) {
            await this.store.commit(types.CONTACTS, body["hydra:member"]);
            return true;
        }
        return false;
    }

    async create(contact) {
        const {code, body} = await this.request.post("/contacts", contact);

        if (code === 201) {
            await this.store.commit(types.CONTACT_ADD, body);
            return true;
        }
        return false;
    }

    async update({id, auth}) {
        const {code, body} = await this.request.put("/contacts/" + id, {
            auth: auth,
        });
        if (code === 200) {
            await this.store.commit(types.CONTACT_UPDATE, body);
            return true;
        }
        return false;
    }
}
