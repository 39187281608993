<template>
    <div class="alarm-type-condition p-4">
        <div class="alarm-type-condition__remove" @click="$emit('remove', index)">&times;</div>
        <div class="form-group mb-0">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 mb-3">
                    <label class="font-weight-bold text-muted">
                        {{ $t('Метрика') }}
                        <strong class="text-danger">*</strong></label>
                    <select v-model="condition.metric_name" class="form-control">
                        <option v-for="item in metrics"
                                :key="item.getSlug()"
                                :value="item.getSlug()">
                            {{ item.getName() }}
                        </option>
                    </select>
                </div>
                <div class="col-12 col-md-6 mb-3"
                     :class="{ 'col-lg-5' : !isMultiPortsMetric, 'col-lg-3' : isMultiPortsMetric }">
                    <label class="font-weight-bold text-muted">
                        {{ $t('Условие') }}
                        <strong class="text-danger">*</strong></label>
                    <select v-model="condition.type" class="form-control">
                        <option v-for="(compare, index) in conditionsList"
                                :key="index"
                                :value="index">
                            {{ compare }}
                        </option>
                    </select>
                </div>
                <div v-if="overriddenConditionValues && overriddenConditionValues.length" class="col-12 col-md-6 col-lg-3 mb-3">
                    <label class="font-weight-bold text-muted">
                        {{ $t('Значение') }}
                        <strong class="text-danger">*</strong>
                    </label>
                    <select v-model="condition.value" class="form-control">
                        <option v-for="(item, index) in overriddenConditionValues"
                                :key="index"
                                :value="item.value">
                            {{ item.name || item.value }}
                        </option>
                    </select>
                </div>

                <div v-else class="col-12 col-md-6 col-lg-3 mb-3">
                    <label class="font-weight-bold text-muted">
                        {{ $t('Значение') }}
                        <strong class="text-danger">*</strong>
                    </label>
                    <div class="input-group">
                        <input v-model="condition.value" type="number" class="form-control">
                        <div v-if="selectedMetricUnits" class="input-group-append">
                            <span class="input-group-text">
                                {{ selectedMetricUnits }}
                            </span>
                        </div>
                    </div>
                </div>

                <div v-if="isMultiPortsMetric" class="col-12 col-md-6 col-lg-2 mb-3">
                    <label class="font-weight-bold text-muted">
                        {{ $t('Port') }}
                        <strong class="text-danger">*</strong></label>
                    <input v-model="condition.port" type="text" class="form-control">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import _ from "underscore";
import config from "../config";
import DeviceObject from "../objects/DeviceObject";
import metricsConfig from "../metricsConfig";

export default {
    name: "AlarmTypeCondition",
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        value: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        conditions: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            condition: {
                type_metric: null,
                metric_name: "",
                port: null,
                type: "eq",
                value: ""
            }
        }
    },
    computed: {
        overriddenConditionValues() {
            const deviceTypeSlug = this.deviceObject?.getType()?.getSlug();

            if (metricsConfig.hasOwnProperty(deviceTypeSlug)
                && metricsConfig[deviceTypeSlug]?.metricConditions?.hasOwnProperty(this.selectedMetric?.getSlug())
            ) {
                return metricsConfig[deviceTypeSlug]?.metricConditions[this.selectedMetric?.getSlug()]?.values;
            }

            return null;
        },
        overriddenConditionList() {
            const deviceTypeSlug = this.deviceObject?.getType()?.getSlug();
            let allowedConditionsItems = null;

            if (metricsConfig.hasOwnProperty(deviceTypeSlug)
                && metricsConfig[deviceTypeSlug]?.metricConditions?.hasOwnProperty(this.selectedMetric?.getSlug())
            ) {
                allowedConditionsItems = metricsConfig[deviceTypeSlug]?.metricConditions[this.selectedMetric?.getSlug()]?.items;
            }

            if (allowedConditionsItems?.length) {
                const out = {};

                for (let i in config.conditionsList) {
                    if (allowedConditionsItems.indexOf(i) > -1) {
                        out[i] = config.conditionsList[i];
                    }
                }

                return out;
            }

            return null;
        },
        conditionsList() {
            return this.overriddenConditionList ? this.overriddenConditionList : config.conditionsList;
        },
        metrics() {
            return this.deviceObject.getType().getMetricsExclude(['bat_volt']);
        },
        selectedMetric() {
            return _.find(this.metrics, item => {
                return item.getSlug() === this.condition.metric_name;
            });
        },
        selectedMetricUnits() {
            return this.selectedMetric?.getUnits();
        },
        selectedMetricId() {
            return this.selectedMetric?.getId();
        },
        isMultiPortsMetric() {
            return this.selectedMetric?.getCountPorts() > 0;
        }
    },
    watch: {
        condition: {
            deep: true,
            immediate: true,
            handler(val) {
                val.type_metric = this.selectedMetricId;

                const payload = {
                    index: this.index,
                    item: val
                }

                this.$emit('change', payload)
            }
        },

        value(val) {
            this.condition = val;
        }
    },
    mounted() {
        this.condition = this.value;
    },
}
</script>

<style lang="less" scoped>

.alarm-type-condition {
    background: #F8F9FA;
    border: 1px solid darken(#F8F9FA, 10);
    border-radius: .5rem;
    position: relative;
    margin-bottom: 1rem;
    overflow: hidden;

    &__remove {
        position: absolute;
        top: .5rem;
        right: 1rem;
        font-size: 1.2rem;
        font-weight: bold;
        cursor: pointer;
    }

    &__level {
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 100%;
    }
}
</style>
