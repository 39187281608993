<script>

import moment from "moment-mini";
import DefaultTable from "./DefaultTable";
import config from "../../config";

export default {
    name: "DoubleRelayTable",
    extends: DefaultTable,
    data() {
        return {
            hiddenColumns: ["units"]
        }
    },
    computed: {
        rows() {
            return this.metricTimeseries?.getData()?.map(item => {

                return {
                    date: moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY),
                    time: moment(item.getDate()).format(config.dateTimeFormats.HHmmss),
                    value: +item.getValue() === 1 ? this.$t("Включено") : this.$t("Выключено"),
                }
            }) || []
        }
    }
}
</script>
