<template>
    <div>
        <HeaderComponent :deviceObject="deviceObject"
                         :timeseries="timeseries"
                         @deviceDoUpdate="$emit('deviceDoUpdate', deviceObject)"
                         @deviceDoShowGeoPosition="$emit('deviceDoShowGeoPosition', deviceObject)"
                         @deviceDoRemove="$emit('deviceDoRemove', deviceObject)"/>
        <div class="row">
            <div class="col-12 position-relative">
                <LatestValueComponent :deviceObject="deviceObject"
                                      :timeseries="timeseries"
                                      :metricImage="metricImage"
                                      :currentMetric="currentMetric"/>
                <div v-if="currentAccountId && !isCurrentUserDevice"
                     data-toggle="tooltip"
                     :title="sharedTooltipText"
                     class="shared-indicator">
                    <i class="fas fa-question text-light"></i>
                </div>
            </div>
            <div class="col-12 text-center">
                <div v-if="deviceNameOnCard" class="font-weight-bold">
                    {{ stringLimit(deviceObject.getName(), 30) }}
                </div>
                <div class="font-weight-bold overflow-hidden py-2"
                     data-toggle="tooltip"
                     :title="currentMetricName"
                     :key="Math.random()"
                >
                    {{ stringLimit(currentMetricName, 20) }}
                </div>
            </div>
        </div>
        <div class="row align-items-center metricsButtons">
            <div class="col-2">
                <button v-if="countDeviceMetrics > paginatedData.perPage"
                        class="btn pagination-controls-left mb-2"
                        :disabled="paginatedData.currentPage === 0"
                        @click="paginatedData.currentPage--">
                </button>
            </div>
            <div class="col-8 text-center">
                <MetricsButtonsComponent
                    :deviceObject="deviceObject"
                    :currentMetric="currentMetric"
                    :paginatedData="getPaginatedData"
                    :timeseries="timeseries"
                    @change="setCurrentMetric"/>
            </div>
            <div class="col-2">
                <button v-if="countDeviceMetrics > paginatedData.perPage"
                        class="btn pagination-controls-right mb-2"
                        :disabled="paginatedData.currentPage >= pageCount -1"
                        @click="paginatedData.currentPage++">
                </button>
            </div>
        </div>
        <div v-if="!deviceCardCompactMode" class="row" style="margin-bottom: 20px">
            <div class="col-12">
                <ChartComponent
                    :deviceObject="deviceObject"
                    :timeseries="timeseries"
                    :metricObject="currentMetric"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button @click="$router.push({name: 'Device', params: { id: deviceObject.getId() }})"
                        class="btn btn-purple btn-custom btn-block">
                    {{ $t('Статистика') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import Icon from "../../Icon";
import {mapState} from "vuex";
import Helper from "../../../classess/Helper";
import DeviceObject from "../../../objects/DeviceObject";
import DefaultCard_1MetricsButtons from "./DefaultCard_1MetricsButtons";
import DefaultCard_1LatestValue from "./DefaultCard_1LatestValue";
import DefaultCard_1AbstractChart from "./DefaultCard_1AbstractChart";
import DefaultCard_1Header from "./DefaultCard_1Header";

export default {
    name: "DefaultCard_1",
    components: {
        HeaderComponent: DefaultCard_1Header,
        MetricsButtonsComponent: DefaultCard_1MetricsButtons,
        LatestValueComponent: DefaultCard_1LatestValue,
        ChartComponent: DefaultCard_1AbstractChart,
        Icon
    },
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        timeseries: {
            type: Array,
            required: true
        },
        isCurrentUserDevice: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            currentMetric: null,
            stringLimit: Helper.stringLimit,
            paginatedData: {
                currentPage: 0,
                perPage: 4,
            },
        }
    },
    methods: {
        setCurrentMetric(metric) {
            this.currentMetric = metric;
        }
    },
    computed: {
        ...mapState({
            currentObject: state => state.currentObject,
            currentAccountId: state => state.currentObject?.account_id,
            deviceCardCompactMode: state => state.DevicesSettingsDropdown.deviceCardCompactMode,
            deviceNameOnCard: state => state.DevicesSettingsDropdown.deviceNameOnCard
        }),
        currentMetricName() {
            return this.currentMetric?.getName();
        },
        sharedTooltipText() {
            return this.$t('Доступ к этому устройству, любезно предоставлен аккаунтом: ') + this.deviceObject.getAccountId();
        },
        firstMetric() {
            return this.deviceObject?.getType()?.excludeInternalMetrics()?.getMetricsWithPortsExclude(["bat_volt"])?.sort((a, b) => {
                return b.getSlug() === "bat_percent" ? -1 : 0;
            })[0];
        },
        countDeviceMetrics() {
            return this.deviceObject?.getType()?.excludeInternalMetrics()?.getMetricsWithPortsExclude(["bat_volt"])?.length;
        },
        pageCount() {
            return Math.ceil(this.countDeviceMetrics / this.paginatedData.perPage);
        },
        getPaginatedData() {
            const start = this.paginatedData.currentPage * this.paginatedData.perPage,
                end = start + this.paginatedData.perPage;
            return this.deviceObject?.getType()?.excludeInternalMetrics()?.getMetricsWithPortsExclude(["bat_volt"])?.sort((a, b) => {
                return b.getSlug() === "bat_percent" ? -1 : 0;
            }).slice(start, end);
        },
        metricImage() {
            let iconFile;

            try {
                iconFile = require(`../../../assets/metrics/${this.currentMetric?.getIcon()}.png`);
            } catch (e) {
                iconFile = require(`../../../assets/metrics/default.png`);
            }

            return {
                "background-image": `url(${iconFile})`
            }
        }
    },
    watch: {
        firstMetric(val) {
            this.currentMetric = val;
            this.$emit('onLoad');
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.firstMetric) {
                this.currentMetric = this.firstMetric;
                this.$emit('onLoad');
            }
        });
    }
}
</script>

<style lang="less">

@import "../../../less/_variables";

.btn:focus {
    box-shadow: none;
}

.shared-indicator {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    text-align: center;
    line-height: 40px;
    vertical-align: middle;
    background: @purple;
    cursor: help;
}

.btn-custom {
    height: 38px;
    font-weight: 400;
    font-size: 16px;
    width: 49%;
    margin: 0 auto;
}

.pagination-controls {

    &-left {
        width: 25px;
        height: 25px;
        padding: 0;
        background: url("../../../assets/ArrowLeft.png") no-repeat;
    }

    &-right {
        width: 25px;
        height: 25px;
        padding: 0;
        background: url("../../../assets/ArrowRight.png") no-repeat;
    }
}

</style>
