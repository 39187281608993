import { render, staticRenderFns } from "./AccidentsLogModal.vue?vue&type=template&id=0cd2e46a&scoped=true&"
import script from "./AccidentsLogModal.vue?vue&type=script&lang=js&"
export * from "./AccidentsLogModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd2e46a",
  null
  
)

export default component.exports