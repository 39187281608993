<script>
import Default from "./Default";
import ElectricityMeterTable from "./ElectricityMeterTable";

export default {
    name: "ElectricityMeter",
    extends: Default,
    components: {
        DefaultLineChart: ElectricityMeterTable,
        DefaultTable: ElectricityMeterTable
    },
}
</script>
