<template>
    <div class="d-flex justify-content-around metric py-2">
        <div v-for="(metric, index) in paginatedData"
             :key="index"
             class="metric-buttons d-flex align-items-center justify-content-center flex-column"
             :class="{ active : isSelected(metric) }"
             @click="$emit('change', metric)">
            <Icon :icon-name="getMetricIcon(metric)"
                  width="24"
                  height="24"
                  :fill="metric.getColor()"
                  viewBox="0 0 26 26"/>

            <div class="position-relative">
                <span v-if="metric.getPortIndex() !== null" class="metric-icon-index">
                    {{ metric.getPortIndex() + 1 }}
                </span>

                <span class="metric-data py-1"
                      :style="isSelected(metric) ? `color: ${metric.getColor()}` : 'color: #706C6C'">
                    {{ getMetricLabel(metric) }}
                </span>
            </div>

            <Icon class="d-block mx-auto"
                  v-if="isSelected(metric)"
                  icon-name="dot"
                  :fill=metric.getColor()
                  width="7"
                  height="7"/>

            <div v-else style="height: 7px"></div>
        </div>
    </div>
</template>

<script>

import Icon from "../../Icon";
import DeviceObject from "../../../objects/DeviceObject";

export default {
    name: "DefaultCard_2MetricsButtons",
    components: {
        Icon
    },
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        currentMetric: {
            required: true
        },
        paginatedData: {
            type: Array,
            required: false
        },
        timeseries: {
            type: Array,
            required: false
        },
    },
    methods: {
        getMetricIcon(metric) {
            return metric.getIcon();
        },
        getMetricLabel(metric, fixed = 2) {
            const values = this.timeseries?.filter(item => item.getSlug() === metric?.getSlug());

            const value = metric?.getPortIndex()
                ? values[metric?.getPortIndex()]
                : values[0];

            const result = value?.getLatestData()?.getValue();

            return (result || result === 0) ? result?.toFixed(fixed) + " " + metric.getUnits() : "N/A";
        },
        isSelected(metric) {
            return this.currentMetric?.getSlug() === metric.getSlug()
                && this.currentMetric?.getPortIndex() === metric.getPortIndex()
        }
    },
}
</script>

<style lang="less" scoped>
.metric {
    margin: 0 -20px;

    &-buttons {
        border-right: 1px solid #9E79BB30;
        width: 100%;
        height: 58px;
        cursor: pointer;

        &:last-child {
            border-right: none;
        }
    }

    &-data {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.0014em;
    }
}

.metric-icon-index {
    user-select: none;
    position: absolute;
    top: -25px;
    right: -10px;
    padding: 1px 7px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
}


</style>
