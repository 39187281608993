<template>
    <Layout>
        <div class="row">
            <div class="col-12 col-md-6">
                <h4 class="title mb-4">
                    {{ $t("Устройства") }}
                    <button @click="deviceAddModal = true" class="btn btn-purple ml-3">
                        <i class="fas fa-plus fa-fw"></i>
                        <span class="d-none d-xl-inline pl-2">{{ $t("Добавить устройство") }}</span>
                    </button>
                </h4>
            </div>
            <div v-show="devices.length > 0" class="col-12 col-md-6 mb-4 mb-md-0">
                <DevicesSettingsDropdown/>
                <div class="btn-group float-md-right mr-4">
                    <button @click="currentTab = 'devices'"
                            class="btn btn-outline-purple btn-hover-svg-white" :class="{ active: currentTab === 'devices' }">
                        <Icon icon-name="devices"
                              :fill=" currentTab === 'devices' ? '#ffffff' : '#732AAD'"
                              width="22"
                              height="22"/>
                        <span class="d-none d-lg-inline pl-2">{{ $t("Устройства") }}</span>
                    </button>
                    <button @click="currentTab = 'map'"
                            class="btn btn-outline-purple" :class="{ active: currentTab === 'map' }">
                        <i class="fas fa-map-marked-alt fa-fw"></i>
                        <span class="d-none d-lg-inline pl-2">{{ $t("Карта") }}</span>
                    </button>
                </div>
            </div>
            <div class="col-12">
                <DemoCaseTabs @change="changeCase"/>
            </div>
            <div v-show="devices.length > 0" class="col-12">
                <div v-show="currentTab === 'devices'">
                    <div class="d-flex flex-wrap justify-content-center justify-content-md-between">
                        <AbstractCard v-for="device in devices"
                                      :key="device.getId()"
                                      :deviceObject="device"
                                      @onLoad="onLoad"
                                      @deviceDoUpdate="deviceDoUpdate"
                                      @deviceDoShowGeoPosition="deviceDoShowGeoPosition"
                                      @deviceDoRemove="deviceDoRemove"
                        />
                        <div class="device-card-fix mx-2"></div>
                        <div class="device-card-fix mx-2"></div>
                        <div class="device-card-fix mx-2"></div>
                        <div class="device-card-fix mx-2"></div>
                    </div>
                </div>
                <DevicesMap v-if="currentTab === 'map'" :devices="devices"/>
            </div>
            <CenterBlock v-show="devices.length === 0">
                <div class="text-center">
                    <Icon icon-name="devices"
                          class="mb-4"
                          fill="#6C757D"
                          width="70"
                          height="70"/>
                    <h4 class="text-muted text-center mb-4">{{ $t('Вы еще не добавляли устройства') }}</h4>
                    <button @click="deviceAddModal = true" class="btn btn-outline-purple">
                        <i class="fas fa-plus fa-fw"></i>
                        <span class="d-none d-md-inline pl-2">{{ $t("Добавить устройство") }}</span>
                    </button>
                </div>
            </CenterBlock>
        </div>

        <DeviceAddModal :opened="deviceAddModal"
                        @closed="() => this.deviceAddModal = false"/>

        <DeviceCoefficientModal/>

        <DeviceUpdateModal :opened="deviceUpdateModal"
                           :deviceObject="currentDeviceObject"
                           @closed="() => this.deviceUpdateModal = false"/>

        <DeviceGeoPositionModal :opened="deviceGeoPositionModal"
                                :deviceObject="currentDeviceObject"
                                @closed="() => this.deviceGeoPositionModal = false"/>

        <RemoveModal :opened="deviceRemoveModal"
                     :processing="removeProcessing"
                     :title="$t('Удалить устройство?')"
                     :text="$t('Вы уверены что хотите удалить устройство? Это действие нельзя будет отменить!')"
                     :buttonText="$t('Удалить')"
                     @removed="removeDevice"
                     @closed="() => this.deviceRemoveModal = false"/>
    </Layout>
</template>

<script>

import types from "../store/types";
import DevicesMap from "../components/DevicesMap"
import DeviceAddModal from "../components/modals/DeviceAddModal";
import DeviceUpdateModal from "../components/modals/DeviceUpdateModal";
import DeviceGeoPositionModal from "../components/modals/DeviceGeoPositionModal";
import Icon from "../components/Icon";
import RemoveModal from "../components/modals/RemoveModal";
import DeviceService from "../services/DeviceService";
import Layout from "../components/Layout";
import DevicesSettingsDropdown from "../components/DevicesSettingsDropdown";
import AbstractCard from "../components/deviceCards/AbstractCard";
import DeviceObject from "../objects/DeviceObject";
import DemoCaseTabs from "../components/DemoCaseTabs";
import CenterBlock from "../components/CenterBlock";
import DeviceCoefficientModal from "../components/modals/DeviceCoefficientModal";

const Device = new DeviceService();

export default {
    name: 'Devices',
    components: {
        DeviceCoefficientModal,
        Layout,
        Icon,
        DevicesMap,
        DeviceAddModal,
        DeviceUpdateModal,
        DeviceGeoPositionModal,
        RemoveModal,
        DevicesSettingsDropdown,
        AbstractCard,
        DemoCaseTabs,
        CenterBlock
    },
    data() {
        return {
            activeCase: null,
            currentTab: 'devices',
            deviceAddModal: false,
            deviceUpdateModal: false,
            deviceGeoPositionModal: false,
            deviceRemoveModal: false,
            removeProcessing: false,
            currentDeviceObject: new DeviceObject()
        }
    },
    methods: {
        async removeDevice() {
            this.removeProcessing = true;
            const result = await Device.remove(this.currentDeviceObject.getId());
            this.removeProcessing = false;
            this.deviceRemoveModal = false;
            this.currentDeviceObject = {};

            if (result) {
                this.$toast.success(this.$t('Устройство удалено'));
            } else {
                this.$toast.error(this.$t('Ошибка!'));
            }
        },
        deviceDoUpdate(deviceObject) {
            this.currentDeviceObject = deviceObject;
            this.deviceUpdateModal = true;
        },
        deviceDoShowGeoPosition(deviceObject) {
            this.currentDeviceObject = deviceObject;
            this.deviceGeoPositionModal = true;
        },
        deviceDoRemove(deviceObject) {
            this.currentDeviceObject = deviceObject;
            this.deviceRemoveModal = true;
        },
        isDemoCaseDevice(device) {
            if (!this.activeCase) {
                return true;
            }

            return this.activeCase.types.indexOf(device.getDevEui()) > -1
                || this.activeCase.types.indexOf(device.getType().getSlug()) > -1
        },
        onLoad() {
            setTimeout(() => {
                this.$store.commit(types.PRELOADER, false);
            }, 500);
        },
        changeCase(data) {
            this.activeCase = data;
        }
    },
    computed: {
        devices() {
            return this.$store.state.devices
                ?.map(item => new DeviceObject(item))
                ?.filter(item => this.isDemoCaseDevice(item));
        },
    },
}
</script>
<style lang="less">

.device-card-fix {
    width: 370px;
    position: relative;

    @media (max-width: 576px) {
        width: 100%;
        min-width: 100%;
    }
}


</style>
