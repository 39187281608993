export default {
    SIDEBAR_COLLAPSED: 'SIDEBAR_COLLAPSED',
    PRELOADER: 'PRELOADER',
    SIMPLE_PRELOADER: 'SIMPLE_PRELOADER',
    SET_TOKEN: 'SET_TOKEN',
    SET_REFRESH: 'SET_REFRESH',
    DEVICES: 'DEVICES',
    DEVICE_UPDATE: 'DEVICE_UPDATE',
    DEVICE_ADD: 'DEVICE_ADD',
    DEVICE_REMOVE: 'DEVICE_REMOVE',
    TYPES: 'TYPES',
    OBJECTS: 'OBJECTS',
    CURRENT_OBJECT: 'CURRENT_OBJECT',
    CONTACTS: 'CONTACTS',
    CONTACT_ADD: 'CONTACT_ADD',
    CONTACT_UPDATE: 'CONTACT_UPDATE',
    CURRENT_USER: 'CURRENT_USER',
    SET_APP_LOCALE: 'SET_APP_LOCALE',
    SET_APP_THEME: 'SET_APP_THEME',
    SET_APP_THEME_COLOR: 'SET_APP_THEME_COLOR',
    DEVICE_CARD_COMPACT_MODE: 'DEVICE_CARD_COMPACT_MODE',
    DEVICE_NAME_ON_CARD: 'DEVICE_NAME_ON_CARD',
    DEVICE_CARD_ACCIDENTS_HIGHLIGHT: 'DEVICE_CARD_ACCIDENTS_HIGHLIGHT',
    SET_CARD_DESIGN: 'SET_CARD_DESIGN',
    SET_AUTO_UPDATE_DEVICES: 'SET_AUTO_UPDATE_DEVICES',
    PUSH_CARD_TIMESERIES_CACHE: 'PUSH_CARD_TIMESERIES_CACHE',
}
