<template>
<!--    <div v-if="device" class="app-card device-info-card d-flex justify-content-between align-items-center">-->
<!--        <div @click="toDevice" class="mr-3">-->
<!--            <Icon iconName="devices"-->
<!--                  class="device-info-icon"-->
<!--                  width="50"-->
<!--                  height="50"-->
<!--                  fill="#ffffff">-->
<!--            </Icon>-->
<!--        </div>-->
<!--        <div class="flex-fill">-->
<!--            <a href="#"-->
<!--               class="font-weight-bold d-block"-->
<!--               @click.prevent="toDevice">-->
<!--                {{ currentDeviceName | stringLimit }}-->
<!--            </a>-->
<!--            <small class="text-muted">-->
<!--                {{ currentDeviceDescription | stringLimit }}-->
<!--            </small>-->
<!--        </div>-->
<!--        <div class="ml-3">-->
<!--            <button @click="toDevice" class="btn btn-light"-->
<!--                    data-toggle="tooltip"-->
<!--                    :title="$t('Перейти к устройству')">-->
<!--                <i class="fas fa-arrow-right"></i>-->
<!--            </button>-->
<!--        </div>-->
<!--    </div>-->
</template>

<script>
import Icon from "./Icon"
import Helper from "../classess/Helper";

export default {
    name: "DeviceInfo",
    props: ["device"],
    components: {
        Icon
    },
    filters: {
        stringLimit: string => Helper.stringLimit(string, 40)
    },
    computed: {
        currentDevice() {
            return this.device || {};
        },
        currentDeviceName() {
            return this.device?.name || "";
        },
        currentDeviceDescription() {
            return this.device?.description || "";
        }
    },
    methods: {
        toDevice() {
            this.$router.push({name: 'Devices', query: {highlight_device_id: this.currentDevice?.id}});
        }
    }
}
</script>

<style lang="less" scoped>

.device-info-card {
    max-width: 470px;
    width: 100%;
}

.device-info-icon {
    padding: 10px;
    background: #870082;
    border-radius: .6rem;
    cursor: pointer;
}
</style>