<script>
import DefaultTable from "./DefaultTable";

export default {
    name: "LeakStatusTable",
    extends: DefaultTable,
    data() {
        return {
            hiddenColumns: ["units"]
        }
    },
    methods: {
        prepColumn(field, row) {
            if (field === "value") {

                if (row[field] === 0) {
                    return this.$t("Протечка не обнаружена");
                }

                if (row[field] === 1) {
                    return this.$t("Обнаружена протечка!")
                }

                return "N/A"
            }

            return row[field];
        },
    },
}
</script>
