<template>
    <div ref="latestValue" class="latest-value">
        <div v-if="value !== null">
            <div class="latest-value__value">{{ value }}</div>
            <div class="latest-value__units">{{ units }}</div>
        </div>
        <div v-else>
            <div class="latest-value__value">N/A</div>
            <div class="latest-value__units">{{ units }}</div>
        </div>
    </div>
</template>

<script>

import DefaultCard_1LatestValue from "./DefaultCard_1LatestValue";

export default {
    name: "DefaultCard_2LatestValue",
    extends: DefaultCard_1LatestValue,
}
</script>

<style lang="less" scoped>

.latest-value {
    width: 100%;
    padding: 10px 0 0;

    &__bg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #ccc;
        color: #fff;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__value {
        text-align: center;
        font-weight: 700;
        font-size: 2rem;
        line-height: 1;
        color: #fff;
    }

    &__units {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
        color: #fff;
    }

    .heating {
        font-size: 16px;
    }
}

</style>
