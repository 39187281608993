import _ from "underscore"
import translations from './translations'

const uk = _.object(_.map(translations, (value, key) => {
    return [key, value]
}));

const ru = _.object(_.map(translations, (value, key) => {
    return [key, key]
}));

export default {ru, uk}