<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <h4 class="title mb_40">
                    {{ $t('Настройки') }}
                </h4>
            </div>
        </div>
        <!--            <div class="row">-->
        <!--                <div class="col-12 col-lg-4 pb-4">-->
        <!--                    <label>{{ $t('Язык интерфейса') }}</label>-->
        <!--                    <select @change="setLocale" class="form-control">-->
        <!--                        <option v-for="(locale, index) in appAvailableLocales"-->
        <!--                                :key="index"-->
        <!--                                :selected="index === appLocale"-->
        <!--                                :value="index">-->
        <!--                            {{ locale }}-->
        <!--                        </option>-->
        <!--                    </select>-->
        <!--                </div>-->
        <!--            </div>-->
<!--        <div class="row">-->
<!--            <div class="col-12 col-lg-4 pb-4">-->
<!--                <label>{{ $t('Тема интерфейса') }}</label>-->
<!--                <select @change="setTheme" class="form-control">-->
<!--                    <option v-for="(theme, index) in appAvailableThemes"-->
<!--                            :key="index"-->
<!--                            :selected="index === appTheme"-->
<!--                            :value="index">-->
<!--                        {{ theme }}-->
<!--                    </option>-->
<!--                </select>-->
<!--            </div>-->
<!--        </div>-->
        <div class="row">
            <div class="col-12 pb-4" >
                <label>{{ $t('Дизайн карточек интерфейса') }}</label>
                <div class="d-flex align-items-start pt-3">
                    <div class="pr-3 d-flex flex-column align-items-center"
                         v-for="(design, index) in availableDesigns"
                         :key="index"
                         @change="setDesign"
                         >
                        <input class="btn-check mb-3 d-none"
                               autocomplete="off"
                               type="radio"
                               name="design"
                               :id="'radio' + index" 
                               :value="index + 1"
                               :checked="index + 1 === selectedDesign"
                        >
                        <label class="btn"
                               :class="index + 1 === selectedDesign ? 'btn-purple' : 'btn-outline-purple'"
                               :for="'radio' + index">
                            {{design.text}}
                        </label>
                        <img class="" width="370px" :src="require('@/assets/cardDesign'+`${index + 1}`+'.jpg')"  alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-4 pb-4">
                <button @click="saveAllSettings" class="btn btn-purple" :disabled="processing">
                    {{ $t('Сохранить') }}
                </button>
            </div>
        </div>
    </Layout>
</template>

<script>

import types from "../store/types";
import {mapState} from "vuex";
import Layout from "../components/Layout";

export default {
    name: 'Settings',
    data: () => ({
        selectedLocale: null,
        selectedTheme: null,
        selectedDesign: null,
        processing: false
    }),
    components: {
        Layout
    },
    computed: {
        ...mapState({
            appAvailableLocales: state => state.appAvailableLocales,
            appLocale: state => state.appLocale,
            appAvailableThemes: state => state.appAvailableThemes,
            appTheme: state => state.appTheme,
            availableDesigns: state => state.DevicesSettingsDropdown.availableDesigns,
            currentCardDesign: state => state.DevicesSettingsDropdown.currentCardDesign
        })
    },
    methods: {
        setLocale(e) {
            this.selectedLocale = e.target.value;
        },
        setTheme(e) {
            this.selectedTheme = e.target.value;
        },
        setDesign(e) {
            this.selectedDesign = +e.target.value;
        },
        saveAllSettings() {
            this.processing = true;
            if (this.selectedLocale) {
                this.$store.commit(types.SET_APP_LOCALE, this.selectedLocale);
                // возможно тут еще нужна перезагрузка, т.к. api отдает локализированные данные
            }
            if (this.selectedTheme) {
                this.$store.commit(types.SET_APP_THEME, this.selectedTheme);
            }
            this.$store.commit(types.SET_CARD_DESIGN, this.selectedDesign);
            this.processing = false;
        },
    },
    created() {
        this.selectedDesign = this.currentCardDesign
    },
    mounted() {
        this.$store.commit(types.PRELOADER, false);
    },

}
</script>

