import GerkonStatus from "../src/components/deviceCharts/GerkonStatus";
import AirQualityHeating from "../src/components/deviceCharts/AirQualityHeating";
import ValveStatus from "./components/deviceCharts/ValveStatus";
import ElectricityMeter from "./components/deviceCharts/ElectricityMeter";
import DoubleRelay from "./components/deviceCharts/DoubleRelay";
import i18n from "./i18n";
import LeakStatus from "./components/deviceCharts/LeakStatus";
import WiFiRelay from "@/components/deviceCharts/WiFiRelay";
import SmokeStatus from "@/components/deviceCharts/SmokeStatus";

/**
 *  "device_type_slug": {
 *       chartMetricsBlacklist:    ["metric_slug_1", ....], Скрытые метрики для страницы /device/xxxxxxxxxxxx....
 *       notAggregatedMetrics:     ["metric_slug_1", ....],
 *       tabledMetrics:            ["metric_slug_1", ....],
 *       cardComponent:            () => import("..."),
 *       chartsComponent:          ["metric_slug_1", ...., GerkonStatus], Кастомные компоненты ГРАФИКОВ для страницы /device/xxxxxxxxxxxx....
 *       metricConditions: {
 *           "metric_slug": {
 *               items: ["eq", ...],
 *               values: [{
 *                   name: <string>,
 *                   value: <string>
 *               }]
 *           }
 *       },
 *       limit: 10   Кастомный лимит загрузки Timeseries
 *   }
 */
export default {
    "lds_xxxx": {
        chartMetricsBlacklist: [],
        notAggregatedMetrics: ["status", "times", "last_duration_min"],
        tabledMetrics: ["status", "times", "last_duration_min"],
        cardComponent: () => import("../src/components/deviceCards/gerkon/AbstractGerkonCard"),
        chartsComponent: ["status", GerkonStatus],
        metricConditions: {
            "status": {
                items: ["eq", "neq"],
                values: [{
                    name: i18n.t("Закрыто"),
                    value: "0"
                }, {
                    name: i18n.t("Открыто"),
                    value: "1"
                }]
            }
        }
    },
    "lwl_xxxx": {
        chartMetricsBlacklist: [],
        notAggregatedMetrics: ["status", "times", "last_duration_min"],
        tabledMetrics: ["status", "times", "last_duration_min"],
        cardComponent: () => import("../src/components/deviceCards/leak/AbstractLeakCard"),
        chartsComponent: ["status", LeakStatus],
        metricConditions: {
            "status": {
                items: ["eq", "neq"],
                values: [{
                    name: i18n.t("Нет протечки"),
                    value: "0"
                }, {
                    name: i18n.t("Есть протечка"),
                    value: "1"
                }]
            }
        }
    },
    "air_quality_xxxx_heating": {
        chartMetricsBlacklist: [],
        notAggregatedMetrics: ["heating"],
        tabledMetrics: ["heating"],
        cardComponent: () => import("../src/components/deviceCards/heating/AbstractHeatingCard"),
        chartsComponent: ["heating", AirQualityHeating],
    },
    "dragino_lt_relay_xxxx": {
        chartMetricsBlacklist: ["ro1", "ro2"],
        notAggregatedMetrics: ["res"],
        tabledMetrics: ["res"],
        cardComponent: () => import("../src/components/deviceCards/valve/AbstractValveCard"),
        chartsComponent: ["res", ValveStatus]
    },
    "mtx_electricity_meter_x_xxxx": {
        chartMetricsBlacklist: ["load_state"],
        notAggregatedMetrics: ["consumption", "load_state"],
        tabledMetrics: ["consumption", "load_state"],
        cardComponent: () => import("../src/components/deviceCards/electricityMeter/AbstractElectricityMeterCard"),
        chartsComponent: ["consumption", ElectricityMeter],
        noLimit: true
    },
    "relay_double_xxxx": {
        notAggregatedMetrics: ["relay1", "relay2"],
        tabledMetrics: ["relay1", "relay2"],
        cardComponent: () => import("../src/components/deviceCards/doubleRelay/AbstractDoubleRelayCard"),
        chartsComponent: ["relay1", "relay2", DoubleRelay],
        noLimit: true
    },
    "_app_relay_solo": {
        notAggregatedMetrics: ["relay1"],
        tabledMetrics: ["relay1"],
        cardComponent: () => import("../src/components/deviceCards/doubleRelay/AbstractDoubleRelayCard"),
        chartsComponent: ["relay1", DoubleRelay],
        noLimit: true
    },
    "iks_telecom": {
        notAggregatedMetrics: ["relay1", "motion"],
        tabledMetrics: ["relay1", "motion"],
        cardComponent: () => import("../src/components/deviceCards/wifiRelay/AbstractWiFiRelayCard"),
        chartsComponent: ["relay1", "motion", WiFiRelay],
    },

    "smoke_sm_xxxx": {
        notAggregatedMetrics: ["smoke_status"],
        tabledMetrics: ["smoke_status"],
        cardComponent: () => import("../src/components/deviceCards/smoke/AbstractSmokeCard"),
        chartsComponent: ["smoke_status", SmokeStatus],
    },

    "smoke_df_xxxx": {
        notAggregatedMetrics: ["smoke_status"],
        tabledMetrics: ["smoke_status"],
        cardComponent: () => import("../src/components/deviceCards/smoke-df/AbstractSmokeDfCard"),
        chartsComponent: ["smoke_status", SmokeStatus],
    },
}
