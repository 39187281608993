<script>
import Default from "./Default";
import LeakStatusTable from "./LeakStatusTable";

export default {
    name: "LeakStatus",
    extends: Default,
    components: {
        DefaultLineChart: LeakStatusTable,
        DefaultTable: LeakStatusTable
    },
}
</script>
