<template>
    <vue-good-table
        :columns="computedColumns"
        :rows="rows"
        :pagination-options="vgtPaginationOptions()">
        <template slot="table-row" slot-scope="props">
            {{ prepColumn(props.column.field, props.row) }}
        </template>
    </vue-good-table>
</template>

<script>

import MetricObject from "../../objects/MetricObject";
import config from "../../config";
import moment from "moment-mini";
import Icon from "../Icon";

export default {
    name: "DefaultTable",
    components: {
        Icon
    },
    props: {
        metricObject: {
            type: MetricObject,
            required: true
        },
        metricTimeseries: {
            required: true
        },
        timeseries: {
            required: true
        },
        selectedPeriod: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            hiddenColumns: [],
            columns: [
                {
                    label: this.$t('Дата'),
                    field: 'date',
                    sortable: false,
                },
                {
                    label: this.$t('Время'),
                    field: 'time',
                    sortable: false,
                },
                {
                    label: this.$t('Значение'),
                    field: 'value',
                    sortable: false,
                },
                {
                    label: this.$t('Единицы измерения'),
                    field: 'units',
                    sortable: false,
                }
            ]
        }
    },
    methods: {
        vgtPaginationOptions() {
            return {
                ...config.vgtPaginationOptions(),
                perPage: 6
            }
        },
        prepColumn(field, row) {
            return row[field];
        },
    },
    computed: {
        computedColumns() {
            return this.columns.filter(item => this.hiddenColumns.indexOf(item.field) === -1);
        },
        rows() {
            return this.metricTimeseries?.getData()?.map(item => {
                return {
                    date: moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY),
                    time: moment(item.getDate()).format(config.dateTimeFormats.HHmmss),
                    value: (item.getAvg() || item.getAvg() === 0) ? item.getAvg() : item.getValue(),
                    units: this.metricObject.getUnits("-")
                }
            }) || []
        }
    }
}
</script>

<style scoped>

</style>
