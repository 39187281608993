<script>
import Default from "./Default";
import SmokeStatusTable from "./SmokeStatusTable";
import DefaultLineChart from "@/components/deviceCharts/DefaultLineChart";

export default {
    name: "SmokeStatus",
    extends: Default,
    components: {
        DefaultLineChart: DefaultLineChart,
        DefaultTable: SmokeStatusTable
    },
}
</script>
