<template>
    <Layout>
        <div class="row">
            <div class="col-12 mb-4">
                <h4 class="title">
                    {{ $t('Настройка аварий') }}
                    <button @click="alarmTypeAddModal = true" class="btn btn-purple ml-3">
                        <i class="fas fa-plus fa-fw"></i>
                        <span class="d-none d-md-inline pl-2">{{ $t("Добавить аварию") }}</span>
                    </button>
                </h4>
            </div>
            <div class="col-12 mb-4">
                <DeviceInfo :device="currentDevice"></DeviceInfo>
            </div>
            <div class="col-12 d-flex justify-content-start flex-wrap">
                <AlarmTypeCard v-for="(item, index) in currentDeviceAlarmTypes"
                               :key="index"
                               :alarmType="item"
                               :deviceObject="deviceObject"
                               @doUpdate="doUpdate"
                               @doRemove="doRemove">
                </AlarmTypeCard>
            </div>
        </div>
        <AlarmTypeAddModal :opened="alarmTypeAddModal"
                           :deviceObject="deviceObject"
                           @created="createAlarmType"
                           @closed="() => alarmTypeAddModal = false"/>

        <AlarmTypeUpdateModal :opened="alarmTypeUpdateModal"
                              :alarmType="currentAlarmType"
                              :deviceObject="deviceObject"
                              @updated="updateAlarmType"
                              @closed="closedAlarmTypeUpdateModal"/>

        <RemoveModal :opened="alarmTypeRemoveModal"
                     :processing="removeProcessing"
                     :title="$t('Удалить аварию?')"
                     :text="$t('Вы уверены что хотите удалить аварию и все журналы инцидентов? Это действие нельзя будет отменить')"
                     :buttonText="$t('Удалить')"
                     @removed="removeAlarmType"
                     @closed="() => this.alarmTypeRemoveModal = false"/>
    </Layout>
</template>

<script>

import _ from "underscore";
import {mapState} from "vuex";
import Layout from "../components/Layout";
import types from "../store/types";
import AlarmTypeAddModal from "../components/modals/AlarmTypeAddModal";
import AlarmTypeService from "../services/AlarmTypeService";
import AlarmTypeCard from "../components/AlarmTypeCard";
import AlarmTypeUpdateModal from "../components/modals/AlarmTypeUpdateModal";
import DeviceInfo from "../components/DeviceInfo";
import $ from "jquery";
import RemoveModal from "../components/modals/RemoveModal"
import DeviceObject from "../objects/DeviceObject";

const AlarmTypes = new AlarmTypeService();

export default {
    name: 'DeviceAlarm',
    props: ["id"],
    components: {
        Layout,
        AlarmTypeAddModal,
        AlarmTypeCard,
        RemoveModal,
        AlarmTypeUpdateModal,
        DeviceInfo,
    },
    data() {
        return {
            alarmTypeAddModal: false,
            alarmTypeUpdateModal: false,
            alarmTypeRemoveModal: false,
            removeProcessing: false,
            alarmTypes: [],
            currentAlarmType: {},
        }
    },
    methods: {
        doRemove(alarmType) {
            this.currentAlarmType = alarmType;
            this.alarmTypeRemoveModal = true;
        },
        doUpdate(alarmType) {
            this.currentAlarmType = alarmType;
            this.alarmTypeUpdateModal = true;
        },
        updateAlarmType(alarmType) {
            this.alarmTypes = _.map(this.alarmTypes, item => {
                return item.id === alarmType.id ? alarmType : item;
            });
            //this.closedAlarmTypeUpdateModal();
        },
        createAlarmType(alarmType) {
            this.alarmTypes.push(alarmType);
            this.alarmTypeAddModal = false;
        },
        async removeAlarmType() {
            this.removeProcessing = true;
            const result = await AlarmTypes.remove(this.currentAlarmType.id);
            this.removeProcessing = false;
            this.alarmTypeRemoveModal = false;

            if (result) {
                this.alarmTypes = _.reject(this.alarmTypes, item => item.id === this.currentAlarmType.id);
                this.$toast.success(this.$t('Авария удалена'));
            } else {
                this.$toast.error(this.$t('Ошибка!'));
            }
        },
        closedAlarmTypeUpdateModal() {
            this.alarmTypeUpdateModal = false;
            this.currentAlarmType = {};
        }
    },
    computed: {
        ...mapState({
            devices: state => state.devices
        }),
        currentDevice() {
            return _.find(this.devices, device => device.id === this.id) || {};
        },
        currentDeviceAlarmTypes() {
            return _.filter(this.alarmTypes, type => {
                return type.device === this.currentDevice['@id'];
            })
        },
        deviceObject() {
            return new DeviceObject(this.currentDevice);
        }
    },
    async mounted() {
        this.alarmTypes = await AlarmTypes.fetch();
        this.$store.commit(types.PRELOADER, false);
    }
}
</script>
