<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div ref="modalDialog" class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content app-modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Добавление устройства') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div v-if="opened && !visibleMap" class="modal-body">
                    <div class="form-group">
                        <label class="font-weight-bold text-muted">
                            Dev EUI
                            <strong class="text-danger">*</strong></label>
                        <input v-model="device.dev_eui" type="text" class="form-control">
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold text-muted">
                            {{ $t('Тип устройства') }}
                            <strong class="text-danger">*</strong></label>
                        <select v-model="device.type" class="form-control">
                            <option v-for="type in types"
                                    :key="type.slug"
                                    :value="type['@id']">
                                {{ type.name }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold text-muted">
                            {{ $t('Имя устройства') }}
                            <strong class="text-danger">*</strong></label>
                        <input v-model="device.name" type="text" class="form-control">
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold text-muted">
                            {{ $t('Описание устройства') }}
                            <strong class="text-danger">*</strong></label>
                        <textarea v-model="device.description" class="form-control" rows="3"></textarea>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="font-weight-bold text-muted">
                                    {{ $t('Широта') }}
                                    <strong class="text-danger">*</strong></label>
                                <input v-model="device.latitude" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="font-weight-bold text-muted">
                                    {{ $t('Долгота') }}
                                    <strong class="text-danger">*</strong></label>
                                <input v-model="device.longitude" type="text" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="opened && visibleMap" class="modal-body">
                    <Map style="border-radius: .5rem" :markers="markers" :visibleGetCurrentPositionBtn="true" @change="setCoords"/>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <button @click="visibleMap = !visibleMap" class="btn"
                            :class="{ 'btn-success' : !visibleMap, 'btn-danger' : visibleMap }">
                        <i v-if="!visibleMap" class="fas fa-map-marked-alt pr-2"></i>
                        {{ !visibleMap ? $t('Показать карту') : $t('Выбрать') }}
                    </button>
                    <button v-if="!visibleMap"
                            @click="create"
                            :disabled="processing || !valid"
                            style="min-width: 110px;"
                            class="btn btn-purple">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>{{ $t('Добавить устройство') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $ from "jquery";
import {mapState} from 'vuex';
import Map from "../Map";
import DeviceService from "../../services/DeviceService";

const Device = new DeviceService();

export default {
    name: "DeviceAddModal",
    components: {
        Map
    },
    props: ["opened"],
    data() {
        return {
            processing: false,
            visibleMap: false,
            device: {
                dev_eui: "",
                type: "",
                account_id: null,
                name: "",
                description: "",
                latitude: null,
                longitude: null,
            }
        }
    },
    methods: {
        setCoords({latitude, longitude}) {
            this.device.latitude = latitude;
            this.device.longitude = longitude;
        },
        async create() {
            this.processing = true;
            const result = await Device.create(this.device);
            this.processing = false;

            if (result) {
                this.$toast.success(this.$t('Устройство добавлено!'));
                Object.assign(this.$data, this.$options.data());
                this.$emit("closed");
            } else {
                this.$toast.error(this.$t('Ошибка!'));
            }
        }
    },
    computed: {
        ...mapState({
            types: state => state.types,
            currentObject: state => state.currentObject
        }),
        valid() {
            return this.device.dev_eui
                && this.device.type
                && this.device.name
                && this.device.description
                && this.device.latitude
                && this.device.longitude
        },
        markers() {
            return [{
                latitude: this.device.latitude || 0,
                longitude: this.device.longitude || 0
            }];
        }
    },
    watch: {
        visibleMap(val) {
            if (val) {
                this.$refs.modalDialog.classList.add('modal-lg');
            } else {
                this.$refs.modalDialog.classList.remove('modal-lg');
            }
        },
        opened(val) {
            if (val) {
                $(this.$refs.modal).modal("show");
                this.device.account_id = this.currentObject.account_id;
                this.device.type = this.types[0]['@id'];
            } else {
                Object.assign(this.$data, this.$options.data());
                $(this.$refs.modal).modal("hide");
                this.$emit("closed");
            }
        },
    },
    mounted() {
        $(this.$refs.modal).on('hide.bs.modal', () => this.$emit("closed"));
    },
}
</script>

<style scoped>

</style>
