<script>
import Default from "./Default";
import WiFiRelayTable from "@/components/deviceCharts/WiFiRelayTable";


export default {
    name: "WiFiRelay",
    extends: Default,
    components: {
        DefaultLineChart: WiFiRelayTable,
        DefaultTable: WiFiRelayTable
    },
}
</script>
