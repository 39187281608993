import AbstractService from "./AbstractService";

export default class TypeMetricValueService extends AbstractService {

    constructor() {
        super();
    }

    async fetchAndStore() {
        const {code, body} = await this.request.get("/type_metric_values");
        return {code, body};
    }

    async get(id) {
        const {code, body} = await this.request.get("/devices/" + id + "/type_metric_values");
        return code === 200 ? body["hydra:member"] : [];
    }

    async create({type_metric, device, name, value, port}) {
        const {code, body} = await this.request.post("/type_metric_values", {type_metric, device, name, value, port});
        return {code, body};
    }

    async update({value, port, id}) {
        const {code, body} = await this.request.put("/type_metric_values/" + id, {value, port});
        return {code, body}
    }

    async remove(id) {
        return await this.request.delete("/type_metric_values/" + id);
    }

}
