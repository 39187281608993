<template>
    <div class="row">
        <div class="col-12 mb-4">
            <div class="alert alert-info mb-4">
                <small>{{ $t('Настройка расписания уведомлений для уровня: ') }}</small>
                <small class="font-weight-bold">"{{ levelName }}". </small>
                <small>{{ $t('Укажите ниже в какие дни и часы вы хотите получать уведомления о инцидентах.') }}</small>
            </div>
            <div class="card bg-light p-3">
                <div class="mb-3 text-center">
                    <div class="font-weight-bold">{{ $t('Часы') }}</div>
                </div>
                <div class="row">
                    <div v-for="(chunk, index) in hoursItems" :key="index" class="col-12 col-md-6 col-lg-3 mb-3">
                        <div class="row">
                            <div v-for="item in chunk" :key="item" class="col-12 text-center">
                                <input :checked="selectedHours.indexOf(item) > -1"
                                       @click="changeHour(item)"
                                       type="checkbox">
                                <div class="text-monospace d-inline">
                                    {{ hourLabel(item) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <div class="btn-group btn-group-sm">
                            <button @click="fillAllHours" class="btn btn-outline-primary btn-sm">{{ $t('Весь день') }}</button>
                            <button @click="fillWorkHours" class="btn btn-outline-primary btn-sm">{{ $t('Рабочие часы') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mb-4">
            <div class="card bg-light p-4">
                <div class="mb-3 text-center">
                    <div class="font-weight-bold">{{ $t('Дни недели') }}</div>
                </div>
                <div class="row">
                    <div v-for="item in weekDaysItems" :key="item" class="col-12 col-md-6 col-lg text-center mb-3">
                        <input :checked="selectedWeekDays.indexOf(item) > -1"
                               @click="changeDay(item)"
                               type="checkbox">
                        <div class="text-monospace d-inline">
                            {{ dayLabel(item) }}
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <div class="btn-group btn-group-sm" style="width: 350px;">
                            <button @click="selectedWeekDays = [0, 1, 2, 3, 4 ,5, 6]" class="btn btn-outline-primary btn-sm">{{ $t('Все дни') }}</button>
                            <button @click="selectedWeekDays = [0, 1, 2, 3, 4]" class="btn btn-outline-primary btn-sm">{{ $t('Рабочие дни') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import _ from "underscore";
import SimpleCron from "../../classess/SimpleCron";

export default {
    name: "AlarmTypeModalLevelSchedule",
    props: {
        levelIndex: {
            type: Number,
            required: true
        },
        levelData: {
            required: true
        }
    },
    data() {
        return {
            selectedHours: [],
            selectedWeekDays: [],
        }
    },
    methods: {
        changeHour(item) {
            if (this.selectedHours.indexOf(item) > -1) {
                this.selectedHours = _.without(this.selectedHours, item);
            } else {
                this.selectedHours.push(item);
            }
        },
        changeDay(item) {
            if (this.selectedWeekDays.indexOf(item) > -1) {
                this.selectedWeekDays = _.without(this.selectedWeekDays, item);
            } else {
                this.selectedWeekDays.push(item);
            }
        },
        fillAllHours() {
            this.selectedHours = Array.from(Array(24).keys());
        },
        fillWorkHours() {
            this.selectedHours = [8, 9, 10, 11, 12, 13, 14, 15, 16];
        },
        hourLabel(index) {
            return this.pad(index) + "-" + this.pad(index + 1)
        },
        dayLabel(index) {
            const labels = [
                this.$t('ПН'),
                this.$t('ВТ'),
                this.$t('СР'),
                this.$t('ЧТ'),
                this.$t('ПТ'),
                this.$t('СБ'),
                this.$t('ВС'),
            ]
            return labels[index];
        },
        pad(n) {
            return n < 10 ? '0' + n : n;
        }
    },
    computed: {
        levelName() {
            return this.levelData?.levelName;
        },
        hoursItems() {
            const hours = Array.from(Array(24).keys());

            return [
                hours.slice(0, 6),
                hours.slice(6, 12),
                hours.slice(12, 18),
                hours.slice(18, 24),
            ];

        },
        weekDaysItems() {
            return Array.from(Array(7).keys());
        },
        cronString() {
            const cron = new SimpleCron();

            return cron.setWeekDaysArray(this.selectedWeekDays)
                .setHoursArray(this.selectedHours)
                .getExpressionString();
        }
    },
    watch: {
        cronString(val) {
            if (this.levelIndex < 0) {
                return;
            }

            this.$emit("change", {
                levelIndex: this.levelIndex,
                cronString: val
            })
        },
        levelData: {
            deep: true,
            handler(val) {
                const cron = new SimpleCron(val?.schedule[0]);
                this.selectedWeekDays = cron.getWeekDaysArray();
                this.selectedHours = cron.getHoursArray();
            }
        }
    },
    mounted() {
        const cron = new SimpleCron(this.levelData?.schedule[0]);
        this.selectedWeekDays = cron.getWeekDaysArray();
        this.selectedHours = cron.getHoursArray();
    }
}
</script>

<style scoped>

</style>
