<template>
    <div class="w-100">
        <div class="btn-group d-flex justify-content-between align-items-center">
            <div v-for="(metric, index) in paginatedData"
                 :key="index"
                 :class="{ active : isSelected(metric) }"
                 @click="$emit('change', metric)"
                 class="btn px-2 py-1">

                <span class="position-relative">
                    <span v-if="metric.getPortIndex() !== null" class="metric-icon-index">
                        {{ metric.getPortIndex() + 1 }}
                    </span>

                    <Icon :icon-name="metric.getIcon()"
                          width="25"
                          height="25"
                          :fill="isSelected(metric) ? metric.getColor() : '#AAB2BF'"/>
                </span>

                <Icon class="d-block mx-auto mt-1"
                      v-if="isSelected(metric)"
                      :fill="metric.getColor()"
                      icon-name="dot"
                      width="4"
                      height="4"/>
                <div class="mt-1" v-else style="height: 4px"></div>
            </div>
        </div>
    </div>
</template>

<script>

import Icon from "../../Icon";
import DeviceObject from "../../../objects/DeviceObject";

export default {
    name: "DefaultCard_1MetricsButtons",
    components: {
        Icon
    },
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        currentMetric: {
            required: true
        },
        paginatedData: {
            type: Array,
            required: true
        },
        timeseries: {
            type: Array,
            required: false,
            default: []
        }
    },
    methods: {
        isSelected(metric) {
            return this.currentMetric?.getSlug() === metric.getSlug()
                && this.currentMetric?.getPortIndex() === metric.getPortIndex()
        }
    }
}
</script>

<style scoped lang="less">

.metric-icon-index {
    user-select: none;
    position: absolute;
    top: -5px;
    right: -10px;
    padding: 1px 7px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
}

</style>
