<script>

import moment from "moment-mini";
import DefaultTable from "./DefaultTable";
import config from "../../config";

export default {
    name: "WiFiRelayTable",
    extends: DefaultTable,
    data() {
        return {
            hiddenColumns: ["units"]
        }
    },
    computed: {
        rows() {
            return this.metricTimeseries?.getData()?.map(item => {
                if(this.metricObject.getSlug() === 'relay1') {
                    return {
                        date: moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY),
                        time: moment(item.getDate()).format(config.dateTimeFormats.HHmmss),
                        value: +item.getValue() === 1 ? this.$t("Включено") : this.$t("Выключено"),
                    }
                }
                if(this.metricObject.getSlug() === 'motion') {
                    return {
                        date: moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY),
                        time: moment(item.getDate()).format(config.dateTimeFormats.HHmmss),
                        value: +item.getValue() === 1 ? this.$t("Движение") : this.$t("Нет движения"),
                    }
                }
            }) || []
        }
    }
}
</script>
