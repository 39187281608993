<script>

import DefaultTable from "./DefaultTable";

export default {
    name: "AirQualityHeatingTable",
    extends: DefaultTable,
    data() {
        return {
            hiddenColumns: ["units"]
        }
    },
    methods: {
        prepColumn(field, row) {
            if (field === "value") {

                if (row[field] === 0) {
                    return this.$t("Выключено");
                }

                if (row[field] === 1) {
                    return this.$t("Включено")
                }

                return "N/A";
            }

            return row[field];
        },
    }
}
</script>

<style scoped>

</style>
