<template>
    <div :style="{backgroundColor:  tableColor || '#e3e3e3' }" class="table-chart">
        <div class="d-flex justify-content-between mb-1 pb-1 border-bottom text-center">
            <small class="w-33"><i class="fas mr-1 fa-calendar-alt"></i><span>{{ $t('Дата') }}</span></small>
            <small class="w-33"><i class="far mr-1 fa-clock"></i><span>{{ $t('Время') }}</span></small>
            <small style="width: 16.5%">{{ $t('Знач.') }}</small>
            <small style="width: 16.5%">{{ $t('Ед.изм.') }}</small>
        </div>

        <div v-for="(item, index) in slicedTimeseries" :key="index" class="d-flex justify-content-between text-center mb-1">
            <small class="w-33"> <i class="fas mr-1 fa-calendar-alt"></i><span>{{ item.date }}</span></small>
            <small class="w-33"><i class="far mr-1 fa-clock"></i><span>{{ item.time }}</span></small>
            <small style="width: 16.5%" class="font-weight-bold">{{ item.value }}</small>
            <small style="width: 16.5%">{{ item.units }}</small>
        </div>
    </div>
</template>

<script>

import DeviceObject from "../../../objects/DeviceObject";
import moment from "moment-mini";
import config from "../../../config";

export default {
    name: "DefaultCard_1Table",
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        metricTimeseries: {
            type: Array,
            required: false
        },
        metricObject: {
            required: true,
        }
    },
    computed: {
        tableColor() {
            return this.metricObject?.getColor();
        },
        slicedTimeseries() {
            return this.metricTimeseries?.slice(0, 6)?.reverse()?.map(item => {
                return {
                    date: moment(item.getDate()).format(config.dateTimeFormats.DDMMYYYY),
                    time: moment(item.getDate()).format(config.dateTimeFormats.HHmmss),
                    value: item?.getValue()?.toFixed(2),
                    units: this.metricObject?.getUnits("-")
                };
            });
        },
    }
}
</script>

<style scoped lang="less">
.table-chart {
    padding: .6rem 1rem;
    border-radius: .6rem;
    height: 190px;
    color: #fff;
    overflow-y: auto;
}
</style>
