<template>
    <div class="d-flex justify-content-between flex-column h-100">
        <div>
            <HeaderComponent :deviceObject="deviceObject"
                             :timeseries="timeseries"
                             @deviceDoUpdate="$emit('deviceDoUpdate', deviceObject)"
                             @deviceDoShowGeoPosition="$emit('deviceDoShowGeoPosition', deviceObject)"
                             @deviceDoRemove="$emit('deviceDoRemove', deviceObject)"/>
            <div v-if="deviceNameOnCard" class="mb-2 font-weight-bold">{{ stringLimit(deviceObject.getName(), 32) }}</div>
            <ValuesListComponent :deviceObject="deviceObject"
                                 :currentMetric="currentMetric"
                                 :timeseries="timeseries"
                                 :paginatedData="getPaginatedData"
                                 @change="setCurrentMetric"/>

            <div v-if="countDeviceMetrics > paginatedData.perPage"
                 class="pagination-controls mb-3 d-flex align-items-center justify-content-between">
                <button
                    class="btn pagination-controls-left"
                    :disabled="paginatedData.currentPage === 0"
                    @click="paginatedData.currentPage--">
                </button>
                <button
                    class="btn pagination-controls-right"
                    :disabled="paginatedData.currentPage >= pageCount -1"
                    @click="paginatedData.currentPage++">
                </button>
            </div>
            <div class="empty mb-3" v-else style="height: 25px"></div>
        </div>
        <div>
            <div v-if="!deviceCardCompactMode" class="row" style="margin-bottom: 20px">
                <div class="col-12">
                    <ChartComponent
                        :deviceObject="deviceObject"
                        :timeseries="timeseries"
                        :metricObject="currentMetric"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button @click="$router.push({name: 'Device', params: { id: deviceObject.getId() }})"
                            class="btn btn-purple btn-custom btn-block">
                        {{ $t('Статистика') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Icon from "../../Icon";
import DefaultCard_1AbstractChart from "./DefaultCard_1AbstractChart";
import DefaultCard_1Header from "./DefaultCard_1Header";
import DefaultCard_2 from "./DefaultCard_2";
import DefaultCard_3ValuesList from "./DefaultCard_3ValuesList";

export default {
    name: "DefaultCard_3",
    extends: DefaultCard_2,
    components: {
        HeaderComponent: DefaultCard_1Header,
        ChartComponent: DefaultCard_1AbstractChart,
        ValuesListComponent: DefaultCard_3ValuesList,
        Icon
    },
}
</script>

<style lang="less" scoped>

@import "../../../less/_variables";

.list-group {
    border-radius: 0;

    &-item {
        display: flex;
        border-color: transparent;
        padding: 0.45rem 1.25rem;
        font-size: 0.9rem;

        &:hover {
            background: white;
        }

        &-title {
            font-weight: 500;
        }

        &-value {
            font-weight: 600;
            color: #706C6C;
        }

        &.active {
            color: #495057;
            background: #f8f9fa;
        }
    }
}

.pagination-controls {

    &-left {
        width: 25px;
        height: 25px;
        padding: 0 11px 0 0;
        background: url("../../../assets/ArrowLeft.png");
    }

    &-right {
        width: 25px;
        height: 25px;
        padding: 0;
        background: url("../../../assets/ArrowRight.png");
    }
}

.btn-custom {
    height: 38px;
    font-weight: 400;
    font-size: 16px;
    width: 49%;
    margin: 0 auto;
}
</style>
