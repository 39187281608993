<template>
    <div class="row px-lg-4">
        <div class="col-12 col-lg-6 d-flex justify-content-between align-items-center mb-4 mb-lg-0">
            <div>
                <i v-if="contact.type === 'phone'" class="fas fa-phone-alt fa-fw fa-lg text-purple"></i>
                <i v-else class="fas fa-envelope fa-fw fa-lg text-purple"></i>
            </div>
            <div class="pl-4 flex-fill text-left font-weight-bold text-black-50">
                <span v-if="contact.type === 'phone'"> {{ contact.value | beautifyPhoneNumber }}</span>
                <span v-else> {{ contact.value | stringLimit }}</span>
            </div>
        </div>
        <div class="col-12 col-lg-6 d-flex justify-content-between align-items-center">
            <div>
                <div class="contact-auth-allow">
                    <div v-if="contact.auth">
                        <i class="fas fa-unlock text-success"></i>
                        <small class="ml-2 text-success">{{ $t('Авторизация разрешена') }}</small>
                    </div>
                    <div v-else>
                        <i class="fas fa-lock text-muted"></i>
                        <small class="ml-2">{{ $t('Авторизация запрещена') }}</small>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <div v-show="processing" class="spinner-border text-purple spinner-border-sm mr-3 position-relative" style="top: 1px" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <toggle-button :color="toggleColor"
                               :sync="true"
                               :value="contact.auth"
                               :disabled="disabled"
                               class="position-relative"
                               style="top: 5px"
                               @change="e => update(e, contact)"/>
            </div>
        </div>
    </div>
</template>

<script>

import Helper from "../classess/Helper";
import ContactService from "../services/ContactService";
import config from "../config";

const Contact = new ContactService();

export default {
    name: "ContactItem",
    props: ["contact", "disabled"],
    filters: {
        stringLimit: str => Helper.stringLimit(str, 20),
        beautifyPhoneNumber: Helper.beautifyPhoneNumber
    },
    data() {
        return {
            processing: false,
        }
    },
    methods: {
        async update(e, contact) {
            contact.auth = e.value;
            this.$emit('update');
            this.processing = true;
            const result = await Contact.update(contact);
            this.$emit('updated', contact);
            this.processing = false;
            if (result) {
                this.$toast.success(this.$t('Контакт Обновлён!'));
            } else {
                this.$toast.error(this.$t('Ошибка!'));
            }
        },
    },
    computed: {
        toggleColor() {
            return config.toggleColor;
        }
    },
}
</script>

<style scoped>

</style>