import AbstractService from "./AbstractService";

export default class AlarmConditionService extends AbstractService {

    constructor() {
        super();
    }

    async create(condition) {
        const {code, body} = await this.request.post("/alarm_conditions", condition);
        return code === 201 ? body : false;
    }

    async update(condition) {
        const {code, body} = await this.request.put("/alarm_conditions/" + condition.id, condition);
        return code === 200 ? body : false;
    }

    async remove(conditionId) {
        const {code} = await this.request.delete("/alarm_conditions/" + conditionId);
        return code === 204;
    }
}
