import AbstractService from "./AbstractService";

export default class AlarmTypeService extends AbstractService {

    constructor() {
        super();
        this.cachedAlarmTypes = {};
    }

    async get(id) {
        const {code, body} = await this.request.get("/alarm_types/" + id);
        return code === 200 ? body : [];
    }

    async getWitchCache(id) {
        if (!this.cachedAlarmTypes.hasOwnProperty(id)) {
            this.cachedAlarmTypes[id] = await this.get(id);
        }

        return this.cachedAlarmTypes[id];
    }

    async fetch() {
        const {code, body} = await this.request.get("/alarm_types");
        return code === 200 ? body["hydra:member"] : [];
    }

    async create({deviceId, deviceType, title, createRuleConditions}) {
        const {code, body} = await this.request.post("/alarm_types", {
            title: title,
            create_rules: createRuleConditions,
            clear_rule: null,
            device: deviceId,
            type: deviceType,
            enabled: true
        });

        return code === 201 ? body : false;
    }

    async update(id, title, schedule) {
        const {code, body} = await this.request.put("/alarm_types/" + id, {
            title,
            schedule
        });
        return code === 200 ? body : false;
    }

    async remove(id) {
        const {code} = await this.request.delete("/alarm_types/" + id);
        return code === 204;
    }
}
