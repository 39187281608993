<template>
    <div>
        <div v-if="metricTimeseries && metricTimeseries.length">
            <DefaultCard_1Table v-if="isTabledMetric"
                                :deviceObject="deviceObject"
                                :metricObject="metricObject"
                                :metricTimeseries="metricTimeseries"/>

            <DefaultCard_1LineChart v-else
                                    :deviceObject="deviceObject"
                                    :metricTimeseries="metricTimeseries"
                                    :metricObject="metricObject"/>
        </div>
        <div v-else class="chart-stub" :style="{ background : chartColor || '#e3e3e3' }">
            {{ $t('Нет данных') }}
        </div>
    </div>
</template>

<script>

import LineChart from "../CardLineChart";
import DeviceObject from "../../../objects/DeviceObject";
import DefaultCard_1LineChart from "./DefaultCard_1Chart";
import DefaultCard_1Table from "./DefaultCard_1Table";
import metricsConfig from "../../../metricsConfig";

export default {
    name: "DefaultCard_1AbstractChart",
    components: {
        DefaultCard_1Table,
        DefaultCard_1LineChart,
        LineChart
    },
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        metricObject: {
            required: true,
        },
        timeseries: {
            type: Array,
            required: false
        },
    },
    computed: {
        chartColor() {
            return this.metricObject?.getColor();
        },
        isTabledMetric() {
            const deviceTypeSlug = this.deviceObject.getType().getSlug();
            return metricsConfig[deviceTypeSlug]?.tabledMetrics?.indexOf(this.metricObject?.getSlug()) > -1
        },
        metricTimeseries() {
            const values = this.timeseries?.filter(item => item.getSlug() === this.metricObject?.getSlug());

            const value = this.metricObject?.getPortIndex()
                ? values[this.metricObject?.getPortIndex()]
                : values[0]

            return value?.getData();
        },
    }
}
</script>

<style lang="less" scoped>

.chart-stub {
    text-align: center;
    vertical-align: middle;
    line-height: 150px;
    height: 190px;
    padding: 1rem;
    color: #fff;
    border-radius: 10px;
}
</style>
