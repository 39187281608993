import i18n from "./i18n";

export default {
    // apiUrl: process.env.NODE_ENV === 'development'
    //     ? "https://api-smartmisto.r.freeutg.com/v1"
    //     : window.location.hostname === "dashboard.blbsk.com"
    //         ? "https://api-smartmisto.r.freeutg.com/v1"
    //         : "https://api.smartmisto.com.ua/v1",
     apiUrl: "https://api.smartmisto.com.ua/v1",
    alarmLevelColors: {
        0: "#c1c1c1",
        1: "#00befc",
        2: "#3ab104",
        3: "#ff8e00",
        4: "#ff0000",
    },
    dateTimeFormats: {
        DDMMYYYYHHmmss: "DD.MM.YYYY HH:mm:ss",
        DDMMYYHHmm: "DD.MM.YY HH:mm",
        DDMMHHmm: "DD.MM HH:mm",
        DDMMYYYY: "DD.MM.YYYY",
        HHmmss: "HH:mm:ss",
        HHmm: "HH:mm",
    },
    alarmLevels: {
        0: i18n.t('Низкий'),
        1: i18n.t('Нормальный'),
        2: i18n.t('Средний'),
        3: i18n.t('Высокий'),
        4: i18n.t('Критический'),
    },
    conditionsList: {
        eq: i18n.t('Равно (=)'),
        neq: i18n.t('Не равно (!=)'),
        gt: i18n.t('Больше (>)'),
        lt: i18n.t('Меньше (<)'),
        gte: i18n.t('Больше или равно (>=)'),
        lte: i18n.t('Меньше или равно (<=)'),
    },
    vgtPaginationOptions: (extend = {}) => {
        return Object.assign({
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'bottom',
            perPageDropdown: [5, 10, 20],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: i18n.t('Туда'),
            prevLabel: i18n.t('Сюда'),
            rowsPerPageLabel: i18n.t('Строк на страницу'),
            ofLabel: i18n.t('из'),
            pageLabel: i18n.t('Страница'),
            allLabel: i18n.t('Все'),
        }, extend)
    },
    toggleColor: "#732AAD",
    cardAutoUpdateInterval: 15, // seconds
    /**
     * Аккаунты для демо-кейсов, в этих аккаунтах, девайсы будут сгруппированы по вкладкам
     * В каждой вкладке будут показаны девайсы из конфига (если они есть в аккаунте)
     * account_id : {
     *   localized_case_name_1: ["device_type_slug_or_dev_eui", ....]
     * }
     */
    demoCaseAccounts: {
        220033: [
            {
                name: i18n.t("Качество воздуха"),
                types: ["air_quality_xxxx"],
                icon: "dc-air-quality"
            },
            {
                name: i18n.t("Датчик для агро"),
                types: ["agro_smart_xxxx"],
                icon: "dc-agro"
            },
            {
                name: i18n.t("Датчик давления воды + управление задвижками"),
                types: ["water_pressure_xxxx", "water_pressure_one_xxxx", "dragino_lt_relay_xxxx"],
                icon: "dc-pressure"
            },
            {
                name: i18n.t("Токовые петли + счетчик электричества"),
                types: ["mtx_electricity_meter_x_xxxx", "phase_current_meter_xxxx"],
                icon: "dc-electricity"
            },
        ],
        307690: [
            {
                name: i18n.t("Качество воздуха"),
                types: ["air_quality_xxxx"],
                icon: "dc-air-quality"
            },
            {
                name: i18n.t("Датчик для агро"),
                types: ["agro_smart_xxxx"],
                icon: "dc-agro"
            },
            {
                name: i18n.t("Датчик давления воды + управление задвижками"),
                types: ["water_pressure_xxxx", "water_pressure_one_xxxx", "dragino_lt_relay_xxxx"],
                icon: "dc-pressure"
            },
            {
                name: i18n.t("Токовые петли + счетчик электричества"),
                types: ["mtx_electricity_meter_x_xxxx", "phase_current_meter_xxxx"],
                icon: "dc-electricity"
            },
        ]
    }
}
