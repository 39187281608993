<template>
    <div>
        <div class="row no-gutters app-auth">
            <div class="col-6 app-auth-bg text-center"><img class="app-auth-logo" src="@/assets/logo.png" alt=""></div>
            <div class="col-6 app-auth-form d-flex align-items-center">
                <div>
                    <h4 class="app-auth-form-title">{{ $t('Авторизация') }}</h4>
                    <div class="input-group mb-4 d-flex justify-content-between flex-nowrap">
                        <div class="input-group-prepend d-flex justify-content-center align-items-center pr-1 position-relative">
                            <Icon icon-name="login" :fill="currentTheme"></Icon>
                        </div>
                        <input v-model="login" type="text" class="form-control form-control-lg" :placeholder="$t('Логин')">
                    </div>
                    <div class="input-group d-flex justify-content-between flex-nowrap position-relative">
                        <div class="input-group-prepend d-flex justify-content-center align-items-center pr-1 position-relative">
                            <Icon icon-name="password" :fill="currentTheme"></Icon>
                        </div>
                        <input v-model="password" :type="passwordFieldType" class="form-control form-control-lg" :placeholder="$t('Пароль')">
                        <div class="input-group-append d-flex justify-content-center align-items-center mr-1 position-absolute" @click="switchVisibility" v-if="password">
                            <Icon icon-name="Show" :fill="currentTheme"></Icon>
                        </div>
                    </div>
                    <button @click="auth" class="btn btn-lg" :disabled="processing">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>{{ $t('Вход') }}</span>
                    </button>
                    <!--                            <button @click="login" class="btn btn-lg btn-block btn-primary mb-4">{{ $t('Войти через Google') }}</button>-->
                    <!--                            <div class="row">-->
                    <!--                                <div class="col-5 text-left">-->
                    <!--                                    <a href="#" @click.prevent="$router.push({name: 'Register'})" class="font-weight-bold text-danger">-->
                    <!--                                        {{ $t('Регистрация') }}-->
                    <!--                                    </a>-->
                    <!--                                </div>-->
                    <!--                                <div class="col-7 text-right">-->
                    <!--                                    <a href="#" @click.prevent="$router.push({name: 'RestorePassword'})" class="font-weight-bold text-danger">-->
                    <!--                                        {{ $t('Восстановить пароль') }}-->
                    <!--                                    </a>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                </div>
            </div>
        </div>
        <div class=" app-auth app-auth-mobile">
            <div class="app-auth-bg text-center">
                <img class="app-auth-logo" src="@/assets/logo.png" width="95px" height="40px">
                <div class="app-auth-form d-flex align-items-center justify-content-center">
                    <div>
                        <h5 class="app-auth-form-title mb-5">{{ $t('Авторизация') }}</h5>
                        <div class="input-group mb-4 d-flex justify-content-between flex-nowrap">
                            <div class="input-group-prepend d-flex justify-content-center align-items-center pr-1 position-relative">
                                <Icon icon-name="login" fill="#ffffff60"></Icon>
                            </div>
                            <input v-model="login" type="text" class="form-control form-control-lg" :placeholder="$t('Логин')">
                        </div>
                        <div class="input-group d-flex justify-content-between flex-nowrap position-relative">
                            <div class="input-group-prepend d-flex justify-content-center align-items-center pr-1 position-relative">
                                <Icon icon-name="password" fill="#ffffff60"></Icon>
                            </div>
                            <input v-model="password" :type="passwordFieldType" class="form-control form-control-lg" :placeholder="$t('Пароль')">
                            <div class="input-group-append d-flex justify-content-center align-items-center mr-1 position-absolute" @click="switchVisibility" v-if="password">
                                <Icon icon-name="Show" fill="#ffffff60"></Icon>
                            </div>
                        </div>
                        <button @click="auth" class="btn btn-lg" :disabled="processing">
                            <span v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                                <span class="sr-only">Loading...</span>
                            </span>
                            <span v-else>{{ $t('Вход') }}</span>
                        </button>
                        <!--                            <button @click="login" class="btn btn-lg btn-block btn-primary mb-4">{{ $t('Войти через Google') }}</button>-->
                        <!--                            <div class="row">-->
                        <!--                                <div class="col-5 text-left">-->
                        <!--                                    <a href="#" @click.prevent="$router.push({name: 'Register'})" class="font-weight-bold text-danger">-->
                        <!--                                        {{ $t('Регистрация') }}-->
                        <!--                                    </a>-->
                        <!--                                </div>-->
                        <!--                                <div class="col-7 text-right">-->
                        <!--                                    <a href="#" @click.prevent="$router.push({name: 'RestorePassword'})" class="font-weight-bold text-danger">-->
                        <!--                                        {{ $t('Восстановить пароль') }}-->
                        <!--                                    </a>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>

import types from "../../store/types";
import AuthService from "../../services/AuthService";
import Icon from "../../components/Icon";
import {mapState} from "vuex";

const Auth = new AuthService();

export default {
    name: "Auth",
    components: {Icon},
    data() {
        return {
            login: "",
            password: "",
            passwordFieldType: "password",
            processing: false,
        }
    },
    methods: {
        async auth() {
            this.processing = true;
            const result = await Auth.login(this.login.trim(), this.password.trim());
            if (!result) {
                this.$toast.error(this.$t('Неверный логин или пароль'));
            } else {
                await this.$router.push({name: "Home"});
            }
            this.processing = false;
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.commit(types.PRELOADER, false);
        })
    },
    computed: {
        ...mapState({
            currentTheme: state => state.currentTheme,
        })
    },
}
</script>

<style scoped lang="less">
@import "../../less/auth";
</style>