<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content app-modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Местоположение устройства') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div v-if="opened" class="modal-body">
                    <Map style="border-radius: .5rem;" :fwControl="false" :height="600" :markers="markers"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $ from "jquery";
import Map from "../Map";
import DeviceObject from "../../objects/DeviceObject";

export default {
    name: "DeviceGeoPositionModal",
    components: {
        Map
    },
    props: {
        opened: Boolean,
        deviceObject: {
            type: DeviceObject,
            required: true
        },
    },
    computed: {
        markers() {
            return [{
                latitude: this.deviceObject.getLatitude() || 0,
                longitude: this.deviceObject.getLongitude() || 0
            }];
        },
    },
    watch: {
        opened(val) {
            if (val) {
                $(this.$refs.modal).modal("show");
            } else {
                $(this.$refs.modal).modal("hide");
                this.$emit("closed");
            }
        },
    },
    mounted() {
        $(this.$refs.modal).on('hide.bs.modal', () => this.$emit("closed"));
    }
}
</script>

<style scoped>

</style>
