<template>
    <div>
        <div class="metric-banner position-relative px-2 pt-2" :style="metricImage">
            <HeaderComponent class="px-2 pt-2"
                             :deviceObject="deviceObject"
                             :timeseries="timeseries"
                             @deviceDoUpdate="$emit('deviceDoUpdate', deviceObject)"
                             @deviceDoShowGeoPosition="$emit('deviceDoShowGeoPosition', deviceObject)"
                             @deviceDoRemove="$emit('deviceDoRemove', deviceObject)"/>
            <div class="row">
                <div class="col-12 px-4 d-flex align-items-center flex-column">
                    <div class="text-center">
                        <div class="overflow-hidden text-white"
                             data-toggle="tooltip"
                             :title="currentMetricName"
                             :key="Math.random()">
                            {{ stringLimit(currentMetric ? currentMetricName : $t('Нет данных'), 20) }}
                        </div>
                    </div>
                    <LatestValueComponent :deviceObject="deviceObject"
                                          :timeseries="timeseries"
                                          :metricImage="metricImage"
                                          :currentMetric="currentMetric"/>
                    <div v-if="currentAccountId && !isCurrentUserDevice"
                         data-toggle="tooltip"
                         :title="sharedTooltipText"
                         class="shared-indicator">
                        <i class="fas fa-question text-light"></i>
                    </div>
                </div>
            </div>

            <div v-if="countDeviceMetrics > paginatedData.perPage" class="pagination-controls">
                <button
                    class="btn pagination-controls-left"
                    :disabled="paginatedData.currentPage === 0"
                    @click="paginatedData.currentPage--">
                </button>
                <button
                    class="btn pagination-controls-right"
                    :disabled="paginatedData.currentPage >= pageCount -1"
                    @click="paginatedData.currentPage++">
                </button>
            </div>

        </div>
        <div v-if="deviceNameOnCard" class="my-2 text-center font-weight-bold">{{ stringLimit(deviceObject.getName(), 30) }}</div>
        <div class="row">
            <div class="col-12 text-center">
                <MetricsButtonsComponent
                    :deviceObject="deviceObject"
                    :currentMetric="currentMetric"
                    :paginatedData="getPaginatedData"
                    :timeseries="timeseries"
                    @change="setCurrentMetric"/>
            </div>
        </div>
        <div v-if="!deviceCardCompactMode" class="row" style="margin-bottom: 20px">
            <div class="col-12">
                <ChartComponent
                    :deviceObject="deviceObject"
                    :timeseries="timeseries"
                    :metricObject="currentMetric"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button @click="$router.push({name: 'Device', params: {id: deviceObject.getId() }})"
                        class="btn btn-purple btn-custom btn-block">
                    {{ $t('Статистика') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import Icon from "../../Icon";
import DefaultCard_1AbstractChart from "./DefaultCard_1AbstractChart";
import DefaultCard_2LatestValue from "./DefaultCard_2LatestValue";
import DefaultCard_2MetricsButtons from "./DefaultCard_2MetricsButtons";
import DefaultCard_1Header from "./DefaultCard_1Header";
import DefaultCard_1 from "./DefaultCard_1";

export default {
    name: "DefaultCard_2",
    extends: DefaultCard_1,
    components: {
        HeaderComponent: DefaultCard_1Header,
        MetricsButtonsComponent: DefaultCard_2MetricsButtons,
        LatestValueComponent: DefaultCard_2LatestValue,
        ChartComponent: DefaultCard_1AbstractChart,
        Icon
    },
    data() {
        return {
            paginatedData: {
                currentPage: 0,
                perPage: 3,
            },
        }
    }
}
</script>

<style lang="less" scoped>

@import "../../../less/_variables";

.metric-banner {
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    height: 190px;
    margin: -20px -20px 0;
}

.pagination-controls {
    position: absolute;
    bottom: 1rem;
    right: 1rem;

    &-left {
        width: 25px;
        height: 25px;
        padding: 0 11px 0 0;
        background: url("../../../assets/ArrowLeft.png") no-repeat;
    }

    &-right {
        width: 25px;
        height: 25px;
        padding: 0;
        background: url("../../../assets/ArrowRight.png") no-repeat;
    }
}

.btn-custom {
    height: 38px;
    font-weight: 400;
    font-size: 16px;
    width: 49%;
    margin: 0 auto;
}

</style>
