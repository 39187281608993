<template>
    <div class="center-block" :style="{ position : positionType}">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "CenterBlock",
    props: {
        positionType: {
            type: String,
            required: false,
            default: "absolute"
        }
    }
}
</script>

<style lang="less" scoped>

.center-block {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

</style>
