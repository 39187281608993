<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div ref="modalDialog" class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content app-modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-center">{{ $t('Поправочный коэффициент') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="font-weight-bold text-muted">
                            {{ $t('Метрика') }}
                        </label>
                        <select v-model="currentMetric" class="form-control">
                            <option v-for="metric in metrics"
                                    v-if="isAggregatedMetric(metric)"
                                    :key="metric.getSlug()"
                                    :value="metric">
                                {{ metric.getName() }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <div class="form-group">
                            <label class="font-weight-bold text-muted">
                                {{ $t('Коэффициент умножения') }}
                            </label>
                            <input placeholder="1" type="number" class="form-control" v-model="factor">
                            <small class="form-text text-muted">
                                {{ $t('Значение метрики будет умножено на этот коэффициент.') }}
                            </small>
                        </div>
                        <label class="font-weight-bold text-muted">
                            {{ $t('Коэффициент сложения') }}
                        </label>
                        <input placeholder="0" type="number" class="form-control" v-model="shift">
                        <small class="form-text text-muted">
                            {{ $t('Этот коэффициент будет прибавлен к значению метрики, для вычитания значения - используйте отрицательное число.') }}
                        </small>
                    </div>
                    <small class="alert alert-warning text-muted font-italic d-block">
                        {{ $t('Вы можете откалибровать метрику, если она снимает ошибочные показания.') }} <br>
                        {{ $t('Просто установите соответствующие коэффициенты.') }} <br>
                        {{ $t('Коэффициенты применяются по правилам математики - сначала умножение, затем сложение.') }}
                    </small>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <button @click="close()" class="btn btn-success" style="min-width: 110px;">
                        {{ $t('Закрыть') }}
                    </button>
                    <button @click="changeCoefficient"
                            :disabled="processing"
                            style="min-width: 110px;"
                            class="btn btn-purple">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>{{ $t('Сохранить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $ from "jquery";
import TypeMetricValueService from "../../services/TypeMetricValueService";
import metricsConfig from "../../metricsConfig"
import {EventBus, events} from "../../EventBus";

const TypeMetricValue = new TypeMetricValueService;

export default {
    name: "DeviceCoefficientModal",
    data() {
        return {
            processing: false,
            deviceObject: {},
            metrics: [],
            shift: null,
            factor: null,
            currentMetric: null,
            typeMetricValues: []
        }
    },
    methods: {
        async getTypeMetricValue(id) {
            this.typeMetricValues = await TypeMetricValue.get(id)
        },
        async changeCoefficient() {
            this.processing = true;
            await this.changeShift();
            await this.changeFactor();
            await this.getTypeMetricValue(this.deviceObject.getId());
            this.processing = false;
        },
        async changeShift() {
            const successMessage = this.$t("Коэффициент смещения сохранен!");

            // skip or remove
            if (this.shift == 0) {
                if (this.shiftOnState) {
                    await TypeMetricValue.remove(TypeMetricValue.parseId(this.shiftOnState['@id']));
                    this.$toast.success(successMessage);
                }
            }

            // create
            else if (this.shiftOnState === undefined) {
                const createResponse = await TypeMetricValue.create({
                    type_metric: this.currentMetric.getId(),
                    device: this.deviceObject.getIRI(),
                    name: 'shift',
                    value: +this.shift,
                    port: this.currentMetric.getPortIndex()
                });

                if (createResponse.code === 201) {
                    this.$toast.success(successMessage);
                } else {
                    this.$toast.error(TypeMetricValue.parseValidationErrorMessage(createResponse.body));
                }
            }

            // update
            else {
                const updateResponse = await TypeMetricValue.update({
                    value: +this.shift,
                    port: this.currentMetric.getPortIndex(),
                    id: TypeMetricValue.parseId(this.shiftOnState['@id']),
                });

                if (updateResponse.code === 200) {
                    this.$toast.success(successMessage);
                } else {
                    this.$toast.error(TypeMetricValue.parseValidationErrorMessage(updateResponse.body));
                }
            }
        },
        async changeFactor() {
            const successMessage = this.$t("Коэффициент умножения сохранен!");

            // skip or remove
            if (this.factor == 1) {
                if (this.factorOnState) {
                    await TypeMetricValue.remove(TypeMetricValue.parseId(this.factorOnState['@id']));
                    this.$toast.success(successMessage);
                }
            }

            // create
            else if (this.factorOnState === undefined) {
                const createResponse = await TypeMetricValue.create({
                    type_metric: this.currentMetric.getId(),
                    device: this.deviceObject.getIRI(),
                    name: 'factor',
                    value: +this.factor,
                    port: this.currentMetric.getPortIndex()
                });

                if (createResponse.code === 201) {
                    this.$toast.success(successMessage);
                } else {
                    this.$toast.error(TypeMetricValue.parseValidationErrorMessage(createResponse.body));
                }
            }

            // update
            else {
                const updateResponse = await TypeMetricValue.update({
                    value: +this.factor,
                    port: this.currentMetric.getPortIndex(),
                    id: TypeMetricValue.parseId(this.factorOnState['@id']),
                });

                if (updateResponse.code === 200) {
                    this.$toast.success(successMessage);
                } else {
                    this.$toast.error(TypeMetricValue.parseValidationErrorMessage(updateResponse.body));
                }
            }
        },
        isAggregatedMetric(metric) {
            let deviceTypeSlug = this.deviceObject?.getType()?.getSlug()
            let metricSlug = metric.getSlug();

            if (!metricsConfig.hasOwnProperty(deviceTypeSlug)) {
                return true;
            }

            return metricsConfig[deviceTypeSlug]?.notAggregatedMetrics?.indexOf(metricSlug) === -1;
        },
        close() {
            $(this.$refs.modal).modal("hide");
        },
    },
    computed: {
        shiftOnState() {
            return this.typeMetricValues.find(item => {
                return item.type_metric === this.currentMetric?.getId() && item.name === 'shift'
            })
        },
        factorOnState() {
            return this.typeMetricValues.find(item => {
                return item.type_metric === this.currentMetric?.getId() && item.name === 'factor'
            })
        }
    },
    watch: {
        opened(val) {
            if (val) {
                $(this.$refs.modal).modal("show");
            } else {
                this.close();
            }
        },
        shiftOnState: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.shift = this.shiftOnState?.value || 0;
                })
            }
        },
        factorOnState: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.factor = this.factorOnState?.value || 1;
                })
            }
        }
    },
    async mounted() {
        $(this.$refs.modal).on('hide.bs.modal', () => this.$emit("closed"));

        await EventBus.$on(events.DEVICE_COEFFICIENT_MODAL_OPEN, ({deviceObject, metric}) => {
            this.deviceObject = deviceObject;
            this.metrics = deviceObject?.getType().getMetricsWithPorts();
            this.currentMetric = metric ? metric : this.metrics[0];
            this.getTypeMetricValue(deviceObject?.getId());
            $(this.$refs.modal).modal("show");
        });

    },

}
</script>

<style scoped>

</style>
