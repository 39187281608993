import AbstractService from "./AbstractService";
import types from "../store/types";

export default class UserService extends AbstractService{

    constructor() {
        super();
    }

    async getAndStore() {
        const {code, body} = await this.request.get("/user");

        if (code === 200) {
            await this.store.commit(types.CURRENT_USER, body);
            return true;
        }

        return false;
    }

}
