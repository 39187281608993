<template>
    <LineChartGenerator v-if="renderComponent" :chart-data="chartData" :chart-options="chartOptions"/>
</template>
<script>
import {Line as LineChartGenerator} from 'vue-chartjs/legacy'
import config from "../../config";
import moment from "moment-mini";
import Color from "../../classess/Color"
import MetricObject from "../../objects/MetricObject";
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chart.js/auto'
import {Chart} from "chart.js";
import SimpleCron from "@/classess/SimpleCron";

Chart.register(zoomPlugin);

export default {
    name: "DefaultLineChart",
    components: {LineChartGenerator},
    props: {
        metricObject: {
            type: MetricObject,
            required: true
        },
        metricTimeseries: {
            required: true
        },
        timeseries: {
            required: true
        },
        selectedPeriod: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            renderComponent: true,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    point: {
                        radius: 0,
                        hoverRadius: 10,
                        hitRadius: 5,
                        hoverBorderWidth: 4
                    },
                },
                tooltips: {
                    displayColors: true,
                    yPadding: 10,
                    xPadding: 10,
                    caretSize: 6,
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    titleFontColor: '#252733',
                    bodyFontColor: '#252733',
                    borderColor: '#DFE0EB',
                    borderWidth: 1,
                    cornerRadius: 4,
                },
                scales: {
                    y: {
                        beginAtZero: true
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    zoom: {
                        animation: {
                            duration: 1000,
                            easing: 'easeOutCubic'
                        },
                        pan: {
                            enabled: true,
                            mode: 'x'
                        },
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            pinch: {
                                enabled: true
                            },
                            mode: 'x',
                        }
                    }
                }
            }
        }
    },
    methods: {
        dateFormat(date) {
            let format = ["today", "yesterday", "before_yesterday"].indexOf(this.selectedPeriod.slug) > -1
                ? config.dateTimeFormats.HHmm
                : config.dateTimeFormats.DDMMYYHHmm;

            if (this.selectedPeriod.slug === "custom") {
                format = this.selectedPeriod.groupBy === "hour" ? config.dateTimeFormats.HHmm : config.dateTimeFormats.DDMMYYHHmm;
            }

            return moment(date).format(format);
        },
        forceRerender() {
            this.renderComponent = false;

            this.$nextTick(() => {
                this.renderComponent = true;
            });
        }
    },
    computed: {
        chartData() {
            return {
                labels: this.labels,
                datasets: [{
                    label: this.$t("Среднее значение"),
                    data: this.datasets,
                    fill: true,
                    backgroundColor: (ctx) => {
                        const canvas = ctx.chart.ctx;
                        const gradient = canvas.createLinearGradient(0, 0, 0, 500);
                        gradient.addColorStop(0.1, Color.hexToRGBA(this.metricObject.getColor(), 60));
                        gradient.addColorStop(0.6, Color.hexToRGBA(this.metricObject.getColor(), 5));

                        return gradient;
                    },
                    borderColor: this.metricObject.getColor(),
                    lineTension: 0.3,
                    borderWidth: 2,
                    borderRadius: 1000,
                    pointBackgroundColor: "#FFFFFF",
                }]
            }
        },
        labels() {
            return this.metricTimeseries
                ?.getData()
                ?.map(item => {
                    return this.dateFormat(item.getDate())
                }).reverse();
        },
        datasets() {
            return this.metricTimeseries
                ?.getData()
                ?.map(item => {
                    return item.getAvg() !== null && typeof item.getAvg() !== "undefined"
                        ? item.getAvg()
                        : item.getValue()
                })
                ?.reverse();
        }
    },
    watch: {
        metricTimeseries() {
            this.forceRerender()
        }
    },
    mounted() {
        // this.render()
    }
}
</script>

<style scoped>

</style>
