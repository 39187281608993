<template>
    <div ref="mapContainer" id="map-container" class="position-relative">
        <button v-if="visibleGetCurrentPositionBtn"
                @click.stop.prevent="getCurrentPosition" class="btn btn-purple position-absolute">
            <i class="far fa-dot-circle fa-fw"></i>
        </button>
    </div>
</template>

<script>

import "leaflet";
import "leaflet-fullscreen";
import "leaflet.markercluster";
import ukJSON from "../assets/ukraine.geo.json";

export default {
    name: "Map",
    props: {
        markers: Array,
        height: {
            type: Number,
            required: false,
            default: 460
        },
        fwControl: {
            type: Boolean,
            required: false,
            default: true
        },
        visibleGetCurrentPositionBtn: {
            required: false,
            type: Boolean,
            default: false
        },
        isMyDevice: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            map: null,
            markerGroup: [],
            currentPosition: null
        }
    },
    methods: {
        getCurrentPosition() {
            navigator.geolocation.getCurrentPosition(({coords}) => {
                this.currentPosition = coords;
                this.$toast.success(this.$t("Позиция определена!"));
                this.$emit('change', {
                    latitude: coords.latitude,
                    longitude: coords.longitude
                });
            }, () => {
                this.$toast.error(this.$t("Позиция не определена!"));
            })
        },
        renderMarkers() {
            this.markerGroup.eachLayer(layer => layer.remove());

            if (this.computedMarkers?.length) {
                this.computedMarkers.forEach(marker => {
                    if (marker.latitude && marker.longitude) {

                        L.marker([marker.latitude, marker.longitude], {
                            icon: L.icon({
                                iconUrl: require("../assets/marker-icon-2x-blue.png"),
                                iconRetinaUrl: require("../assets/marker-icon-2x-blue.png"),
                                shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
                                iconSize: [25, 41],
                                iconAnchor: [25, 41],
                            })
                        }).addTo(this.markerGroup);

                        this.map.panTo([marker.latitude, marker.longitude]);
                    }
                });
            }
        },
        renderMap() {
            this.$refs.mapContainer.style.height = this.height + "px";

            this.map = L.map('map-container', {
                scrollWheelZoom: true,
                fullscreenControl: this.fwControl,
            }).setView([49.41812070066643, 30.563964843750004], 5);

            L.tileLayer(this.$store.state.tileApi, {
                maxZoom: 19,
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a>',
            }).addTo(this.map);

            this.markerGroup = L.layerGroup().addTo(this.map);

            // border Ukraine
            L.geoJSON(ukJSON, {
                fillColor: "#8417b1",
                color: "#8c13c1",
                opacity: 1,
                fillOpacity: 0.05
            }).addTo(this.map);
        },
    },
    computed: {
        computedMarkers() {
            return this.currentPosition ? [this.currentPosition] : this.markers;
        }
    },
    watch: {
        computedMarkers() {
            this.$nextTick(() => {
                this.renderMarkers();
            })
        }
    },
    async mounted() {
        setTimeout(() => {
            this.renderMap();
            this.renderMarkers();
            if (this.isMyDevice) {
                this.map.on("click", e => {
                    this.$emit('change', {
                        latitude: e.latlng.lat,
                        longitude: e.latlng.lng
                    });
                })
            }
        }, 200);
    }
}
</script>

<style lang="less" scoped>

#map-container {
    width: 100%;
    min-height: 100px;

    button {
        bottom: 1rem;
        left: 1rem;
        z-index: 1000000;
    }
}

</style>
