export default class TimeseriesItemObject {

    constructor(timeseriesItem) {
        this.timeseriesItem = timeseriesItem
    }

    getDate() {
        return this.timeseriesItem?.datetime;
    }

    getAvg() {
        return this.timeseriesItem?.avg;
    }

    getMax() {
        return this.timeseriesItem?.max;
    }

    getMin() {
        return this.timeseriesItem?.min;
    }

    getValue() {
        return this.timeseriesItem?.value;
    }

}
