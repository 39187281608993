<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 400px" role="document">
            <div class="modal-content app-modal-content">
                <div class="modal-header">
                    <h5 class="modal-title mx-auto position-absolute"
                        style="left: 50%; transform: translateX(-50%)">
                        {{ title }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-center">
                    {{ text }}
                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <button data-dismiss="modal" class="btn btn-secondary px-3">
                        <i class="fas fa-times mr-2"></i>
                        {{ buttonCanselText }}
                    </button>
                    <button @click="$emit('success')" data-dismiss="modal" class="btn btn-success px-3">
                        <i class="fas fa-check mr-2"></i>
                        {{ buttonSuccessText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $ from "jquery";
import i18n from "@/i18n";

export default {
    name: "ConfirmModal",
    props: {
        opened: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: i18n.t("Подтверждение")
        },
        text: {
            type: String,
            required: false,
            default: i18n.t("Подтверждаете действие?")
        },
        buttonCanselText: {
            type: String,
            required: false,
            default: i18n.t("Отмена")
        },
        buttonSuccessText: {
            type: String,
            required: false,
            default: i18n.t("Подтвердить")
        },
    },
    watch: {
        opened(val) {
            if (val) {
                $(this.$refs.modal).modal("show");
            } else {
                $(this.$refs.modal).modal("hide");
                this.$emit("closed");
            }
        },
    },
    mounted() {
        $(this.$refs.modal).on('hide.bs.modal', () => this.$emit("closed"));
    },
}
</script>

<style scoped>

</style>
