<template>
    <Layout>
        <div class="row">
            <div class="col-12 col-md-5 mb-5">
                <h4 class="title mb_40">
                    {{ $t('Профиль') }}
                </h4>
                <div class="app-card p-lg-5">
                    <div v-for="(item, index) in profileData"
                         v-if="item.name && item.value"
                         :key="index"
                         :class="{ 'border-bottom mb-4' : index !== profileData.length - 1 }"
                         class="pb-3">
                        <small class="mb-1 font-weight-bold text-black-50 d-block">{{ item.name }}</small>
                        <div class="font-weight-bold">{{ item.value }}</div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-7 mb-5">
                <h4 class="title mb_40">
                    {{ $t('Контакты') }}
                    <button @click="contactAddModal = true" class="btn btn-purple ml-3">
                        <i class="fas fa-plus fa-fw"></i>
                        <span class="d-none d-md-inline ml-2">{{ $t("Добавить контакт") }}</span>
                    </button>
                </h4>
                <div class="app-card p-lg-5">
                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-success mb-5 font-italic" style="font-weight: 500">
                                {{ $t('Здесь можно добавить свои контакты в профиль. Так же можно включить возможность авторизации, используя контакт как логин.') }}
                            </div>
                        </div>
                        <div class="col-12 mb-4">
                            <div class="card bg-light p-4">
                                <div v-if="!sortedContacts.length" class="text-center">
                                    <p class="text-center text-black-50 font-italic">
                                        {{ $t('Вы еще не добавляли контакты') }}
                                    </p>
                                    <button @click="contactAddModal = true" class="btn btn-purple">
                                        {{ $t("Добавить") }}
                                    </button>
                                </div>
                                <div v-else>
                                    <ContactItem v-for="(item, index) in sortedContacts"
                                                 :class="{ 'border-top' : index !== 0 }"
                                                 :key="index"
                                                 :contact="item"
                                                 v-show="sliceContacts ? index < 5 : true"
                                                 :disabled="processing"
                                                 class="pt-3 mb-4"
                                                 @update="processing = true"
                                                 @updated="processing = false"/>
                                </div>
                            </div>
                        </div>
                        <div v-if="contacts.length > 5" class="col-12 text-center">
                            <div @click="sliceContacts = !sliceContacts" class="btn btn-outline-purple">
                                <i v-if="sliceContacts" class="fas fa-chevron-down fa-fw"></i>
                                <i v-else class="fas fa-chevron-up fa-fw"></i>
                                {{ sliceContacts ? $t('Больше контактов') : $t('Меньше контактов') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ContactAddModal :opened="contactAddModal" @closed="contactAddModal = false"/>
    </Layout>
</template>

<script>

import _ from "underscore";
import types from "../store/types";
import {mapState} from "vuex";
import Icon from "../components/Icon";
import Layout from "../components/Layout";
import Helper from "../classess/Helper";
import ContactItem from "../components/ContactItem";
import ContactAddModal from "../components/modals/ContactAddModal";

export default {
    name: "Profile",
    components: {
        ContactItem,
        Layout,
        Icon,
        ContactAddModal
    },
    data: () => ({
        processing: false,
        sliceContacts: true,
        contactAddModal: false,
    }),
    methods:{
        parseAddressData(type) {
            return _.findWhere(this.currentObject.address, {type: type})?.name;
        },
    },
    computed: {
        ...mapState({
            user: state => state.currentUser,
            contacts: state => state.contacts,
            currentObject: state => state.currentObject,
            currentTheme: state => state.currentTheme,
        }),
        sortedContacts() {
            return _.sortBy(this.contacts, "id").reverse();
        },
        profileData() {
            let fullAddress = "";
            let streetType = _.find(this.currentObject.address, item => {
                return Helper.streetTypes().indexOf(item.type) > -1;
            })?.type;
            let streetTypeName = Helper.streetTypeHuman(streetType, false);

            let streetName = this.parseAddressData(streetType);
            let houseName = this.parseAddressData("house");
            let flatName = this.parseAddressData("flat");

            if (streetName) fullAddress += streetName;
            if (houseName) fullAddress += " " + houseName;
            if (flatName) fullAddress += " / " + flatName;

            return [
                {
                    name: this.$t('ID Аккаунта'),
                    value: this.currentObject.account_id
                },
                {
                    name: this.$t('Имя'),
                    value: this.user.first_name
                },
                {
                    name: this.$t('Фамилия'),
                    value: this.user.last_name
                },
                {
                    name: this.$t('Страна'),
                    value: _.findWhere(this.currentObject.address, {type: "country"})?.name
                },
                {
                    name: this.$t('Область'),
                    value: _.findWhere(this.currentObject.address, {type: "region"})?.name
                },
                {
                    name: this.$t('Район'),
                    value: _.findWhere(this.currentObject.address, {type: "area"})?.name
                },
                {
                    name: this.$t('Населенный пункт'),
                    value: _.findWhere(this.currentObject.address, {type: "locality"})?.name
                },
                {
                    name: streetTypeName.charAt(0).toUpperCase() + streetTypeName.slice(1),
                    value: fullAddress
                },
            ];
        },
        // ждем когда загрузятся все данные, что бы скрыть прелоадер
        allPageData() {
            return !_.isEmpty(this.user) && !_.isEmpty(this.currentObject);
        },
    },
    watch: {
        allPageData(val) {
            val && this.$store.commit(types.PRELOADER, false);
        }
    },
    mounted() {
        this.allPageData && this.$store.commit(types.PRELOADER, false);
    }
}
</script>

<style scoped lang="less">

</style>
