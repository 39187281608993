<template>
    <div>
        <button type="button" class="btn btn-purple float-right" data-toggle="dropdown">
            <Icon
                class="sidebar-icon-fix"
                icon-name="settings"
                fill="#ffffff"
                width="22"
                height="22"
            />
        </button>
        <div class="dropdown-menu app-dropdown device-settings-dropdown mt-2 mr-2" style="max-width: 415px;" @click.stop>
            <div class="row">
                <div class="col-12">
                    <div class="app-dropdown-title font-weight-bold">{{ $t('Настройки') }}</div>
                    <hr style="margin: 10px -20px">
                    <div class="row d-flex align-items-center">
                        <div class="col">
                            {{ $t('Компактный вид карточек') }}
                        </div>
                        <div class="col-auto">
                            <toggle-button :sync="true"
                                           @click.native.stop
                                           :color="toggleColor"
                                           :value="deviceCardCompactMode"
                                           style="top: 5px"
                                           @change="e => this.$store.commit(types.DEVICE_CARD_COMPACT_MODE, e.value)"/>
                        </div>
                    </div>
                    <hr style="margin: 10px -20px">
                    <div class="row d-flex align-items-center">
                        <div class="col">
                            {{ $t('Отображение названия') }}
                        </div>
                        <div class="col-auto">
                            <toggle-button :sync="true"
                                           @click.native.stop
                                           :color="toggleColor"
                                           :value="deviceNameOnCard"
                                           style="top: 5px"
                                           @change="e => this.$store.commit(types.DEVICE_NAME_ON_CARD, e.value)"/>
                        </div>
                    </div>
                </div>
                <!--                <div class="col-12 mb-4">-->
                <!--                    <div class="row d-flex align-items-center">-->
                <!--                        <div class="col">-->
                <!--                            {{ $t('Подсветка карточек с инцидентами') }}-->
                <!--                        </div>-->
                <!--                        <div class="col-auto">-->
                <!--                            <toggle-button :sync="true"-->
                <!--                                           @click.native.stop-->
                <!--                                           :width="60"-->
                <!--                                           :height="30"-->
                <!--                                           :color="toggleColor"-->
                <!--                                           :value="deviceCardAccidentsHighlight"-->
                <!--                                           @change="e => this.$store.commit(types.DEVICE_CARD_ACCIDENTS_HIGHLIGHT, e.value)"/>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="col-12">
                    <hr style="margin: 10px -20px">
                    <label>{{ $t('Дизайн карточек интерфейса') }}</label>
                    <div class="d-flex align-items-start mt-1">
                        <div class="pr-3 d-flex flex-column align-items-center"
                             v-for="(design, index) in availableDesigns"
                             :key="index"
                             @change="setDesign(selectedDesign)"
                        >
                            <input class="btn-check mb-3 d-none"
                                   type="radio"
                                   name="design"
                                   :id="'radio' + index"
                                   :value="index + 1"
                                   :checked="index + 1 === selectedDesign"
                                   v-model="selectedDesign"
                            >
                            <label
                                class="cursor-pointer"
                                :for="'radio' + index"
                            >
                                <span class="d-block shadow" :class="index + 1 === selectedDesign ? 'active-design' : 'design'">
                                    <img width="110px" height="170px" :src="require('@/assets/cardDesign'+`${index + 1}`+'.png')" alt="">
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <!--                <div class="col-12" >-->
                <!--                    <div class="d-flex align-items-center">-->
                <!--                        <div class="input-group-prepend">-->
                <!--                            <label class="input-group-text" for="inputGroupSelect">{{ $t('Дизайн') }}</label>-->
                <!--                        </div>-->
                <!--                        <select class="custom-select"-->
                <!--                                id="inputGroupSelect"-->
                <!--                                v-model="selectedDesign"-->
                <!--                                @change="setDesign(selectedDesign)"-->
                <!--                        >-->
                <!--                            <option v-for="(design, index) in availableDesigns" :key="index"  :value="design.value">{{design.text}}</option>-->
                <!--                        </select>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import config from "../config";
import Icon from "./Icon";
import {mapState} from "vuex";
import types from "../store/types";

export default {
    name: "DevicesSettingsDropdown",
    components: {
        Icon
    },
    data: () => ({
        selectedDesign: null
    }),
    methods: {
        setDesign(value) {
            this.$store.commit(types.SET_CARD_DESIGN, value);
        }
    },
    computed: {
        ...mapState({
            deviceCardCompactMode: state => state.DevicesSettingsDropdown.deviceCardCompactMode,
            deviceNameOnCard: state => state.DevicesSettingsDropdown.deviceNameOnCard,
            deviceCardAccidentsHighlight: state => state.DevicesSettingsDropdown.deviceCardAccidentsHighlight,
            availableDesigns: state => state.DevicesSettingsDropdown.availableDesigns,
            currentCardDesign: state => state.DevicesSettingsDropdown.currentCardDesign
        }),
        toggleColor() {
            return config.toggleColor;
        },
        types() {
            return types;
        },
    },
    created() {
        this.selectedDesign = this.currentCardDesign
    }
}
</script>

<style scoped lang="less">

@media (max-width: 768px) {
    .device-settings-dropdown {
        max-width: 95vw !important;
        overflow-y: auto;
    }
}

.design {
    height: 174px;
    max-width: 114px;
    padding: 2px;
    position: relative;
}

.active-design {
    height: 174px;
    max-width: 114px;
    padding: 2px;
    position: relative;
    background: linear-gradient(#A900FE 0%, #E657FF 50.52%, #F66EFF 100%);
    border-radius: 5px;
}

</style>
