<template>
    <div class="row">
        <div class="col-auto mb-4">
            <div class="card py-3 px-4 d-flex justify-content-between align-items-center flex-row">
                <div class="mr-4 pr-4 border-right">
                    <small class="d-block text-muted">
                        <i class="fas fa-filter"></i>
                        {{ $t('Применен фильтр') }}
                    </small>
                    <small v-if="deviceName" class="d-block">
                        {{ $t('Устройство: ') }} <strong>{{ deviceName }}</strong>
                    </small>
                    <small v-if="alarmTypeName" class="d-block">
                        {{ $t('Авария: ') }} <strong>{{ alarmTypeName }}</strong>
                    </small>
                </div>
                <button @click="$router.push({name: 'Accidents'})" class="btn btn-purple btn-sm">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "AccidentsFilterButton",
    props: {
        deviceId: {
            required: false,
            default: null
        },
        alarmId: {
            required: false,
            default: null
        },
        accidents: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapState({
            devices: state => state.devices
        }),
        device() {
            return this.devices.find(item => item.id === this.deviceId);
        },
        deviceName() {
            return this.device?.name;
        },
        alarmTypeName() {
            return this.accidents.find(item => item.alarm.id === this.alarmId * 1)?.alarm?.title;
        }
    },
}
</script>

<style scoped>

</style>
