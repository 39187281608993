<script>
import Default from "./Default";
import DoubleRelayTable from "./DoubleRelayTable";


export default {
    name: "DoubleRelay",
    extends: Default,
    components: {
        DefaultLineChart: DoubleRelayTable,
        DefaultTable: DoubleRelayTable
    },
}
</script>
