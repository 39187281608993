import Request from "../classess/Request";
import AbstractService from "./AbstractService";
import {EventBus, events} from "../EventBus";
import types from "../store/types";

export default class AuthService extends AbstractService {

    constructor() {
        super();
        this.request = new Request();
    }

    async login(login, password) {

        const {code, body} = await this.request.post(this.config.apiUrl + "/auth", {
            login,
            password
        })

        if (code === 200) {
            this.store.commit(types.SET_TOKEN, body.token);
            this.store.commit(types.SET_REFRESH, body.refresh);
            EventBus.$emit(events.AUTHORIZED);
            return true;
        }

        return false;
    }

    async logout() {
        //clear credentials
        await this.store.commit(types.SET_TOKEN, "");
        await this.store.commit(types.SET_REFRESH, "");
    }
}
