<template>
    <div>
        <div class="row">
            <h5 class="col-12 font-weight-bold pb-2">
                <Icon :iconName="metricObject.getIcon()"
                      :fill="metricObject.getColor()"
                      width="32"
                      height="32"
                      class="mr-3"/>
                <span>{{ metricObject.getName() }}</span>
                <span v-if="metricObject.getPortIndex() !== null"> #{{ metricObject.getPortIndex() + 1 }}</span>
                <span v-if="metricPort !== null"> ( port: {{ metricPort }} )</span>
            </h5>
            <div class="col-12">
                <div class="empty-chart-box">
                    <span>{{ $t('Нет данных') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import MetricObject from "../../objects/MetricObject";
import Icon from "../Icon";

export default {
    name: "Empty",
    components: {
        Icon
    },
    props: {
        metricPort: {
            type: Number,
            required: false,
            default: null
        },
        metricObject: {
            type: MetricObject,
            required: true
        }
    },
}
</script>

<style lang="less" scoped>

.empty-chart-box {

    background: #efefef;
    width: 100%;
    height: 200px;
    border-radius: .5rem;
    position: relative;

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #a9a9a9;
    }
}

</style>
