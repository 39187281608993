<template>
    <div id="map"></div>
</template>

<script>

import "leaflet";
import "leaflet-fullscreen";
import "leaflet.markercluster";
import ukJSON from "../assets/ukraine.geo.json";
import $ from "jquery";
import Color from "../classess/Color";
import Helper from "../classess/Helper"
import {mapState} from "vuex";

export default {
    name: "DevicesMap",
    props: {
        devices: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            mapHeight: null,
            map: null,
            currentDevice: null,
            icons: {
                danger: require("../assets/marker-icon-2x-red.png"),
                info: require("../assets/marker-icon-2x-orange.png"),
                primary: require("../assets/marker-icon-2x-blue.png"),
                success: require("../assets/marker-icon-2x-green.png"),
            }
        }
    },
    methods: {
        getMetricTimeseries(devEui, metricSlug, metricPortIndex = 0) {
            const deviceMetrics = this.cardsTimeseriesCache.find(item => item.devEui === devEui);

            if (!deviceMetrics) {
                return null;
            }

            const metrics = deviceMetrics.timeseries.filter(item => item.getSlug() === metricSlug);

            return metrics[metricPortIndex || 0];
        },
        createMarker(device) {
            let html = '';
            let offset = 0;

            device.getType().excludeInternalMetrics().getMetricsWithPorts().forEach(metric => {

                const metricData = this.getMetricTimeseries(device.getDevEui(), metric.getSlug(), metric.getPortIndex());
                const metricLatestValue = metricData?.getLatestData()?.getValue() || "n/a";
                const metricPortIndex = metric.getPortIndex() !== null ? " #" + (metric.getPortIndex() + 1) : "";

                // language=HTML
                html += `
                    <div class="d-flex justify-content-center align-items-center marker" style="margin-left: -130px;">
                            <span class="font-weight-bold app-marker-name" style="
                                color: ${metric.getColor()};
                                border: 1px solid ${metric.getColor()};">
                                <div style="min-width: 200px">
                                    ${Helper.stringLimit(device.getName(), 30)}
                                    <hr>
                                    ${metric.getName()}
                                    ${metricPortIndex}
                                </div>
                                <span class="arrow-right" style="border-left-color: ${metric.getColor()};"></span>
                            </span>
                        <div class="app-marker w-100" style="
                                    top: ${-offset}px;
                                    left: ${-offset}px;
                                    background: ${metric.getColor()};
                                    border: 1px solid ${Color.adjust(metric.getColor(), -20)};">
                            <span class="d-block font-weight-bold">${metricLatestValue}</span>
                            <small class="d-block">${metric.getUnits('-')}</small>
                        </div>
                    </div>
                `
                offset += 2;
            });

            return new L.Marker([device.getLatitude(), device.getLongitude()], {
                riseOnHover: true,
                icon: new L.DivIcon({
                    iconSize: 45,
                    className: 'app-marker-wrapper',
                    iconAnchor: [45, 45],
                    html: html || `<div class="app-marker-empty">N/A</div>`
                })
            }).setZIndexOffset(html.length ? 20 : 10);
        },
        turnMarkerMetrics(e) {
            let wrapper = $(e.originalEvent.target).hasClass('app-marker-wrapper')
                ? $(e.originalEvent.target)
                : $(e.originalEvent.target).parents('.app-marker-wrapper');

            let offset = 0;

            wrapper.find('.app-marker').each(function () {
                $(this).css({
                    top: offset,
                    left: offset
                });

                offset -= 2;
            });
        },
        expandMarkerMetrics(e) {
            let wrapper = $(e.originalEvent.target).hasClass('app-marker-wrapper')
                ? $(e.originalEvent.target)
                : $(e.originalEvent.target).parents('.app-marker-wrapper');

            let startOffset = (wrapper.find('.app-marker').length - 1) * 45;
            let startOffsetName = (wrapper.find('.app-marker-name').length - 1) * 45;
            wrapper.find('.app-marker').each(function () {
                $(this).css({
                    top: -startOffset,
                    left: 0
                });

                startOffset -= 45;
            });
            wrapper.find('.app-marker-name').each(function () {
                $(this).css({
                    top: -startOffsetName,
                    left: -80
                }).attr("data-content", "qwerqrtt");

                startOffsetName -= 45;
            });
        },
        renderMap() {
            let markersList = [];

            this.map = L.map('map', {
                scrollWheelZoom: window.outerWidth > 768,
                fullscreenControl: true,
            }).setView([47.8421933, 31.6945746], 6);

            L.tileLayer(this.$store.state.tileApi, {
                maxZoom: 19,
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a>',
            }).addTo(this.map);

            // border Ukraine
            L.geoJSON(ukJSON, {
                fillColor: "#8417b1",
                color: "#8c13c1",
                opacity: 1,
                fillOpacity: 0.05
            }).addTo(this.map);

            // add markers (devices)
            this.devices.forEach(device => {

                const marker = this.createMarker(device)

                marker.on("click", () => {
                    this.$router.push({name: "Device", params: {id: device.getId()}})
                });

                marker.on('mouseover', this.expandMarkerMetrics);
                marker.on('mouseout', this.turnMarkerMetrics);

                this.map.addLayer(marker);
                markersList.push(marker);
            });

            if (markersList.length) {
                let group = new L.featureGroup(markersList);
                this.map.fitBounds(group.getBounds().pad(window.outerWidth <= 768 ? 1 : 0.7));
            }
        },
    },
    computed: {
        ...mapState({
            cardsTimeseriesCache: state => state.cardsTimeseriesCache
        })
    },
    watch: {
        devices() {
            this.renderMap();
            this.$emit("ready");
        }
    },
    mounted() {
        this.renderMap();
        this.$emit("ready");
    }

}
</script>

<style lang="less">

@import "../less/_variables";

#map {
    width: 100%;
    height: calc(100vh - (@navbar-height + 120px));
    box-sizing: border-box;
    border-radius: 0.5rem;
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.1);

    @media (max-width: 768px) {
        height: calc(100vh - 250px);
    }
}

.app-marker-wrapper {
    position: relative;
}

.marker {
    &:hover {
        .app-marker-name {
            display: block;
        }
    }
}

.app-marker {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    //background: tint(#8417b1, 20);
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    //border: 1px solid #8417b1;
    font-size: 11px;

    &-name {
        display: none;
        position: relative;
        z-index: 999;
        background: #FFFFFF99;
        padding: 5px;
        border-radius: 5px;
        text-align: center;
    }
}

.arrow-right {
    border: solid transparent;
    position: absolute;
    left: 100%;
    top: 12px;
    border-width: 9px;
}

.app-marker-empty {
    .app-marker();
    background: #ff0000;
}

</style>
