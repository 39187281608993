import DeviceTypeObject from "./DeviceTypeObject";

export default class DeviceObject {

    constructor(device = {}) {
        this.device = device;
    }

    getName() {
        return this.device?.name;
    }

    /**
     * @returns {DeviceTypeObject}
     */
    getType() {
        return new DeviceTypeObject(this.device?.type);
    }

    getAccountId() {
        return this.device?.owner_account_id;
    }

    getDescription() {
        return this.device?.description;
    }

    getDevEui() {
        return this.device?.dev_eui;
    }

    getIRI() {
        return this.device["@id"];
    }

    getId() {
        return this.device?.id;
    }

    getLastUpstreamDate() {
        return this.device?.last_upstream_date;
    }

    getLongitude() {
        return this.device?.longitude;
    }

    getLatitude() {
        return this.device?.latitude;
    }

    hasActiveAccidents() {
        return this.device?.has_active_accident;
    }

    getAltitude() {
        return this.device?.altitude;
    }

    getRaw() {
        return this.device;
    }

    isMyDevice() {
        return this.device?.owner_account_id === this.device?.account_id;
    }
}

