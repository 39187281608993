import RequestWithAuth from "../classess/RequestWithAuth";
import config from "../config";
import store from "../store";
import i18n from "../i18n";

export default class AbstractService {

    constructor() {
        this.request = new RequestWithAuth(config.apiUrl);
        this.store = store;
        this.config = config;
    }

    parseId(stringId) {
        const array = stringId.split("/");
        return array?.length ? array.reverse()[0] : null;
    }

    parseValidationErrorMessage(responseBody, fallbackMessage = i18n.t("Ошибка!")) {
        if (responseBody?.["@type"] === "ConstraintViolationList") {
            return responseBody?.["hydra:description"] || fallbackMessage;
        }

        return fallbackMessage;
    }

}
