import moment from "moment-mini";
import i18n from "../i18n";

export default class Helper {

    static keyboardConverter(str) {
        const s = [
            "й", "ц", "у", "к", "е", "н", "г", "ш", "щ", "з", "х", "ъ",
            "ф", "ы", "в", "а", "п", "р", "о", "л", "д", "ж", "э",
            "я", "ч", "с", "м", "и", "т", "ь", "б", "ю"
        ];

        const r = [
            "q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "\\[", "\\]",
            "a", "s", "d", "f", "g", "h", "j", "k", "l", ";", "'",
            "z", "x", "c", "v", "b", "n", "m", ",", "\\."
        ];

        for (let i = 0; i < r.length; i++) {
            const reg = new RegExp(r[i], 'mig');
            str = str.replace(reg, function (a) {
                return a == a.toLowerCase() ? s[i] : s[i].toUpperCase();
            });
        }

        return str;
    }

    static stringLimit(str, limit = 10, end = "...") {
        if (typeof str === "string" && str.length > limit) {
            str = str.substring(0, limit) + end;
        }
        return str;
    }

    static wordForms(n, textForms) {
        n = Math.abs(n) % 100;
        let n1 = n % 10;
        if (n > 10 && n < 20) {
            return textForms[2];
        }
        if (n1 > 1 && n1 < 5) {
            return textForms[1];
        }
        if (n1 == 1) {
            return textForms[0];
        }
        return textForms[2];
    }

    static stripTags(html) {
        let div = document.createElement("div");
        div.innerHTML = html;
        return div.innerText;
    }

    static isDateBetween(_start = null, _stop = null) {
        let start = moment(_start).isValid() ? moment(_start) : null;
        let stop = moment(_stop).isValid() ? moment(_stop) : null;
        let server_date = moment();

        // даты не указаны
        if (!start && !stop) {
            return true;
        }

        // указана только дата старта
        if (start && !stop) {
            return server_date.isSameOrAfter(start);
        }

        // указана только дата стопа
        if (!start && stop) {
            return server_date.isBefore(stop);
        }

        // указаны обе даты
        if (start && stop) {
            return server_date.isSameOrAfter(start) && server_date.isBefore(stop);
        }
    }

    static streetTypeHuman(streetType, short = true) {

        const fullStreetTypes = {
            street: i18n.t('улица'),
            avenue: i18n.t('проспект'),
            square: i18n.t('площадь'),
            lane: i18n.t('проулок'),
            alley: i18n.t('аллея'),
            boulevard: i18n.t('бульвар'),
            pass: i18n.t('переулок'),
            road: i18n.t('шоссе'),
            blind_alley: i18n.t('тупик'),
            housing_area: i18n.t('жилой массив'),
            garden_array: i18n.t('садовый массив'),
            entry: i18n.t('въезд')
        };

        const shortStreetTypes = {
            street: i18n.t('ул.'),
            avenue: i18n.t('пр-т.'),
            square: i18n.t('пл.'),
            lane: i18n.t('пер.'),
            alley: i18n.t('ал.'),
            boulevard: i18n.t('б-р.'),
            pass: i18n.t('пер.'),
            road: i18n.t('ш.'),
            blind_alley: i18n.t('т.'),
            housing_area: i18n.t('жил.мас.'),
            garden_array: i18n.t('сад.мас.'),
            entry: i18n.t('въезд')
        };

        return (short ? shortStreetTypes[streetType] : fullStreetTypes[streetType]) || "";
    }

    static streetTypes() {
        return [
            "street",
            "avenue",
            "square",
            "lane",
            "alley",
            "boulevard",
            "pass",
            "road",
            "blind_alley",
            "housing_area",
            "garden_array",
            "entry"
        ]
    }
    static beautifyPhoneNumber(phone) {
        phone = '' + phone;
        let newPhone = phone.replace(/\s+/g, '');
        newPhone.substr(newPhone.length - 10);
        return newPhone.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})/, '+38 ($1) $2-$3-$4');
    }
}