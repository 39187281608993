<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div ref="modalDialog" class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content app-modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Редактирование доступа к устройству') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div v-if="opened" class="modal-body">
                    <div v-if="showDevicesList" class="form-group">
                        <label class="font-weight-bold text-muted">
                            {{ $t('Устройство') }}
                            <strong class="text-danger">*</strong></label>
                        <select :value="currentDevice.id" class="form-control" disabled>
                            <option v-for="item in devices" :key="item.id" :value="item.id">
                                {{ item.name + " - " + item.id }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold text-muted">
                            {{ $t('Аккаунт ID') }}
                            <strong class="text-danger">*</strong></label>
                        <input v-model="access.account_id" type="number" readonly class="form-control">
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold text-muted d-block">
                            {{ $t('На сколько открываем доступ?') }} <strong class="text-danger">*</strong>
                        </label>
                        <date-picker v-model="accessPeriod"
                                     type="date"
                                     class="w-100"
                                     range
                                     :disabled-date="isDisabledDate"
                                     :lang="$store.state.appLocale"
                                     input-class="form-control"
                                     :placeholder="$t('Выбрать диапазон дат')">
                        </date-picker>
                    </div>
                    <div class="form-group">
                        <label class="font-weight-bold text-muted d-block">
                            {{ $t('К каким метрикам даем доступ?') }} <strong class="text-danger">*</strong>
                        </label>
                        <div class="card pt-4 px-4 bg-light mb-2">
                            <div class="row">
                                <template v-for="item in deviceMetrics">
                                    <div class="col-9 mb-4">
                                        <span>{{ item.name | stringLimit }}</span>
                                        <span class="text-muted" v-if="item.port"> PORT: {{ item.port }}</span>
                                    </div>
                                    <div class="col-3 text-right">
                                        <toggle-button :value="isChecked(item)"
                                                       :color="toggleColor"
                                                       :disabled="processing || (isChecked(item) && countCheckedMetrics < 2)"
                                                       @change="(e) => onChangeToggleHandler(e, item)"/>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <small class="text-muted font-italic">
                            {{ $t('Необходимо выбрать минимум одну метрику') }}
                        </small>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="update"
                            :disabled="processing || !isValid"
                            style="min-width: 110px;"
                            class="btn btn-purple">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>{{ $t('Сохранить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import moment from "moment-mini";
import _ from "underscore";
import $ from "jquery";
import {mapState} from 'vuex';
import DatePicker from "vue2-datepicker";
import DeviceAccessService from "../../services/DeviceAccessService";
import DeviceMetricAccessService from "../../services/DeviceMetricAccessService";
import Helper from "../../classess/Helper";
import config from "../../config";
import DeviceObject from "../../objects/DeviceObject";

const DeviceAccess = new DeviceAccessService();
const DeviceMetricAccess = new DeviceMetricAccessService()

export default {
    name: "DeviceAccessUpdateModal",
    components: {
        DatePicker,
    },
    props: {
        opened: Boolean,
        access: {
            type: Object,
            required: false,
        },
        showDevicesList: {
            type: Boolean,
            required: false
        }
    },
    filters: {
        stringLimit: str => Helper.stringLimit(str, 25)
    },
    data() {
        return {
            processing: false,
            accessPeriod: [],
            currentAccess: {
                id: null,
                start: null,
                stop: null,
            },
        }
    },
    methods: {
        isChecked(metric) {
            return !!_.find(this.access?.metrics, item => {
                return item.port === metric.port && item.name === metric.key;
            });
        },
        async onChangeToggleHandler(e, item) {
            this.processing = true;

            if (e.value) {
                await DeviceMetricAccess.create(item.key, item.port, this.access['@id']);
            } else {
                await DeviceMetricAccess.remove(item.id);
            }

            const result = await DeviceAccess.get(this.currentAccess.id);

            this.processing = false;

            this.$toast.success(this.$t('Параметры обновлены'));
            this.$emit('updated', result);
        },
        isDisabledDate(date) {
            return moment(date).isBefore(moment().subtract(1, "day"));
        },
        async update() {
            this.processing = true;
            const result = await DeviceAccess.update(this.currentAccess);
            this.processing = false;

            if (result !== false) {
                this.$toast.success(this.$t('Параметры обновлены'));
                this.$emit('updated', result);
            } else {
                this.$toast.error(this.$t('Ошибка!'));
            }
        }
    },
    computed: {
        ...mapState({
            devices: state => state.devices
        }),
        toggleColor() {
            return config.toggleColor;
        },
        countCheckedMetrics() {
            return _.filter(this.deviceMetrics, item => {
                return this.isChecked(item);
            })?.length;
        },
        currentDevice() {
            return _.find(this.devices, item => item['@id'] === this.access.device);
        },
        deviceObject() {
            return new DeviceObject(this.currentDevice);
        },
        deviceMetrics() {
            return this.deviceObject.getType().getMetrics().map(item => {

                const id = _.find(this.access.metrics, metric => {
                    return metric.name === item.getSlug();
                })

                return {
                    id: id ? id['@id'].split('/').reverse()[0] : null,
                    key: item.getSlug(),
                    name: item.getName(),
                    port: null
                }
            })
        },
        isValid() {
            return this.currentAccess.start?.length > 0
                && this.currentAccess.stop?.length > 0;
        },
    },
    watch: {
        accessPeriod(val) {
            this.currentAccess.start = moment(val[0]).startOf("day").format();
            this.currentAccess.stop = moment(val[1]).endOf("day").format();
        },
        opened(val) {
            if (val) {
                $(this.$refs.modal).modal("show");

                this.currentAccess.start = this.access.start;
                this.currentAccess.stop = this.access.stop;
                this.currentAccess.id = this.access['@id'].split('/').reverse()[0];
                this.accessPeriod = [new Date(this.access.start), new Date(this.access.stop)];

            } else {
                Object.assign(this.$data, this.$options.data());
                $(this.$refs.modal).modal("hide");
                this.$emit("closed");
            }
        },
    },
    mounted() {
        $(this.$refs.modal).on('hide.bs.modal', () => this.$emit("closed"));
    },
}
</script>

<style scoped>

</style>
