<template>
    <Icon v-if="voltage"
          icon-name="battery"
          width="30"
          height="30"
          :fill="currentCardDesign === 2 ? 'white' : '#B9B9BA'"
          :title="tooltipText"
          data-toggle="tooltip"
          data-placement="top" />
</template>

<script>

import Icon from "../../Icon";
import {mapState} from "vuex";
import DeviceObject from "../../../objects/DeviceObject";
import moment from "moment-mini";
import TimeAgo from "javascript-time-ago";
import ru from 'javascript-time-ago/locale/ru.json';
import uk from 'javascript-time-ago/locale/uk.json';

export default {
    name: "DefaultCard_1HeaderBattery",
    components: {
        Icon
    },
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true,
        },
        timeseries: {
            type: Array,
            required: false
        },
    },
    computed: {
        ...mapState({
            currentCardDesign: state => state.DevicesSettingsDropdown.currentCardDesign,
        }),
        voltage() {
            const batVoltStat = this.timeseries.find(item => item.getSlug() === "bat_volt")?.getLatestData()?.getValue();
            return batVoltStat ? batVoltStat : null;
        },
        lastUpdate() {
            const date = this.timeseries.find(item => item.getSlug() === "bat_volt")?.getLatestData()?.getDate();

            if (moment(date).isValid()) {
                const timeAgo = new TimeAgo(this.$i18n.locale);
                return this.$t("Обновлено") + " " + timeAgo.format(new Date(date));
            }

            return "";
        },
        tooltipText() {
            return this.$t('Напряжение батареи:') + ` ${this.voltage}V ${this.lastUpdate}`;
        }
    },
    created() {
        TimeAgo.addLocale(ru);
        TimeAgo.addLocale(uk);
    },
}
</script>

<style scoped>

</style>
