<script>
import Default from "./Default";
import ValveStatusTable from "./ValveStatusTable";

export default {
    name: "Valve",
    extends: Default,
    components: {
        DefaultLineChart: ValveStatusTable,
        DefaultTable: ValveStatusTable
    },
}
</script>
