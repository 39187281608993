<template>
    <div>
        <DefaultHead :metricPort="metricPort"
                     :metricTimeseries="metricTimeseries"
                     :deviceObject="deviceObject"
                     :typeMetricValues="typeMetricValues"
                     :metricObject="metricObject"/>
        <div class="row">
            <div class="col-12 overflow-auto">
                <DefaultTable v-if="isTabledMetric"
                              :metricTimeseries="metricTimeseries"
                              :timeseries="timeseries"
                              :metricObject="metricObject"
                              :style="lineChartStyle"
                              :selectedPeriod="selectedPeriod"/>


                <DefaultLineChart v-else
                                  :metricTimeseries="metricTimeseries"
                                  :timeseries="timeseries"
                                  :metricObject="metricObject"
                                  :style="lineChartStyle"
                                  :selectedPeriod="selectedPeriod"/>
            </div>
        </div>
    </div>
</template>

<script>

import MetricObject from "../../objects/MetricObject";
import DeviceObject from "../../objects/DeviceObject";
import DefaultLineChart from "./DefaultLineChart";
import DefaultTable from "./DefaultTable";
import DefaultHead from "./DefaultHead";
import metricsConfig from "../../metricsConfig";

export default {
    name: "Default",
    components: {
        DefaultHead,
        DefaultLineChart,
        DefaultTable
    },
    props: {
        metricPort: {
            type: Number,
            required: false,
            default: null
        },
        metricObject: {
            type: MetricObject,
            required: true
        },
        typeMetricValues: {
            type: Array,
            required: false
        },
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        metricTimeseries: {
            required: true
        },
        timeseries: {
            required: true
        },
        selectedPeriod: {
            type: Object,
            required: true
        },
    },
    computed: {
        isTabledMetric() {
            const deviceTypeSlug = this.deviceObject.getType().getSlug();
            return metricsConfig[deviceTypeSlug]?.tabledMetrics?.indexOf(this.metricObject?.getSlug()) > -1
        },
        lineChartStyle() {
            return {
                height: '350px'
            }
        }
    },
}
</script>

<style scoped>

</style>
