<template>
    <div id="app">
        <Preloader/>
        <SimplePreloader/>
        <router-view v-if="isInit"/>
    </div>
</template>

<script>

import "bootstrap"
import $ from "jquery";
import Preloader from "./components/Preloader";
import SimplePreloader from "./components/SimplePreloader";
import DeviceService from "./services/DeviceService";
import TypeService from "./services/TypeService";
import ObjectService from "./services/ObjectService";
import ContactService from "./services/ContactService";
import UserService from "./services/UserService";
import types from "./store/types";
import {EventBus, events} from "./EventBus";
import {mapState} from "vuex";
import moment from "moment-mini";
import TypeMetricValueService from "./services/TypeMetricValueService";

const Device = new DeviceService();
const Type = new TypeService();
const Obj = new ObjectService();
const Contacts = new ContactService();
const User = new UserService();
const TypeMetricValue = new TypeMetricValueService();

export default {
    components: {
        Preloader,
        SimplePreloader
    },
    data() {
        return {
            isInit: false
        }
    },
    methods: {
        async init() {
            this.$store.commit(types.PRELOADER, true);
            this.setTheme();
            await Obj.fetchAndStore();
            await Device.fetchAndStore();
            await Type.fetchAndStore();
            await Contacts.getAndStore();
            await User.getAndStore();
            await TypeMetricValue.fetchAndStore();
            this.$store.commit(types.PRELOADER, false);
            this.isInit = true;
        },
        setTheme() {
            switch (this.appTheme) {
                case 'light':
                    this.$store.commit(types.SET_APP_THEME_COLOR, '#64008D');
                    $('body').removeClass('theme-dark');
                    break;
                case 'dark':
                    this.$store.commit(types.SET_APP_THEME_COLOR, '#301934');
                    $('body').addClass('theme-dark');
                    break;
                case 'auto':
                    if (moment().format('HH') >= 7 && moment().format('HH') < 21) {
                        this.$store.commit(types.SET_APP_THEME_COLOR, '#64008D');
                        $('body').removeClass('theme-dark');

                    } else {
                        this.$store.commit(types.SET_APP_THEME_COLOR, '#301934');
                        $('body').addClass('theme-dark');
                    }
                    break;
            }
        }
    },
    computed: {
        ...mapState({
            appLocale: state => state.appLocale,
            appTheme: state => state.appTheme,
        })
    },
    watch: {
        appLocale(locale) {
            this.$i18n.locale = locale;
        },
        appTheme: "setTheme"
    },
    async mounted() {
        $(window).resize(() => {
            if ($(window).width() <= 768) {
                this.$store.commit(types.SIDEBAR_COLLAPSED, true);
            }
        });

        $(window).resize();

        EventBus.$on(events.UNAUTHORIZED, async () => {
            setTimeout(async () => {
                if (this.$route.name !== 'Auth') {
                    await this.$router.push({name: 'Auth'});
                }
            }, 300);
        });

        EventBus.$on(events.AUTHORIZED, async () => {
            await this.$nextTick();
            await this.init();
        });

        await this.init();

        $('body').tooltip({
            selector: '[data-toggle="tooltip"]'
        });
    }
}

</script>

<style lang="less">

@import "~bootstrap/dist/css/bootstrap.min.css";
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import './less/app';
@import "./less/_variables";

// leaflet
@import "../node_modules/leaflet/dist/leaflet.css";
@import "../node_modules/leaflet-fullscreen/dist/leaflet.fullscreen.css";
@import "../node_modules/leaflet.markercluster/dist/MarkerCluster.css";
@import "../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";

html, body {
    font-family: 'Montserrat', sans-serif !important;
}

#app {
    position: relative;
    min-height: 100vh;
}

.v-toast {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.v-toast__text {
    min-width: 250px;
}

</style>
