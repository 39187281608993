<template>
    <div class="row">
        <div class="col-12 font-weight-bold pb-2 d-flex align-items-center justify-content-between">
            <h5>
                <Icon :iconName="metricObject.getIcon()"
                      :fill="metricObject.getColor()"
                      width="32"
                      height="32"
                      class="mr-3"/>
                <span>{{ metricObject.getName() }}</span>
                <span v-if="metricObject.getPortIndex() !== null"> #{{ metricObject.getPortIndex() + 1 }}</span>
                <span v-if="metricPort !== null"> ( port: {{ metricPort }} )</span>
            </h5>
            <div v-if="metricHaveCoefficient && isCurrentUserDevice">
                <button class="btn btn-purple" @click.prevent="$eventBus.emit('DEVICE_COEFFICIENT_MODAL_OPEN', {deviceObject: deviceObject, metric: metricObject})">
                    <i class="fas fa-percent pr-4 text-primary fa-fw"></i>
                    {{ $t('Применен поправочный коэффициент') }}
                </button>
            </div>
        </div>
        <div class="col-12 values-list">
            <small v-if="metricMin!== null" class="d-inline-block mr-4 mb-3">
                {{ $t('Минимальное значение') }}
                <small class="text-muted font-weight-bold">{{ metricMin }} {{ metricObject.getUnits() }}</small>
            </small>
            <small v-if="metricAvg !== null" class="d-inline-block mr-4 mb-3">
                {{ $t('Среднее значение') }}
                <small class="text-muted font-weight-bold"> - {{ metricAvg }} {{ metricObject.getUnits() }}</small>
            </small>
            <small v-if="metricMax !== null" class="d-inline-block mr-4 mb-3">
                {{ $t('Максимальное значение') }}
                <small class="text-muted font-weight-bold"> - {{ metricMax }} {{ metricObject.getUnits() }}</small>
            </small>
        </div>
    </div>
</template>

<script>
import MetricObject from "../../objects/MetricObject";
import Icon from "../Icon";
import DeviceObject from "../../objects/DeviceObject";
import {mapState} from "vuex";

export default {
    name: "DefaultHead",
    components: {
        Icon
    },
    props: {
        metricPort: {
            type: Number,
            required: false,
            default: null
        },
        metricObject: {
            type: MetricObject,
            required: true
        },
        metricTimeseries: {
            required: true
        },
        typeMetricValues: {
            type: Array,
            required: false
        },
        deviceObject: {
            type: DeviceObject,
            required: true
        },
    },
    computed: {
        ...mapState({
            currentObject: state => state.currentObject,
        }),
        metricHaveCoefficient() {
            return this.typeMetricValues.filter(item => item.type_metric === this.metricObject?.getId()).length > 0
        },
        metricMin() {
            let minValues = this.metricTimeseries?.getData()?.map(item => item.getMin());
            let result = minValues?.length ? Math.min.apply(null, minValues) : null;
            return (!result && result !== 0) ? null : result.toFixed(2);
        },
        metricAvg() {
            let avgValues = this.metricTimeseries
                ?.getData()
                ?.map(item => item.getAvg());

            let avg = avgValues?.reduce((memo, value) => memo + value, 0);

            let result = avgValues?.length ? (avg / avgValues.length) : null;

            return (!result && result !== 0) ? null : result.toFixed(2);
        },
        metricMax() {
            let maxValues = this.metricTimeseries?.getData()?.map(item => item.getMax());
            let result = maxValues?.length ? Math.max.apply(null, maxValues) : null;
            return (!result && result !== 0) ? null : result.toFixed(2);
        },
        isCurrentUserDevice() {
            return this.deviceObject.getAccountId() === this.currentObject?.account_id;
        },
    },
}
</script>

<style lang="less" scoped>
.values-list > small {
    background: #eaeaea;
    padding: .3rem 1rem;
    border-radius: 1rem;
}
</style>
