import Vue from "vue";
import ConfirmModal from "@/components/modals/ConfirmModal";

Vue.directive('confirm', {
    bind(el, binding) {
        el.handleClick = (e) => {
            const ComponentConstructor = Vue.extend(ConfirmModal);
            const instance = (new ComponentConstructor()).$mount();

            document.getElementsByTagName('body')[0].appendChild(instance.$el);

            instance.opened = true;

            if (binding?.arg?.title) instance.title = binding?.arg?.title;
            if (binding?.arg?.text) instance.text = binding?.arg?.text;
            if (binding?.arg?.buttonSuccessText) instance.buttonSuccessText = binding?.arg?.buttonSuccessText;
            if (binding?.arg?.buttonCancelText) instance.buttonCancelText = binding?.arg?.buttonCancelText;

            instance.$on("closed", () => {
                instance.$destroy();
                instance.$el.parentNode.removeChild(instance.$el);
            });

            instance.$on("success", binding.value);
        }

        el.addEventListener('click', el.handleClick);
    },
    unbind(el) {
        el.removeEventListener('click', el.handleClick);
    }
});
