<template>
    <Layout></Layout>
</template>

<script>

import Layout from "../components/Layout";

export default {
    name: 'Home',
    components: {
        Layout,
    },
    mounted() {
        this.$router.push({name: 'Devices'}).catch(error => {
            console.info(error.message)
        });
    }
}
</script>
