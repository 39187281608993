import TimeseriesItemObject from "./TimeseriesItemObject";

export default class TimeseriesObject {

    constructor(timeseries) {
        this.timeseries = timeseries
    }

    getSlug() {
        return this.timeseries?.name;
    }

    getPort() {
        return this.timeseries?.port;
    }

    /**
     * @returns {TimeseriesItemObject[]|[]}
     */
    getData() {
        return this.timeseries?.data?.map(item => new TimeseriesItemObject(item)) || [];
    }

    getLatestData() {
        return this.getData()[0];
    }
}
