<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div ref="modalDialog" class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content app-modal-content" style="min-height: 500px;">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Добавление аварии') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div v-if="opened" class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label class="font-weight-bold text-muted">
                                    {{ $t('Название аварии') }}
                                    <strong class="text-danger">*</strong></label>
                                <input v-model="alarmTypeTitle" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-12 mb-4">
                            <ul class="nav nav-tabs">
                                <li v-for="item in levels" :key="item.level"
                                    @click="currentLevelTab = item.level"
                                    class="nav-item">
                                    <a class="nav-link font-weight-bold"
                                       :style="{ color: getLevelColor(item.level) }"
                                       :class="{ active : currentLevelTab === item.level }" href="#">
                                        {{ item.levelName }}
                                        ({{ countLevelConditions(item.level) }})
                                        <i @click.stop="removeLevel(item)"
                                           v-if="currentLevelTab === item.level"
                                           class="fas fa-times-circle text-dark"></i>
                                    </a>
                                </li>
                                <li v-if="unusedAlarmLevels.length" class="nav-item">
                                    <a class="nav-link" href="#">
                                        <div class="dropdown">
                                            <a href="#"
                                               @click.prevent
                                               class="text-decoration-none text-primary"
                                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-plus"></i>
                                                <span class="font-weight-bold" v-if="!levels.length">
                                                    {{ $t('Добавить уровень') }}
                                                </span>
                                            </a>
                                            <div class="dropdown-menu app-dropdown">
                                                <a v-for="item in unusedAlarmLevels"
                                                   :key="item.level"
                                                   @click.prevent="addLevel(item)"
                                                   class="dropdown-item" href="#">
                                                    {{ item.levelName }}
                                                </a>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div v-if="currentLevelConditions && currentLevelConditions.length" class="col-12 mb-4 text-right">
                            <button @click="scheduleMenuVisible = !scheduleMenuVisible" class="btn btn-outline-success btn-sm">
                                <i v-if="!scheduleMenuVisible" class="far fa-fw fa-clock"></i>
                                <i v-else class="fas fa-fw fa-list-ul"></i>
                                {{ scheduleMenuVisible ? $t('Условия аварии') : $t('Расписание уведомлений') }}
                            </button>
                        </div>
                        <div class="col-12">
                            <AlarmTypeModalLevelSchedule v-if="scheduleMenuVisible"
                                                         :levelIndex="currentLevelIndex"
                                                         :levelData="currentLevel"
                                                         @change="changeLevelSchedule"/>
                            <template v-else>
                                <AlarmTypeCondition v-for="(item, index) in currentLevelConditions"
                                                    :key="index"
                                                    :value="currentLevelConditions[index]"
                                                    :index="index"
                                                    :deviceObject="deviceObject"
                                                    :conditions="currentLevelConditions"
                                                    @change="changeConditionItem"
                                                    @remove="removeConditionItem"/>

                                <div class="btn btn-primary btn-block"
                                     v-if="levels.length && deviceMetrics.length"
                                     @click="addConditionItem">
                                    <i class="fas fa-plus"></i>
                                    {{ $t('Добавить условие') }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="create"
                            :disabled="processing || !isValid"
                            style="min-width: 110px;"
                            class="btn btn-purple">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>{{ $t('Сохранить') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import _ from "underscore";
import $ from "jquery";
import config from "../../config";
import {mapState} from 'vuex';
import AlarmTypeCondition from "../AlarmTypeCondition";
import AlarmTypeService from "../../services/AlarmTypeService";
import DeviceObject from "../../objects/DeviceObject";
import AlarmTypeModalLevelSchedule from "./AlarmTypeModalLevelSchedule";

const AlarmType = new AlarmTypeService()

export default {
    name: "AlarmTypeAddModal",
    components: {
        AlarmTypeCondition,
        AlarmTypeModalLevelSchedule
    },
    props: {
        opened: Boolean,
        deviceObject: DeviceObject
    },
    data() {
        return {
            levels: [],
            currentLevelTab: null,
            processing: false,
            alarmTypeTitle: "",
            scheduleMenuVisible: false
        }
    },
    methods: {
        removeLevel(level) {
            if (this.currentLevelConditions.length === 0 || confirm(this.$t('Удалить уровень? Так же будут удалены все условия этого уровня'))) {
                this.levels = _.reject(this.levels, item => item.level === level.level);
                this.currentLevelTab = this.levels[0]?.level;
            }
        },
        addLevel(item) {
            this.levels.push({
                conditions: [],
                schedule: ['* * * * *'],
                ...item
            });
            this.currentLevelTab = item.level;
        },
        getLevelColor(level) {
            return config.alarmLevelColors[level];
        },
        removeConditionItem(index) {
            this.levels[this.currentLevelIndex].conditions = _.reject(this.levels[this.currentLevelIndex].conditions, (item, idx) => idx === index);
        },
        changeConditionItem({index, item}) {
            this.levels[this.currentLevelIndex].conditions[index] = item;
        },
        addConditionItem() {
            this.levels[this.currentLevelIndex].conditions.push({
                metric_name: "",
                type_metric: null,
                port: null,
                type: "eq",
                value: ""
            });
        },
        countLevelConditions(level) {
            return _.find(this.levels, item => item.level === level)?.conditions?.length || 0;
        },
        changeLevelSchedule({levelIndex, cronString}) {
            this.levels[levelIndex].schedule = [cronString];
        },
        async create() {
            const createRuleConditions = _.map(this.levels, item => {
                item.level = item.level * 1;
                return item;
            })

            this.processing = true;

            const alarmType = await AlarmType.create({
                deviceId: this.deviceObject.getIRI(),
                deviceType: this.deviceObject.getType().getId(),
                title: this.alarmTypeTitle,
                level: this.level,
                createRuleConditions: createRuleConditions,
            });

            this.processing = false;

            if (alarmType) {
                this.$emit('created', alarmType);
                this.$toast.success(this.$t('Условие аварии добавлено!'));
                Object.assign(this.$data, this.$options.data());
            } else {
                this.$toast.error(this.$t('Ошибка!'));
            }
        }
    },
    computed: {
        ...mapState({
            types: state => state.types,
        }),
        deviceMetrics() {
            return this.deviceObject.getType().getMetrics();
        },
        alarmLevels() {
            return _.map(config.alarmLevels, (item, index) => {
                return {
                    level: index,
                    levelName: item
                }
            });
        },
        unusedAlarmLevels() {
            return _.filter(this.alarmLevels, item => {
                return _.pluck(this.levels, "level").indexOf(item.level) === -1;
            })
        },
        currentLevel() {
            return this.levels[this.currentLevelIndex];
        },
        currentLevelConditions() {
            return this.currentLevel?.conditions;
        },
        currentLevelIndex() {
            return _.findIndex(this.levels, item => item.level === this.currentLevelTab);
        },
        isValid() {
            const notEmptyLevels = _.every(this.levels, item => item.conditions.length > 0)
            const allConditions = _.chain(this.levels)
                .pluck("conditions")
                .flatten()
                .value()

            const isFilledConditions = _.every(allConditions, condition => {
                return condition.metric_name && condition.value !== "" && condition.type;
            });


            return this.alarmTypeTitle && allConditions.length && isFilledConditions && notEmptyLevels;
        }
    },
    watch: {
        opened(val) {
            if (val) {
                $(this.$refs.modal).modal("show");
            } else {
                $(this.$refs.modal).modal("hide");
                this.$emit("closed");
            }
        },
        currentLevelTab() {
            this.scheduleMenuVisible = false;
        }
    },
    mounted() {
        $(this.$refs.modal).on('hide.bs.modal', () => this.$emit("closed"));
    },
}
</script>

<style lang="less" scoped>

</style>
