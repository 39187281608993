<template>
    <component :is="currentCardComponent"
               :deviceObject="deviceObject"
               :timeseries="timeseries"
               :isCurrentUserDevice="isCurrentUserDevice"
               @onLoad="() => this.loading = false"
               @deviceDoUpdate="$emit('deviceDoUpdate', $event)"
               @deviceDoShowGeoPosition="$emit('deviceDoShowGeoPosition', $event)"
               @deviceDoRemove="$emit('deviceDoRemove', $event)"/>
</template>

<script>

import {mapState} from "vuex";
import DeviceObject from "../../../objects/DeviceObject";
import DefaultCard_1 from "./DefaultCard_1";
import DefaultCard_2 from "./DefaultCard_2";
import DefaultCard_3 from "./DefaultCard_3";

export default {
    name: "AbstractDefaultCard",
    components: {
        DefaultCard_1,
        DefaultCard_2,
        DefaultCard_3,
    },
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        timeseries: {
            type: Array,
            required: true
        },
        isCurrentUserDevice: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapState({
            currentCardDesign: state => state.DevicesSettingsDropdown.currentCardDesign,
        }),
        currentCardComponent() {
            switch (this.currentCardDesign) {
                case 3:
                    return DefaultCard_3;
                case 2:
                    return DefaultCard_2;
                default:
                    return DefaultCard_1
            }
        }
    },
}
</script>

<style scoped>

</style>
