import store from "../store"
import MetricObject from "./MetricObject";

export default class DeviceTypeObject {

    constructor(type) {
        this.type = store.state.types?.find(item => item['@id'] === type) || {};
        this._excludeInternalMetrics = false;
    }

    getId() {
        return this.type['@id'];
    }

    getName() {
        return this.type?.name || null;
    }

    getSlug() {
        return this.type?.slug || null;
    }

    excludeInternalMetrics() {
        this._excludeInternalMetrics = true;
        return this;
    }

    /**
     * @returns {MetricObject[] | []}
     */
    getMetrics() {
        let result = this.type?.type_metrics
            ?.map(item => new MetricObject(item))
            ?.filter(metricObj => this._excludeInternalMetrics ? !metricObj.isInternal() : true) || [];

        this.excludeInternalMetrics = false;

        return result;
    }

    /**
     * @returns {MetricObject[] | []}
     */
    getMetricsWithPorts() {
        const result = [];
        const metrics = this.getMetrics();

        metrics.forEach(item => {
            if (item.getCountPorts() > 0) {
                for (let i = 0; i < item.getCountPorts(); i++) {
                    result.push(item.clone().setPortIndex(i));
                }
            } else {
                result.push(item);
            }
        });

        return result;
    }

    getMetricsWithPortsExclude(excludeMetrics = []) {
        return this.getMetricsWithPorts().filter(item => excludeMetrics.indexOf(item.getSlug()) === -1);
    }

    getMetricsExclude(excludeMetrics = []) {
        return this.getMetrics().filter(item => excludeMetrics.indexOf(item.getSlug()) === -1) || [];
    }

    getMetricBySlug(metricSlug) {
        return this.getMetrics().find(item => item.getSlug() === metricSlug);
    }
}
