<template>
    <vue-good-table
        @on-page-change="onPageChange"
        :columns="columns"
        :rows="logs"
        :totalRows="totalRows"
        :isLoading.sync="isLoading"
        max-height="450px"
        :fixed-header="true"
        mode="remote"
        :pagination-options="vgtPaginationOptions">
        <template slot="table-row" slot-scope="props">
            <small v-if="props.column.field === 'event'" :class="getEventColor(props.row.event)">
                {{ getEventName(props.row.event) || "-" }}
            </small>
            <small v-else-if="props.column.field === 'level'" class="font-weight-bold">
                <span v-if="props.row.event === 'disabled'">-</span>
                <span v-else :style="{ color: getAlarmLevelColor(props.row.context.level) }">
                    {{ getAlarmLevelName(props.row.context.level) || "-" }}
                </span>
            </small>
            <small v-else-if="props.column.field === 'context'" class="font-weight-bold">
                <span v-if="props.row.event === 'disabled'">-</span>
                <AccidentLogContext v-else
                                    :context="props.row.context"
                                    :deviceObject="currentDeviceObject"/>
            </small>
            <small v-else-if="props.column.field === 'created_at'">
                {{ dateFormat(props.row.created_at) }}
            </small>
            <div v-else>
                {{ props.formattedRow[props.column.field] }}
            </div>
        </template>
    </vue-good-table>
</template>

<script>

import config from "../config";
import moment from "moment-mini";
import DeviceObject from "../objects/DeviceObject";
import {mapState} from "vuex";
import AccidentLogContext from "./AccidentLogContext";
import AccidentService from "../services/AccidentService";

const Accident = new AccidentService();

export default {
    name: "AccidentLogTable",
    components: {
        AccidentLogContext
    },
    props: {
        currentAccident: {
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            columns: [
                {
                    label: this.$t('Событие'),
                    field: 'event',
                    sortable: false,
                },
                {
                    label: this.$t('Уровень'),
                    field: 'level',
                },
                {
                    label: this.$t('Контекст'),
                    field: 'context',
                    sortable: false,
                    width: "400px"
                },
                {
                    label: this.$t('Дата'),
                    field: 'created_at',
                    sortable: false,
                }
            ],
            logs: [],
            totalRows: 0,
            currentPage: 1,
            perPage: 10
        }
    },
    methods: {
        async onPageChange({currentPage}) {
            this.currentPage = currentPage;
            await this.loadLogs()
        },
        dateFormat(date) {
            return date ? moment(date).format(config.dateTimeFormats.DDMMYYYYHHmmss) : "-"
        },
        getAlarmLevelColor(level) {
            return config.alarmLevelColors[level];
        },
        getAlarmLevelName(level) {
            return config.alarmLevels[level];
        },
        getEventName(event) {
            return ({
                created: this.$t('Создано'),
                updated: this.$t('Обновлено'),
                disabled: this.$t('Завершено'),
            })[event];
        },
        getEventColor(event) {
            return ({
                created: "text-danger",
                updated: "text-warning",
                disabled: "text-success",
            })[event];
        },
        async loadLogs() {
            this.isLoading = true;
            const body = await Accident.fetchLog(this.currentAccidentId, this.currentPage, this.perPage);
            this.logs = body["hydra:member"];
            this.totalRows = body["hydra:totalItems"];
            this.isLoading = false;
        },
    },
    watch: {
        async currentAccident(val) {
            this.currentPage = 1;

            if (val) {
                await this.$nextTick();
                await this.loadLogs();
            } else {
                this.logs = []
            }
        }
    },
    computed: {
        ...mapState({
            devices: state => state.devices
        }),
        vgtPaginationOptions() {
            return config.vgtPaginationOptions({
                perPage: this.perPage,
                perPageDropdown: [this.perPage],
                setCurrentPage: this.currentPage
            })
        },
        currentAccidentDeviceId() {
            return this.currentAccident.device.split("/").reverse()[0];
        },
        currentAccidentId() {
            return this.currentAccident['@id'].split("/").reverse()[0];
        },
        currentDevice() {
            return this.devices.find(item => item.id === this.currentAccidentDeviceId);
        },
        currentDeviceObject() {
            return new DeviceObject(this.currentDevice)
        }
    },
}
</script>

<style scoped>

</style>
