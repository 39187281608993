<template>
    <div class="cursor-pointer">
        <div v-if="hasActiveAccidents"
             @click="$router.push({name: 'Accidents', params: {deviceId:  deviceId }})"
             class="accident-indicator"
             data-toggle="tooltip"
             :title="$t('На этом устройстве есть активные инциденты')">

            <div class="accident-indicator__pulse"></div>
            <div class="accident-indicator__pulse2"></div>

            <Icon icon-name="danger"
                  :fill="currentCardDesign === 2 ? 'white' : '#B9B9BA'"
                  width="28"
                  height="28"/>
        </div>

        <div v-else
             @click="$router.push({name: 'Accidents', params: {deviceId: deviceId }})"
             data-toggle="tooltip"
             :title="$t('На этом устройстве нет активных инцидентов')">

            <Icon icon-name="danger"
                  :fill="currentCardDesign === 2 ? 'white' : '#B9B9BA'"
                  width="28"
                  height="28"/>
        </div>
    </div>
</template>

<script>

import Icon from "../../Icon";
import {mapState} from "vuex";
import DeviceObject from "../../../objects/DeviceObject";

export default {
    name: "DefaultCard_1AccidentsIndicator",
    components: {
        Icon
    },
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        }
    },
    computed: {
        ...mapState({
            currentCardDesign: state => state.DevicesSettingsDropdown.currentCardDesign,
        }),
        hasActiveAccidents() {
            return this.deviceObject.hasActiveAccidents()
        },
        deviceId() {
            return this.deviceObject.getId();
        }
    },
    watch: {
        hasActiveAccidents(val) {
            this.$emit('onHasAccidents', val);
        }
    },
}
</script>

<style lang="less" scoped>

.accident-indicator {
    position: relative;
    z-index: 1;

    &__pulse, &__pulse2 {
        display: block;
        content: "";
        background: tint(#E94C3D, 50);
        border-radius: 100%;
        width: 28px;
        height: 28px;
        animation-name: pulse;
        animation-duration: 1.5s;
        animation-timing-function: ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
        z-index: -1;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -14px;
        margin-left: -14px;
    }

    &__pulse2 {
        animation-delay: 350ms;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: scale(1.8);
        opacity: 0;
    }
}
</style>
