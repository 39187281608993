<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 400px" role="document">
            <div class="modal-content app-modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div v-if="opened" class="modal-body">
                    <p class="mb-4">{{ text }}</p>
                    <button @click="$emit('removed')"
                            :disabled="processing"
                            class="btn btn-danger btn-block">
                        <div v-if="processing" class="spinner-border spinner-border-sm text-white" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <span v-else>{{ buttonText }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $ from "jquery";

export default {
    name: "RemoveModal",
    props: {
        opened: {
            type: Boolean,
            required: true,
        },
        processing: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        buttonText: {
            type: String,
            required: true,
        },
    },
    watch: {
        opened(val) {
            if (val) {
                $(this.$refs.modal).modal("show");
            } else {
                $(this.$refs.modal).modal("hide");
                this.$emit("closed");
            }
        },
    },
    mounted() {
        $(this.$refs.modal).on('hide.bs.modal', () => this.$emit("closed"));
    },
}
</script>

<style scoped>

</style>