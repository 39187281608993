<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <h4 class="title mb-4">{{ $t('Поддержка') }}</h4>
            </div>
            <div class="col-12 col-md-6 mb-4">
                <h4 class="subtitle mb-4">{{ $t('Ваш персональний менеджер') }}</h4>
                <div class="app-card p-4">
                    <div class="app-card-body d-flex flex-column flex-md-row align-items-center">
                        <div class="my-2 mr-md-4">
                            <img src="../assets/ava.jpg" class="rounded-circle">
                        </div>
                        <div class="d-flex flex-column align-items-center align-items-md-start">
                            <div class="app-card_title mb-2">{{ $t('Алексей Веретюк') }}</div>
                            <div class="app-card_text d-flex align-items-center">
                                <div class="mr-3 ">
                                    <Icon
                                        icon-name="call"
                                        width="20"
                                        height="21"
                                        :fill="currentTheme"
                                    />
                                </div>
                                <div>+38 (067) 217-34-43</div>
                            </div>
                            <div class="app-card_text d-flex">
                                <div class="mr-3">
                                    <Icon
                                        icon-name="mail"
                                        width="22"
                                        height="16"
                                        :stroke="currentTheme"
                                        fill="none"
                                    />
                                </div>
                                <div>mykhalych90@gmail.com</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 mb-4">
                <h4 class="subtitle mb-4">{{ $t('Онлайн-чат с техподдержкой') }}</h4>
                <div class="app-card p-4">
                    <div class="app-card_title mb-2">{{ $t('Выберете мессенджер') }}</div>
                    <div class="d-flex app-card-chats justify-content-center justify-content-md-start">
                        <a class="app-card-chats-facebook" target="_blank" href="https://m.me/625945008039045"></a>
                        <a class="app-card-chats-telegram" target="_blank" href="https://t.me/smartmictobot"></a>
                        <a class="app-card-chats-viber" target="_blank" href="viber://pa?chatURI=smartmicto"></a>
                        <a class="app-card-chats-chat" target="_blank" href="https://bitrix.freeutg.com/online/smartmicto"></a>
                    </div>
                </div>
            </div>
<!--            <div class="col-12 col-md-6  mb-4">-->
<!--                <h4 class="subtitle mb-4">{{ $t('Контакты') }}</h4>-->
<!--                <div class="app-card p-4">-->
<!--                    <div class="row">-->
<!--                        <div class="col-12 col-md-6 mb-4">-->
<!--                            <div class="app-card_title mb-2">{{ $t('Отдел продаж') }}</div>-->
<!--                            <div class="app-card_text d-flex align-items-center">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="call"-->
<!--                                        width="20"-->
<!--                                        height="21"-->
<!--                                        :fill="currentTheme"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>+38 (050) 232-87-00</div>-->
<!--                            </div>-->
<!--                            <div class="app-card_text d-flex align-items-center">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="call"-->
<!--                                        width="20"-->
<!--                                        height="21"-->
<!--                                        :fill="currentTheme"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>+38 (050) 232-87-00</div>-->
<!--                            </div>-->
<!--                            <div class="app-card_text d-flex">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="mail"-->
<!--                                        width="22"-->
<!--                                        height="16"-->
<!--                                        :stroke="currentTheme"-->
<!--                                        fill="none"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>Anton.Muhin@gmail.com</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-12 col-md-6 mb-4">-->
<!--                            <div class="app-card_title mb-2">{{ $t('Бухгалтерия') }}</div>-->
<!--                            <div class="app-card_text d-flex align-items-center">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="call"-->
<!--                                        width="20"-->
<!--                                        height="21"-->
<!--                                        :fill="currentTheme"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>+38 (050) 232-87-00</div>-->
<!--                            </div>-->
<!--                            <div class="app-card_text d-flex align-items-center">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="call"-->
<!--                                        width="20"-->
<!--                                        height="21"-->
<!--                                        :fill="currentTheme"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>+38 (050) 232-87-00</div>-->
<!--                            </div>-->
<!--                            <div class="app-card_text d-flex">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="mail"-->
<!--                                        width="22"-->
<!--                                        height="16"-->
<!--                                        :stroke="currentTheme"-->
<!--                                        fill="none"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>Anton.Muhin@gmail.com</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-12 col-md-6 mb-4">-->
<!--                            <div class="app-card_title mb-2">{{ $t('Просто хорошие люди') }}</div>-->
<!--                            <div class="app-card_text d-flex align-items-center">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="call"-->
<!--                                        width="20"-->
<!--                                        height="21"-->
<!--                                        :fill="currentTheme"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>+38 (050) 232-87-00</div>-->
<!--                            </div>-->
<!--                            <div class="app-card_text d-flex align-items-center">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="call"-->
<!--                                        width="20"-->
<!--                                        height="21"-->
<!--                                        :fill="currentTheme"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>+38 (050) 232-87-00</div>-->
<!--                            </div>-->
<!--                            <div class="app-card_text d-flex">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="mail"-->
<!--                                        width="22"-->
<!--                                        height="16"-->
<!--                                        :stroke="currentTheme"-->
<!--                                        fill="none"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>Anton.Muhin@gmail.com</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="row">-->
<!--                        <div class="col-12 col-md-6 mb-4">-->
<!--                            <div class="app-card_title mb-2">{{ $t('Технические вопросы') }}</div>-->
<!--                            <div class="app-card_text d-flex align-items-center">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="call"-->
<!--                                        width="20"-->
<!--                                        height="21"-->
<!--                                        :fill="currentTheme"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>+38 (050) 232-87-00</div>-->
<!--                            </div>-->
<!--                            <div class="app-card_text d-flex align-items-center">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="call"-->
<!--                                        width="20"-->
<!--                                        height="21"-->
<!--                                        :fill="currentTheme"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>+38 (050) 232-87-00</div>-->
<!--                            </div>-->
<!--                            <div class="app-card_text d-flex">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="mail"-->
<!--                                        width="22"-->
<!--                                        height="16"-->
<!--                                        :stroke="currentTheme"-->
<!--                                        fill="none"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>Anton.Muhin@gmail.com</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-12 col-md-6 mb-4">-->
<!--                            <div class="app-card_title mb-2">{{ $t('Просто хорошие люди') }}</div>-->
<!--                            <div class="app-card_text d-flex align-items-center">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="call"-->
<!--                                        width="20"-->
<!--                                        height="21"-->
<!--                                        :fill="currentTheme"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>+38 (050) 232-87-00</div>-->
<!--                            </div>-->
<!--                            <div class="app-card_text d-flex align-items-center">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="call"-->
<!--                                        width="20"-->
<!--                                        height="21"-->
<!--                                        :fill="currentTheme"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>+38 (050) 232-87-00</div>-->
<!--                            </div>-->
<!--                            <div class="app-card_text d-flex">-->
<!--                                <div class="mr-3">-->
<!--                                    <Icon-->
<!--                                        icon-name="mail"-->
<!--                                        width="22"-->
<!--                                        height="16"-->
<!--                                        :stroke="currentTheme"-->
<!--                                        fill="none"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <div>Anton.Muhin@gmail.com</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </Layout>
</template>

<script>

import types from "../store/types";
import Icon from "../components/Icon";
import {mapState} from "vuex";
import Layout from "../components/Layout";

export default {
    name: "Support",
    data: () => ({
        name: '',
        phone: null,
        message: '',
    }),
    components: {
        Icon,
        Layout
    },
    mounted() {
        this.$store.commit(types.PRELOADER, false);
    },
    computed: {
        ...mapState({
            currentTheme: state => state.currentTheme,
        }),
    },
}
</script>

<style scoped lang="less">

@import "../less/_variables";

.app-card {

    &-chats {
        a {
            width: 45px;
            height: 45px;
            margin: .5rem 2.5rem .8rem 0;
            background-size: contain;
            background-repeat: no-repeat;

            @media (max-width: 768px) {
                margin: 0 1rem;
            }
        }

        &-facebook {
            background-image: url("../assets/contacts/facebook.png");
        }

        &-telegram {
            background-image: url("../assets/contacts/telegram.png");
        }

        &-viber {
            background-image: url("../assets/contacts/Viber.png");
        }

        &-chat {
            background-image: url("../assets/contacts/chat.png");
        }
    }

    &_title {
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        //letter-spacing: -0.4px;
        margin-bottom: 1rem;
    }

    &_text {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.085em;
        margin-bottom: .5rem;
    }

    .btn-custom {
        width: 200px;
    }
}

</style>
