import Vue from "vue";
export const EventBus = new Vue();
export const events = {
    UNAUTHORIZED: "unauthorized",
    AUTHORIZED: "authorized",
    DEVICE_ADD_MODAL_OPEN: " DEVICE_ADD_MODAL_OPEN",
    DEVICE_UPDATE_MODAL_OPEN: " DEVICE_UPDATE_MODAL_OPEN",
    REFRESH_CARD_TIMESERIES: "REFRESH_CARD_TIMESERIES",
    DEVICE_COEFFICIENT_MODAL_OPEN: "DEVICE_COEFFICIENT_MODAL_OPEN",
};

export default {
    install: function (Vue) {
        Vue.prototype.$eventBus = {
            ...events,
            emit: function (event, payload) {
                EventBus.$emit(event, payload);
            },
            on: function (event, callback) {
                EventBus.$on(event, callback);
            },
            off: function (event, callback) {
                EventBus.$off(event, callback);
            }
        }
    }
};
