import AbstractService from "./AbstractService";

export default class AccidentService extends AbstractService {

    constructor() {
        super();
    }

    async fetch(order_by = 'created_at', order_type = 'desc', page = 1, itemsPerPage = 10) {
        const {code, body} = await this.request.get("/accidents", {
            page,
            itemsPerPage,
            ['order[' + order_by + ']'] : order_type
        });
        return code === 200 ? body : [];
    }

    async fetchByDevice(deviceId, order_by = 'created_at', order_type = 'desc', page = 1, itemsPerPage = 10) {
        const {code, body} = await this.request.get(`/devices/${deviceId}/accidents`, {
            page,
            itemsPerPage,
            ['order[' + order_by + ']'] : order_type,
        });
        return code === 200 ? body : [];
    }

    async fetchLog(accidentId, page = 1, itemsPerPage = 10) {
        const {code, body} = await this.request.get(`/accidents/${accidentId}/logs`, {
            page,
            itemsPerPage,
            'order[created_at]': 'desc'
        });
        return code === 200 ? body : [];
    }
}
