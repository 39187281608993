<template>
    <ul class="list-group">
        <li v-for="item in accidentMetrics"
            :key="item.metric.getId()"
            class="list-group-item py-1 px-2" style="background: #F8F9FA;">
            <div class="row">
                <div class="col-3">
                    <Icon :iconName="item.metric.getIcon()" :fill="item.metric.getColor()"></Icon>
                </div>
                <div class="col-6 metric-name">
                    {{ item.metric.getName() }}
                    <span v-if="item.data.port">
                       , Port: {{ item.data.port }}
                    </span>
                </div>
                <div class="col-3 metric-value">
                    {{ item.data.value }}
                    <span>{{ item.metric.getUnits() }}</span>
                </div>
            </div>
        </li>
        <li v-if="!accidentMetrics.length" class="list-group-item py-1 px-2">
            <span class="metric-name">N/A</span>
        </li>
    </ul>
</template>

<script>

import Icon from "./Icon";
import DeviceObject from "../objects/DeviceObject";
import metricsConfig from "../metricsConfig";

export default {
    name: "AccidentLogContext",
    components: {
        Icon
    },
    props: {
        context: Object,
        deviceObject: {
            type: DeviceObject,
            required: true
        }
    },
    methods: {
        getMetricCustomTextValue(metric) {
            if (metricsConfig[this.deviceTypeSlug]?.metricConditions?.hasOwnProperty(metric.name)) {
                return metricsConfig[this.deviceTypeSlug]
                    ?.metricConditions[metric.name]
                    ?.values
                    ?.find(el => el.value == metric.value)
                    ?.name || metric.value;
            }

            return metric.value;
        },
    },
    computed: {
        deviceTypeSlug() {
            return this.deviceObject?.getType()?.getSlug();
        },
        accidentMetrics() {
            return this.context?.metrics?.map(item => {
                item.value = this.getMetricCustomTextValue(item);

                if (item.name === "offline_hours") {
                    item.value = Math.trunc(item.value);
                }

                return {
                    data: item,
                    metric: this.deviceObject.getType().getMetricBySlug(item.name)
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
.metric-name {
    font-weight: normal;
}

.metric-value span {
    font-weight: normal;
}

</style>
