<template>
    <vue-good-table
        :columns="columns"
        :rows="accidents"
        :totalRows="totalRows"
        @on-sort-change="onSortChange"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :pagination-options="vgtPaginationOptions()"
        :sort-options="{
            enabled: true,
        }">
        <template slot="table-row" slot-scope="props">
            <a v-if="props.column.field === 'device'"
               @click.prevent="toDevice(props.row.device)"
               href="#">
                <small class="font-weight-bold">
                    {{ getDeviceObject(props.row.device).getName() | stringLimit }}
                </small>
            </a>
            <a v-else-if="props.column.field === 'name'"
               @click.prevent="toAlarm(props.row)"
               href="#">
                <small class="font-weight-bold">
                    {{ stringLimit(props.row.alarm.title, 30) }}
                </small>
            </a>
            <small v-else-if="props.column.field === 'level'"
                   :style="{ color: getAlarmLevelColor(props.row.level)}"
                   class="font-weight-bold">
                {{ getAlarmLevelName(props.row.level) }}
            </small>
            <div v-else-if="props.column.field === 'status'"
                 class="badge"
                 :class="getAlarmLevelStatusColor(props.row)">
                {{ getAlarmLevelStatus(props.row) }}
            </div>
            <small v-else-if="'created_at' === props.column.field" class="text-danger">
                {{ dateFormat(props.formattedRow[props.column.field]) }}
            </small>
            <small v-else-if="'updated_at' === props.column.field" class="text-warning">
                {{ dateFormat(props.formattedRow[props.column.field]) }}
            </small>
            <small v-else-if="'disabled_at' === props.column.field" class="text-success">
                {{ dateFormat(props.formattedRow[props.column.field]) }}
            </small>
            <button v-else-if="props.column.field === 'action'"
                    @click="selectAccident(props.row)"
                    class="btn btn-sm btn-purple btn-block">
                <i class="fas fa-list-ul mr-2"></i>
                {{ $t('Обзор') }}
            </button>
            <div v-else>
                {{ props.formattedRow[props.column.field] }}
            </div>
        </template>
    </vue-good-table>
</template>

<script>

import config from "../config";
import moment from "moment-mini";
import DeviceObject from "../objects/DeviceObject";
import {mapState} from "vuex";
import Helper from "../classess/Helper";

export default {
    name: "AccidentsTable",
    props: {
        accidents: {
            type: Array,
            required: true
        },
        totalRows: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data() {
        return {
            currentAccidentId: null,
            columns: [
                {
                    label: this.$t('Устройство'),
                    field: 'device',
                    sortable: false,
                },
                {
                    label: this.$t('Авария'),
                    field: 'name',
                    sortable: false,
                },
                {
                    label: this.$t('Уровень'),
                    field: 'level',
                    sortable: false,
                },
                {
                    label: this.$t('Статус'),
                    field: 'status',
                    sortable: false,
                },
                {
                    label: this.$t('Создано'),
                    field: 'created_at',
                },
                {
                    label: this.$t('Обновлено'),
                    field: 'updated_at',
                },
                {
                    label: this.$t('Завершено'),
                    field: 'disabled_at',
                },
                {
                    label: this.$t('Действия'),
                    field: 'action',
                    sortable: false,
                },
            ],
        }
    },
    filters: {
        stringLimit: string => Helper.stringLimit(string || "", 40)
    },
    methods: {
        stringLimit: Helper.stringLimit,
        vgtPaginationOptions: config.vgtPaginationOptions,
        dateFormat(date) {
            return date ? moment(date).format(config.dateTimeFormats.DDMMYYYYHHmmss) : "-"
        },
        getAlarmLevelColor(level) {
            return config.alarmLevelColors[level];
        },
        getAlarmLevelName(level) {
            return config.alarmLevels[level];
        },
        getAlarmLevelStatusColor(level) {
            return level.disabled_at
                ? 'badge-success'
                : level.updated_at ? 'badge-warning' : 'badge-danger'
        },
        getAlarmLevelStatus(level) {
            return level.disabled_at
                ? this.$t('Инцидент завершен')
                : level.updated_at ? this.$t('Инцидент продолжается') : this.$t('Инцидент создан')
        },
        getDeviceObject(deviceIRI) {
            const device = this.devices.find(item => item.id === deviceIRI.split("/").reverse()[0]);
            return new DeviceObject(device);
        },
        toAlarm({device, alarm_type}) {
            this.$router.push({
                name: 'DeviceAlarm',
                params: {
                    id: this.getDeviceObject(device).getId()
                },
                query: {
                    highlight_alarm_id: alarm_type.split('/').reverse()[0]
                }
            });
        },
        toDevice(device) {
            this.$router.push({
                name: 'Devices',
                query: {
                    highlight_device_id: this.getDeviceObject(device).getId()
                }
            });
        },
        selectAccident(accident) {
            this.currentAccidentId = accident['@id'].split("/").reverse()[0];
            this.$emit('select', accident)
        },
        onSortChange(params) {
            this.$emit('onSortChange', params)
        },
        onPageChange(params) {
            this.$emit('onPageChange', params)
        },
        onPerPageChange(params) {
            this.$emit('onPerPageChange', params)
        }
    },
    computed: {
        ...mapState({
            devices: state => state.devices
        })
    },
}
</script>

<style>

</style>
