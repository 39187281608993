import AbstractService from "./AbstractService";

export default class AlarmRuleService extends AbstractService {

    constructor() {
        super();
    }

    async create(alarmRule) {
        const {code, body} = await this.request.post("/alarm_rules", alarmRule);
        return code === 201 ? body : false;
    }

    async update(alarmRule) {
        const {code, body} = await this.request.put(`/alarm_rules/${alarmRule.id}`, {
            schedule: alarmRule.schedule
        });
        return code === 201 ? body : false;
    }

    async remove(alarmRuleId) {
        const {code} = await this.request.delete("/alarm_rules/" + alarmRuleId);
        return code === 204;
    }
}
